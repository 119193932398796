import React from 'react'
import { Link } from 'react-router-dom'

export default function Welcome() {
  return (
    <div className='flex flex-col items-center justify-center min-h-screen gap-[2rem]'>
      <h1 className='text-[3rem] font-bold'>Welcome to Strike.</h1>
      <Link to={"/login"}>
        <button type='button'
          className='px-[5rem] py-[0.5rem] bg-primary text-white text-[1.5rem] font-bold rounded-xl'>
          Login Page
        </button>
      </Link>
    </div>
  )
}
