import React, { useState } from 'react'
import { Icon } from '@iconify/react';
import { Checkbox } from '@material-tailwind/react';
import { useForm } from 'react-hook-form';
import { ToastContainer } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';

export default function EditNotification() {
  const [selectImg, setSelectImg] = useState(null);
  const [loaderBtn, setLoaderBtn] = useState(false);
  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();

  const onSubmit = async (data) => {
    console.log(data);
  }

  return (
    <div className="flex flex-col gap-[1rem] font-[500]">
      <form onSubmit={handleSubmit(onSubmit)}
        className="bg-white flex flex-col gap-[1rem] px-[2rem] rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[2rem] gap-y-[1rem]">
          <div className='flex flex-col gap-[1.2rem]'>
            <div className="flex flex-col gap-[0.5rem]">
              <label htmlFor="title" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                Title
              </label>
              <input
                className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                type="text"
                placeholder="Enter Title"
                {...register("title", {
                  required: "*This field is required.",
                })}
              />
              {errors.title && (
                <div className="text-sm text-[#E92215]">
                  {errors.title.message}
                </div>
              )}
            </div>
            <div className="flex flex-col gap-[0.5rem]">
              <label htmlFor="description" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                Description
              </label>
              <textarea rows={4}
                className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                type="text"
                placeholder="Description goes here..."
                {...register("description", {
                  required: "*This field is required.",
                })}
              />
              {errors.description && (
                <div className="text-sm text-[#E92215]">
                  {errors.description.message}
                </div>
              )}
            </div>
            <div className='flex flex-col gap-[0.5rem]'>
              <label htmlFor="title" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                Select to Send Notifications
              </label>
              <div className="flex gap-[1rem]">
                <div
                  className="border border-[#D9D9D9] text-[#858585] pr-[2rem] rounded-md">
                  <Checkbox color="green" label="Agents" />
                </div>
                <div className="border border-[#D9D9D9] text-[#858585] pr-[2rem] rounded-md">
                  <Checkbox color="green" label="Customers" />
                </div>
                <div className="border border-[#D9D9D9] text-[#858585] pr-[2rem] rounded-md">
                  <Checkbox color="green" label="Lottery Agents" />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="profilePicture" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Upload Image
            </label>
            <div className="flex flex-col items-center py-[2rem] px-[1rem] border-2 border-dashed
                         border-[#D9D9D9] rounded-lg">
              {selectImg?.name ? (
                <>
                  <h2 className="text-[1.1rem] font-semibold text-green-600 my-[1rem]">
                    Uploaded document
                  </h2>
                  <div className="flex gap-[1rem] items-center">
                    <h2 className="text-[1.1rem] text-black font-[500]">
                      {selectImg?.name.length > 30
                        ? selectImg.name.substring(0, 30) + "..."
                        : selectImg.name}
                    </h2>
                    <Icon
                      onClick={() => setSelectImg(null)}
                      icon="akar-icons:cross"
                      className="text-[1.2rem] cursor-pointer hover:bg-[#3D4A5C]/[0.3]"
                    />
                  </div>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() =>
                      document.querySelector(".input-field").click()
                    }
                  >
                    <Icon
                      icon="material-symbols:cloud-upload"
                      className="text-[#D9D9D9] text-[4rem] mb-[0.5rem]"
                    />
                  </button>
                  <input
                    type="file"
                    // accept="image/*"
                    value=""
                    className="input-field"
                    hidden
                    onChange={({ target: { files } }) => {
                      if (files[0]) {
                        setSelectImg(files[0]);
                      }
                    }}
                  />
                  <p className="text-[0.9rem] text-[#D9D9D9]">
                    Upload jpg, png, pdf. Size up to 20MB
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end mt-[1rem]">
          {loaderBtn ? (
            <ThreeDots
              height="50"
              width="50"
              radius="9"
              color="#9FB947"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) :
            (<button
              className="text-[1.2rem] font-semibold text-white bg-primary py-[0.5rem] px-[1rem] rounded-[8px]"
              type="submit"
            >
              Save Notification
            </button>)}
        </div>
      </form>
      <ToastContainer />
    </div>
  )
}

