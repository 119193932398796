import React, { useState } from 'react'
import PersonalDetails from './PersonalDetails';
import BankDetails from './BankDetails';
import KYCDetails from './KYCDetails';
import HeaderStepper from '../../../../helpers/HeaderStepper';
import { Icon } from '@iconify/react';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../../store/AuthContext';
import { APIurls } from "../../../../api/apiConstant";
import { updatePersonalDetails, updateBankDetails, updateLoaderBtn } from '../../../../store/createWalletAgentSlice';
import { useNavigate } from 'react-router-dom';


export default function CreateWalletAgent() {
  const [currentStep, setCurrentStep] = useState(1);
  const [steps, setSteps] = useState(["Personal Details", "Bank Details", "KYC Details"]);
  const dispatch = useDispatch();
  // const { loaderBtn } = useSelector((store) => store.createAgent);
  const navigate = useNavigate();
  const { getAccessToken } = useAuth()

  const {
    firstName,
    lastName,
    email,
    mobileNumber,
    whatsAppNumber,
    telegramNumber,
    nationality,
    passportNumber,
    address,
    country,
    state,
    city,
    pincode,
  } = useSelector((store) => store.createWalletAgent.personalDetails)
  const {
    accountNumber,
    ifscCode,
    accountHolderName,
    bankName,
    bankCity,
    branch,
  } = useSelector((store) => store.createWalletAgent.bankDetails)


  const displayStep = () => {
    switch (currentStep) {
      case 1: return <PersonalDetails currentStep={currentStep} handleClick={handleClick} steps={steps} />;
      case 2: return <BankDetails currentStep={currentStep} handleClick={handleClick} steps={steps} />;
      case 3: return <KYCDetails currentStep={currentStep} handleClick={handleClick} steps={steps} />;
      default: break;
    }
  }

  const handleClick = async (direction) => {
    let newStep = currentStep;
    if (direction === "next") {
      newStep++;
    } else if (direction === "back") {
      newStep--;
    } else if (direction === "create") {
      createWalletAgentHandler();
    }
    if (newStep > 0 && newStep <= steps.length) {
      setCurrentStep(newStep);
    }
  }

  async function createWalletAgentHandler() {
    const token = await getAccessToken()
    try {
      dispatch(updateLoaderBtn(true));
      const response = await fetch(APIurls.createWalletAgent, {
        method: "POST",
        body: JSON.stringify({
          "Email": email,
          // "Email": "dibya23238@gmail.com",
          "FirstName": firstName,
          "LastName": lastName,
          "dob": "18-09-2000",
          "location": {
            "country": country,
            "state": state,
            "pincode": pincode,
            "city": city,
            "address": address,
          },
          "MobileNumber": mobileNumber,
          "whatsApp": whatsAppNumber,
          "BankDetails": {
            "acc_name": accountHolderName,
            "acc_number": accountNumber,
            "acc_ifsc": ifscCode,
            "acc_city": bankCity,
            "acc_branch": branch,
          },
          "kyc_details": {},
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      })
      if (!response.ok) {
        dispatch(updateLoaderBtn(false));
        if (response.status === 400) {
          const errorData = await response.json();
          console.log("Error Data:", errorData);
          toast.error(errorData.message);
          throw new Error(errorData.message);
        } else {
          toast.error("Failed to Create Wallet Agent");
          throw new Error("Failed to Create Wallet Agent");
        }
      }
      const result = await response.json();
      console.log("Create Wallet Agent Result::::", result);
      toast.success("Wallet Agent Created Successfully!")
      dispatch(updateLoaderBtn(false));
      // setCurrentStep(4);
      // clear the input field data from redux store.
      dispatch(updatePersonalDetails({
        firstName: "",
        lastName: "",
        email: "",
        mobileNumber: "",
        whatsAppNumber: "",
        telegramNumber: "",
        nationality: "",
        passportNumber: null,
        country: null,
        state: null,
        city: null,
        pincode: null,
        address: "",
      }))
      dispatch(updateBankDetails({
        accountNumber: "",
        ifscCode: "",
        accountHolderName: "",
        // bankName: "",
        bankCity: "",
        branch: "",
        status: "",
      }))
      navigate("/admin/wallet-agents")
    } catch (error) {
      console.log(error.response);
      //toast.error("Failed to Create Agent!")
    }
  }

  return (
    <div className='flex flex-col gap-[2rem] mt-[1rem] mb-[3rem]'>
      <div className="text-primary flex items-center gap-[1rem] px-[2rem]">
        <Icon icon="heroicons-solid:user-group" className="text-[2rem]" />
        <h1 className="text-[1.2rem] sm:text-[1.8rem] font-semibold">
          Create Wallet Agent
        </h1>
      </div>
      <div>
        <HeaderStepper steps={steps} currentStep={currentStep} />
      </div>
      <div>
        {displayStep()}
      </div>
      <ToastContainer />
    </div>
  )
}
