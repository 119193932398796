import React from 'react'
import authBgImage from '../../assets/auth-bg-img.svg'
import { Link, useNavigate } from 'react-router-dom';
import strikeLogo from "../../assets/strike-logo.svg"
import failureLogo from '../../assets/failure-logo.svg'


export default function Failure() {

  return (
    <div
      className="w-full min-h-screen max-xs:px-[0.5rem] bg-cover bg-[#F1F6FA] bg-center bg-repeat flex justify-center items-center"
      style={{ backgroundImage: `url(${authBgImage})` }}
    >
      <div className="bg-white flex flex-col min-w-[20rem] xs:min-w-[28.75rem] justify-center  px-[1rem]  xs:px-[4rem] py-[1rem] xs:py-[3rem] gap-[1rem] xs:gap-[1rem] rounded-[10px]  ">
        <Link to="/">
          <div>
            <img
              src={strikeLogo}
              alt=""
              className=""
            />
          </div>
        </Link>
        <div className='flex flex-col justify-center items-center text-center'>
          <div className='mb-[1rem]'>
            <img src={failureLogo} alt="successLogo" className='w-[90%]' />
          </div>
          <h1 className="text-[#1A3032] font-bold text-[1.5rem] xs:text-[1.8rem]">
            Unsuccessful !
          </h1>
          <p className='text-[0.8rem] text-[#8F9193]'>
            Your password is not changed. Go back and try again.
          </p>
        </div>
        <Link to={"/"}>
          <button
            type="submit"
            className="w-full text-white text-[1.1rem] rounded-md px-[1.5rem] py-[0.8rem] bg-primary font-bold"
          >
            Retry
          </button>
        </Link>
      </div>
    </div>
  )
}

