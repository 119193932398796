import React from 'react'
import { NavLink, Outlet, useLocation } from 'react-router-dom'

export default function ContainerWithdrawals() {

   const { pathname } = useLocation();
   console.log(pathname);

   return (
      <div>
         <div className='flex gap-[3rem] border-b-2 border-[#989898] px-[1rem] pt-[1rem] mx-[0.5rem] mt-[0.5rem]'>
            <NavLink to={`/admin/withdrawals`}>
               {({ isActive }) => (
                  <h2 className={`text-[1.2rem] sm:text-[1.3rem] whitespace-nowrap pb-[0.3rem] ${isActive && (pathname === "/admin/withdrawals" || pathname === "/admin/withdrawals/total") ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                     User withdrawals
                  </h2>
               )}
            </NavLink>
            <NavLink to={`/admin/withdrawals/agents`}>
               {({ isActive }) => (
                  <h2 className={`text-[1.2rem] sm:text-[1.3rem] whitespace-nowrap pb-[0.3rem] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                     Agents withdrawals
                  </h2>
               )}
            </NavLink>
         </div>
         <Outlet />
      </div>
   )
}

