import React, { useEffect, useMemo, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { Icon } from "@iconify/react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
// import 'flatpickr/dist/flatpickr.min.css';
import {
  IconButton,
  Option,
  Select,
  ThemeProvider,
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import CustomCheckbox from "../../../helpers/CustomCheckbox";
import moment from "moment";
import { Link, useOutletContext } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { APIurls } from "../../../api/apiConstant";
import { toast } from "react-toastify";
import { useAuth } from "../../../store/AuthContext";

export default function WalletCoinsRequests() {
  const [depositRequests, isLoading, refetch] = useOutletContext();
  const [status, setStatus] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const flatpickrRef = useRef();
  const [filteredItems, setFilteredItems] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});
  const { getAccessToken, userRole } = useAuth();

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        padding: "10px 10px",
        color: "#212529",
      },
    },
    head: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        fontSize: "15px",
      },
    },

    rows: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        color: "#565656",
        padding: "10px 10px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    pagination: {
      style: {
        boxShadow: "10px 5px 5px #ddd",
        marginBottom: "5px",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1600px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };
  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      minWidth: "60px",
      maxWidth: "60px",
    },
    {
      name: "Agent Name",
      id: "agentName",
      selector: (row) => row.agentName,
      center: true,
      grow: 2,
    },
    {
      name: "UTR Number",
      id: "utrNumber",
      selector: (row) => row.utrNumber,
      center: true,
      grow: 1.5,
    },
    {
      name: "Paid Amount",
      id: "paidAmount",
      selector: (row) => row.paidAmount,
      center: true,
      grow: 1.3,
    },
    {
      name: "Coins Credit",
      id: "coinsCredit",
      selector: (row) => row.receiveCoins,
      center: true,
      grow: 1.3,
    },
    {
      name: "Payment Proof",
      id: "paymentProof",
      selector: (row) => (
        <a href={row.paymentProof} target="_blank" rel="noreferrer"
          className="text-blue-600 font-medium underline">
          View Proof
        </a>
      ),
      center: true,
      grow: 1.3,
    },
    {
      name: "Requested Date",
      id: "requestedDate",
      selector: (row) => moment(row.createdAt).format("DD MMMM YYYY"),
      center: true,
      grow: 1.5,
    },
    {
      name: "Payment Method",
      id: "paymentMethod",
      selector: (row) => row.paymentMethod,
      center: true,
      grow: 1.5,
    },
    {
      name: "Status",
      id: "status",
      selector: (row) => (
        <div className={`flex justify-center items-center gap-2 w-32 py-2 font-semibold rounded-md
          ${row.status?.toLowerCase() === "approved" ? "text-[#25BF17] bg-[#D1F0CF]" : row.status?.toLowerCase() === "rejected" ? "text-[#FF0023] bg-[#FDCAD1]" : row.status?.toLowerCase() === "hold" ? "text-[#1A202C] bg-[#CFD0D3]" : "text-[#FFCE06] bg-[#f1ece3]"}`}>
          <span className={`${row.status?.toLowerCase() === "approved" ? "bg-[#25BF17]" : row.status?.toLowerCase() === "rejected" ? "bg-[#FF0023]" : row.status?.toLowerCase() === "hold" ? "bg-[#1A202C]" : "bg-[#FFCE06]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
          <h2>{row.status}</h2>
        </div>
      ),
      center: true,
      grow: 1.5,
    },
    {
      name: "Actions",
      id: "action",
      cell: (row) => (
        (userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("UPDATE_USER_WITHDRAWALS_REQUEST")) && (
          <div className="flex gap-3">
            {
              loadingStates[row.requestId] && loadingStates.status === "approved" ? (
                <ThreeDots
                  height="50"
                  width="50"
                  radius="9"
                  color="#25BF17"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                <IconButton onClick={() => updateWalletAgentDepositRequestStatusHandler(row.requestId, "approved")}
                  className="shadow-none hover:shadow-none focus:shadow-none text-[#25BF17] bg-[#E8F7E6] rounded-md border border-[#25BF17]"
                >
                  <Icon icon="mdi:tick" className="text-[1.5rem]" />
                </IconButton>
              )
            }
            {
              loadingStates[row.requestId] && loadingStates.status === "hold" ? (
                <ThreeDots
                  height="50"
                  width="50"
                  radius="9"
                  color="#25BF17"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                <IconButton onClick={() => updateWalletAgentDepositRequestStatusHandler(row.requestId, "hold")}
                  className="shadow-none hover:shadow-none focus:shadow-none text-[#1A202C] bg-[#CFD0D3] rounded-md border border-[#1A202C]"
                  disabled={row.status === "hold"}
                >
                  <Icon
                    icon="mdi:user-block"
                    className="text-[1.5rem]"
                  />
                </IconButton>
              )
            }
            {
              loadingStates[row.requestId] && loadingStates.status === "rejected" ? (
                <ThreeDots
                  height="50"
                  width="50"
                  radius="9"
                  color="#25BF17"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                <IconButton onClick={() => updateWalletAgentDepositRequestStatusHandler(row.requestId, "rejected")}
                  className="shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] rounded-md border border-[#FF0023]"
                >
                  <Icon
                    icon="material-symbols:close"
                    className="text-[1.5rem]"
                  />
                </IconButton>
              )
            }
          </div>
        )
      ),
      center: true,
      grow: 1.5,
    },
  ];

  const updateWalletAgentDepositRequestStatusHandler = async (reqId, statusType) => {
    setLoadingStates((prevState) => ({
      ...prevState,
      [reqId]: true,
      status: statusType,
    }))
    const token = await getAccessToken()
    try {
      const response = await fetch(`${APIurls.updateWalletAgentDepositRequestStatus}/${reqId}?status=${statusType}`, {
        method: 'PUT',
        body: JSON.stringify({
          statusType: statusType
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      })
      if (!response.ok) {
        throw new Error("Failed to Update Deposit Request status");
      }
      const result = await response.json();
      console.log(result);
      toast.success("Deposit Request status Updated Successfully");
      refetch();
    } catch (error) {
      console.warn("Failed to Update Deposit Request Status");
      toast.error("Failed to Update Deposit Request Status");
    }
    setLoadingStates((prevState) => ({
      ...prevState,
      [reqId]: false,
      status: "",
    }))
  }

  const handleDateChange = (selectedDates) => {
    console.log(selectedDates.length);

    if (selectedDates.length === 1) {
      const formatedStartDate = moment(selectedDates[0]).format("YYYY-MM-DD");
      setStartDate(formatedStartDate);
    }
    if (selectedDates.length === 2) {
      const formatedEndDate = moment(selectedDates[1]).format("YYYY-MM-DD");
      setEndDate(formatedEndDate);
    }
  };
  const dateOptions = {
    mode: "range",
    dateFormat: "d-m-Y",
  };
  const clearDatesHandler = () => {
    setStartDate(null);
    setEndDate(null);
    flatpickrRef.current.flatpickr.clear();
  }

  useEffect(() => {
    let mapData = depositRequests?.map((item, index) => {
      return { ...item };
    });
    if (searchKey !== "") {
      mapData = mapData.filter((item) => {
        return item?.agentName?.toLowerCase().includes(searchKey.toLowerCase());
      });
    }
    if (startDate && endDate) {
      const dStart = new Date(startDate);
      dStart.setUTCHours(0, 0, 0, 0);
      const dEnd = new Date(endDate);
      dEnd.setUTCHours(23, 59, 59, 999);
      mapData = mapData.filter((item) => {
        const itemDate = new Date(item.createdAt);
        return (
          itemDate.getTime() >= dStart.getTime() && itemDate.getTime() < dEnd.getTime()
        );
      });
    }
    if (status !== "") {
      if (status === "All") {
        setFilteredItems(mapData);
      } else {
        mapData = mapData.filter((item) => item.status === status);
      }
    }
    setFilteredItems(mapData);
  }, [searchKey, startDate, endDate, depositRequests, status]);

  return (
    <div className="py-[1rem]">
      {/* visible till Medium screen */}
      <div
        className="md:hidden flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[full] my-[1rem]"
        style={{
          border: "0.533975px solid #DFDFDF",
          borderRadius: "6px",
        }}
      >
        <div className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md ">
          <Icon
            icon="iconamoon:search-bold"
            className="text-[#272727] max-sm:text-[0.9rem]"
          />
        </div>
        <input
          type="text"
          className="text-[0.8rem] sm:text-[1rem] flex-1 bg-transparent placeholder-[#848484] outline-none "
          placeholder="Search here..."
          value={searchKey}
          onChange={(e) => {
            setSearchKey(e.target.value);
          }}
        />
      </div>
      <div className="flex flex-col max-xs:items-start xs:flex-row gap-[0.5rem] sm:gap-[1rem] justify-between items-center my-[1rem] xl:mx-[1rem]">
        {/* Visible from Medium Screen */}
        <h1 className="text-[1.2rem] font-semibold">
          List
        </h1>
        <div className="flex gap-[1rem] items-center px-[1rem]">
          <div
            className="max-md:hidden flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[300px] lg:w-[350px]"
            style={{
              border: "0.533975px solid #DFDFDF",
              borderRadius: "6px",
            }}
          >
            <input
              type="text"
              className="flex-1 bg-transparent  placeholder-[#848484] outline-none "
              placeholder="Search here..."
              value={searchKey}
              onChange={(e) => {
                setSearchKey(e.target.value);
              }}
            />
            {
              !searchKey ? (
                <button type="button"
                  className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:search" className="text-white" />
                </button>
              ) : (
                <button onClick={() => setSearchKey("")}
                  className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:cross" className="text-white" />
                </button>
              )
            }
          </div>
          <div className="max-md:w-full flex items-center gap-[0.5rem] py-[0.5rem] px-[1rem]"
            style={{
              border: "0.6px solid #DFDFDF",
              borderRadius: "6px",
            }}
          >
            <Flatpickr
              ref={flatpickrRef}
              className="text-[0.8rem] sm:text-[1rem] bg-transparent placeholder-[#848484] outline-none"
              placeholder="Select Date"
              options={dateOptions}
              onChange={(selectedDate) => handleDateChange(selectedDate)}
            />
            {
              !startDate || !endDate ? (
                <button type="button"
                  className="flex items-center cursor-pointer bg-primary py-[0.25rem] px-[0.2rem] rounded-md">
                  <Icon icon="solar:calendar-outline" className="text-white" />
                </button>
              ) : (
                <button onClick={clearDatesHandler}
                  className="flex items-center cursor-pointer bg-primary py-[0.25rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:cross" className="text-white" />
                </button>
              )
            }
          </div>
          <div className="">
            <Select label="Select Status"
              className="text-[0.8rem] sm:text-[1rem] bg-transparent"
              value={status}
              onChange={(value) => setStatus(value)}
            >
              <Option value="pending">pending</Option>
              <Option value="hold">hold</Option>
              <Option value="All">All</Option>
            </Select>
          </div>
          <div className="flex flex-row gap-[0.5rem] xs:gap-[1rem] items-center justify-center py-[0.2rem]">
            <div className="">
              <Menu>
                <MenuHandler>
                  <Button className="text-[0.8rem] sm:text-[1rem] flex w-full flex-row justify-center whitespace-nowrap capitalize text-white bg-primary px-[0.4rem] xs:px-[1rem] py-[0.5rem] rounded-md items-center gap-[0.5rem] sm:gap-[1rem] hover:shadow-white shadow-white outline-none">
                    <Icon
                      icon="material-symbols:download"
                      className="text-[1rem] w-[1.2rem] sm:w-[1.5rem] h-auto"
                    />
                    <span className="font-[500]">Export</span>
                  </Button>
                </MenuHandler>
                <MenuList>
                  <MenuItem
                    //  onClick={downloadPdf}
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                  >
                    {" "}
                    <Icon
                      icon="bxs:file-pdf"
                      className="text-[#BE0A23] w-[1.6rem] h-auto"
                    />
                    Download as PDF
                  </MenuItem>
                  <hr className="my-[0.2rem] sm:my-[0.5rem]" />
                  <MenuItem
                    //  onClick={downloadCSV}
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.9rem]"
                  >
                    <Icon
                      icon="fa-solid:file-csv"
                      className="text-[#29672E] w-[1.1rem] h-auto"
                    />
                    Download as CSV
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white px-[1rem]">
        <DataTable
          columns={columns}
          data={filteredItems}
          customStyles={customStyles}
          pagination
          progressPending={isLoading}
          selectableRows
          selectableRowsComponent={CustomCheckbox}
        />
      </div>
    </div>
  );
}

