import React from 'react'
import TotalAmount from './TotalAmount'
import TotalLottery from './TotalLottery'

export default function LotterySummary({ data }) {
  return (
    <div className='flex lg:flex-row flex-col justify-between items-center gap-6 mb-[40px]'>
      <TotalAmount></TotalAmount>
      <TotalLottery data={data} />
    </div>
  )
}
