import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react';
import { ThreeDots } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import "react-phone-number-input/style.css";
import { Checkbox, Option, Select } from '@material-tailwind/react';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useAuth } from '../../../store/AuthContext';
import { APIurls } from '../../../api/apiConstant';
import { useQuery } from 'react-query';

export default function EditSubAdmin() {
   const [setUpdateSubadminList] = useOutletContext();
   const [showError, setShowError] = useState(false);
   // const [postLoaderBtn, setPostLoaderBtn] = useState(false);
   const [designationStatus, setDesignationStatus] = useState(null);
   const [permissions, setPermissions] = useState([]);
   const [loaderBtn, setLoaderBtn] = useState(false);
   const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
   const { getAccessToken, userRole } = useAuth();
   const { id } = useParams();
   const navigate = useNavigate();

   const fetchSubAdminRoles = async () => {
      const token = await getAccessToken()
      const response = await fetch(APIurls.getSubAdminRoles, {
         method: "GET",
         headers: {
            Authorization: `Bearer ${token}`,
         }
      });
      if (!response.ok) {
         throw new Error("Failed to Fetch Roles");
      }
      const result = await response.json();

      console.log("SubAdmin Roles...", result)
      return result;
   };
   const { data: rolesData } = useQuery('updateRoles', fetchSubAdminRoles);

   const fetchSubadminById = async () => {
      const token = await getAccessToken()
      try {
         const response = await fetch(`${APIurls.fetchSubadminsById}/${id}`, {
            method: "GET",
            headers: {
               Authorization: `Bearer ${token}`,
            }
         });
         if (!response.ok) {
            throw new Error("Error Fetching Sub-Admin Data");
         }
         const result = await response.json();
         console.log("SUB ADMIN BY ID", result?.response?.SubadminData);
         const { name, email, MobileNumber, Designation, userRole } = result?.response?.SubadminData;
         setValue("name", name);
         setValue("email", email);
         setValue("mobileNumber", MobileNumber);
         setDesignationStatus(Designation);
         setPermissions(userRole?.role?.subAdmin?.permissions);
      } catch (error) {
         console.warn(error.message);
         toast.error(error.message);
      }
   };
   useEffect(() => {
      fetchSubadminById();
   }, [id])

   const handleCheckboxChange = (role) => {
      if (permissions.includes(role)) {
         setPermissions((prevPermissions) =>
            prevPermissions.filter((permission) => permission !== role)
         );
      } else {
         setPermissions((prevPermissions) => [...prevPermissions, role]);
      }
   }
   // console.log("PERMISSIONS", permissions);

   const onSubmit = async (data, event) => {
      event.preventDefault();
      if (!(designationStatus)) {
         setShowError(true);
         return;
      } else {
         setShowError(false);
      }
      const { name } = data;
      const token = await getAccessToken();
      try {
         setLoaderBtn(true)
         const response = await fetch(`${APIurls.editSubadmin}/${id}`, {
            method: 'POST',
            body: JSON.stringify({
               "name": name,
               "designation": designationStatus,
               "permissions": permissions,
            }),
            headers: {
               Authorization: `Bearer ${token}`,
               'Content-Type': 'application/json',
            }
         });

         if (!response.ok) {
            // toast.error(response.statusText);
            setLoaderBtn(false)
            if (response.status === 400) {
               const result = await response.json();
               console.error("Error Updating Subadmin:", result.message);
               toast.error(result?.message)
            } else if (response.status === 404) {
               const result = await response.json();
               console.error("Error Updating Subadmin:", result.message);
               toast.error(result?.message)
            } else {
               console.log(response.status)
               // Handle other non-OK responses
               throw new Error("Error Updating Subadmin");
            }
         } else {
            setLoaderBtn(false)
            // Handle successful response (status code 200 or 201)
            const result = await response.json();
            toast.success("Subadmin Successfully Updated!");
            navigate("/admin/sub-admin")
            setUpdateSubadminList(true);
            console.log(result);
            setDesignationStatus("");
            setPermissions([]);
            reset();
         }
      } catch (error) {
         setLoaderBtn(false)
         // Handle network errors or unexpected errors
         // toast.error("Error creating Lottery");
         toast.error("Error creating Lottery")
         console.warn(error);
      }
   }

   const formatKey = (key) => {
      return key.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
   };

   return (
      <div className="flex flex-col gap-[1rem] font-[500]">
         <form onSubmit={handleSubmit(onSubmit)}
            className="bg-white flex flex-col gap-[1rem] px-[2rem] rounded-lg">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[2rem] gap-y-[1rem]">
               <div className="flex flex-col gap-[0.5rem]">
                  <label htmlFor="userName" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                     User Name
                  </label>
                  <input
                     className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                     type="text"
                     placeholder="Enter User Name"
                     {...register("name", {
                        required: "*This field is required.",
                     })}
                  />
                  {errors.name && (
                     <div className="text-sm text-[#E92215]">
                        {errors.name.message}
                     </div>
                  )}
               </div>
               <div className="flex flex-col gap-[0.5rem]">
                  <label htmlFor="emailAddress" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                     Email Address
                  </label>
                  <input
                     className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                     type="email"
                     readOnly
                     {...register("email")}
                  />
               </div>
               <div className="flex flex-col gap-[0.5rem]">
                  <label htmlFor="pricePerTicket" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                     Mobile Number
                  </label>
                  <input
                     className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                     type="text"
                     readOnly
                     {...register("mobileNumber")}
                  />
               </div>
               <div className='flex flex-col gap-[0.5rem]'>
                  <label htmlFor="designation" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                     Designation
                  </label>
                  <Select className='text-black text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] placeholder:text-[#D9D9D9]'
                     label='select'
                     value={designationStatus}
                     onChange={(value) => setDesignationStatus(value)}
                  >
                     <Option value='Manager'>Manager</Option>
                     <Option value='Employee'>Employee</Option>
                     <Option value='Sub Admin'>Sub Admin</Option>
                     <Option value='employeeessss'>Employeeessss</Option>
                  </Select>
                  {showError && !designationStatus && (
                     <p className="text-[#E92215] text-sm">
                        *This field is required.
                     </p>
                  )}
               </div>
            </div>
            <div className='flex flex-col gap-[0.5rem]'>
               <label htmlFor="permissions" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                  Permissions
               </label>
               <div className='flex flex-wrap gap-[1rem]'>
                  {Object.keys(rolesData ? rolesData : {}).map((role) => (
                     <div key={role} className='flex gap-[0.5rem] items-center border border-[#D9D9D9] px-[1rem] py-[0.5rem] rounded-md'>
                        <input className='h-[1.2rem] w-[1.2rem] cursor-pointer'
                           type="checkbox"
                           id={role}
                           checked={permissions.includes(rolesData[role])}
                           onChange={() => handleCheckboxChange(rolesData[role])}
                        />
                        <label htmlFor={role} className='text-[#858585] cursor-pointer'>{formatKey(role)}</label>
                     </div>
                  ))}
               </div>
               {showError && !designationStatus && (
                  <p className="text-[#E92215] text-sm">
                     *Permissions Section Can't be Empty.
                  </p>
               )}
            </div>
            <div className="flex items-center justify-end">
               {(userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("EDIT_SUB_ADMIN")) && (
                  loaderBtn ? (
                     <ThreeDots
                        height="50"
                        width="50"
                        radius="9"
                        color="#9FB947"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                     />
                  ) :
                     (<button
                        className="text-[1.2rem] font-semibold text-white bg-primary py-[0.5rem] w-[12rem] rounded-[8px]"
                        type="submit"
                     >
                        Save Sub Admin
                     </button>)
               )}
            </div>
         </form>
         <ToastContainer />
      </div>
   )
}

