import React, { useEffect, useRef, useState } from 'react'
import { Icon } from '@iconify/react';
import { ThreeDots } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { Checkbox, Select as MaterialSelect, Option, Radio, Typography, input } from '@material-tailwind/react';
import Select from 'react-select';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { APIurls } from '../../../api/apiConstant';
import { useAuth } from '../../../store/AuthContext';
import { Navigate, useNavigate, useOutletContext, useParams } from 'react-router-dom';

export default function EditOffer() {
  const [setUpdateOffersList] = useOutletContext();
  const [showError, setShowError] = useState(false);
  const [loaderBtn, setLoaderBtn] = useState(false);
  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
  const [offerType, setOfferType] = useState("");
  const [typeOfOffer, setTypeOfOffer] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [percentage, setPercentage] = useState("");
  const [amount, setAmount] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [lotteryData, setLotteryData] = useState([]);
  const [selectedLotteriesId, setSelectedLotteriesId] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [selectedUsersId, setSelectedUsersId] = useState([]);
  const [lotterySelectOptions, setLotterySelectOptions] = useState([]);
  const [userSelectOptions, setUserSelectOptions] = useState([]);
  const { getAccessToken, userRole } = useAuth();
  const startDateRef = useRef();
  const expiryDateRef = useRef();
  const { id } = useParams();
  const navigate = useNavigate();

  // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: '0.1rem 0.3rem',
      border: state.isFocused || state.isHover ? '1px solid #2563EB' : '1px solid #D9D9D9',
      borderRadius: '6px',
      outline: 'none',
      boxShadow: 'none',
      fontSize: '0.75rem',
      '@media (min-width: 640px)': {
        fontSize: '1rem',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#D9D9D9',
    }),
    option: (provided) => ({
      ...provided,
      cursor: 'pointer',
    }),
  };

  const fetchLotteryData = async () => {
    const token = await getAccessToken()
    try {
      const response = await fetch(APIurls.fetchLottary, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        toast.error("Failed to Lottery Data in Offers");
        throw new Error("Failed to Fetch Lottery Data in Offers");
      }
      const result = await response.json();
      console.log("Lottary Data in Offers", result?.response?.Lottary);
      setLotteryData(result?.response?.Lottary.reverse().map((lottery) => {
        return {
          value: lottery._id,
          label: lottery.Name,
        }
      }));
    } catch (error) {
      console.log("Failed to Fetch Lottery Data in Offers")
    }
  };
  const fetchUsersData = async () => {
    const token = await getAccessToken()
    try {
      const response = await fetch(APIurls.fetchUsers, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        toast.error("Failed to Users Data in Offers");
        throw new Error("Failed to Fetch Users Data in Offers");
      }
      const result = await response.json();
      console.log("Users Data in Offers", result?.response?.Users);
      setUsersData(result?.response?.Users.reverse().map((user) => {
        return {
          value: user._id,
          label: user.FirstName + " " + user.LastName,
        }
      }));
    } catch (error) {
      console.log("Failed to Fetch Users Data in Offers")
    }
  };
  useEffect(() => {
    fetchLotteryData();
    fetchUsersData();
  }, [])

  const fetchOfferById = async () => {
    const token = await getAccessToken()
    try {
      const response = await fetch(`${APIurls.fetchOfferById}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        throw new Error("Error Fetching Offers Data");
      }
      const result = await response.json();
      console.log("OFFER BY ID", result?.response?.offer);
      const { type_offer, offerType, discount_amount, percentage, LottaryId, UserSpecific, coupon_code, title, UserUsageId, TotalUsage, userLimit, StartDate, ExpieryDate, terms } = result?.response?.offer;
      setValue("title", title);
      setValue("totalLimit", TotalUsage);
      setValue("limitForUser", userLimit);
      setValue("termsConditions", terms);
      setTypeOfOffer(type_offer);
      setOfferType(offerType.charAt(0).toUpperCase() + offerType.slice(1).toLowerCase());
      setAmount(discount_amount);
      setPercentage(percentage);
      setSelectedLotteriesId(LottaryId.map(lottery => lottery._id));
      setLotterySelectOptions(LottaryId.map((lottery) => {
        return {
          value: lottery._id,
          label: lottery.Name
        }
      }));
      setCouponCode(coupon_code);
      setSelectedUsersId(UserSpecific.map(user => user._id));
      setUserSelectOptions(UserSpecific.map((user) => {
        return {
          value: user._id,
          label: user.FirstName + " " + user.LastName,
        }
      }));
      setStartDate(StartDate);
      setExpiryDate(ExpieryDate);
    } catch (error) {
      console.warn(error.message);
      toast.error(error.message);
    }
  };
  useEffect(() => {
    fetchOfferById();
  }, [id])

  const onSubmit = async (data, event) => {
    if (!(typeOfOffer && (typeOfOffer !== "DISCOUNT" || offerType !== "") && (typeOfOffer !== "DISCOUNT" || selectedLotteriesId.length !== 0) && (offerType !== "Amount" || amount !== "") && (offerType !== "Percentage" || percentage !== "") && couponCode && startDate && expiryDate)) {
      setShowError(true);
      return;
    } else {
      setShowError(false);
    }
    try {
      setLoaderBtn(true)
      const { title, totalLimit, limitForUser, termsConditions } = data;
      const token = await getAccessToken()
      const response = await fetch(`${APIurls.updateOfferById}/${id}`, {
        method: "PUT",
        body: JSON.stringify({
          "title": title,
          "coupon_code": couponCode,
          "LottaryId": selectedLotteriesId,
          "terms": termsConditions,
          "type_offer": typeOfOffer,
          "offerType": offerType.toUpperCase(),
          "percentage": offerType === "Percentage" ? percentage : 0,
          "discount_amount": offerType === "Amount" ? amount : 0,
          "userLimit": Number(limitForUser),
          "TotalUsage": Number(totalLimit),
          "StartDate": startDate,
          "ExpiryDate": expiryDate,
          "UserSpecific": selectedUsersId,
          "LottarySpecific": selectedLotteriesId.length > 0,
          "isUserSpecific": selectedUsersId.length > 0,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      });

      if (!response.ok) {
        setLoaderBtn(false)
        if (response.status === 400) {
          const result = await response.json();
          console.error("Error Updating Offer:", result.message);
          console.log(result?.message)
          toast.error(result?.message)
        } else {
          console.log(response.status)
          throw new Error("Error Updating Offer");
        }
      } else {
        setLoaderBtn(false)
        const result = await response.json();
        toast.success("Successfully Updated Offer!");
        setUpdateOffersList(true);
        reset();
        setCouponCode("");
        setSelectedLotteriesId([]);
        setTypeOfOffer("");
        setOfferType("");
        setAmount("");
        setPercentage("");
        setStartDate(null);
        setExpiryDate(null);
        startDateRef.current.flatpickr.clear();
        expiryDateRef.current.flatpickr.clear();
        setSelectedUsersId([]);
        setLotterySelectOptions([]);
        setUserSelectOptions([]);
        navigate("/admin/offers-banners");
        console.log(result);
      }
    } catch (error) {
      setLoaderBtn(false)
      toast.error("Error Updating Offer")
      console.warn(error);
    }
  }

  const handleStartDate = (inputDate) => {
    setStartDate(inputDate[0]);
  };
  const handleExpiryDate = (inputDate) => {
    setExpiryDate(inputDate[0]);
  };
  const dateOptions = {
    mode: "single",  // for single date
    dateFormat: "d-m-Y",
  };
  const handleRadioBtnChange = (e) => {
    setOfferType(e.target.value);
  }

  const handleMultiLotterySelect = (selectedOptions) => {
    const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setSelectedLotteriesId(values);
    setLotterySelectOptions(selectedOptions);
  }
  const handleMultiUsersSelect = (selectedOptions) => {
    const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setSelectedUsersId(values);
    setUserSelectOptions(selectedOptions)
  }

  const generateCouponCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+-=';
    let couponCode = '';
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      couponCode += characters[randomIndex];
    }
    return couponCode;
  }

  const generateCouponCodeHandler = () => {
    const code = generateCouponCode();
    setCouponCode(code);
  }

  return (
    <div className="flex flex-col gap-[1rem] font-[500]">
      <form onSubmit={handleSubmit(onSubmit)}
        className="bg-white flex flex-col gap-[1rem] px-[2rem] rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[2rem] gap-y-[1rem]">
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="typeOfOffer" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Type of Offer
            </label>
            <MaterialSelect className='text-black text-[1rem] font-[500]'
              label='select'
              size='lg'
              color='blue-gray'
              variant='outlined'
              value={typeOfOffer}
              onChange={(value) => setTypeOfOffer(value)}
            >
              <Option value='DISCOUNT'>Discount</Option>
              <Option className='hidden' value='COMBODISCOUNT'>Combo Discounts Offer</Option>
              {/* <Option value='COMBO'>Combo Offers</Option> */}
            </MaterialSelect>
            {showError && !typeOfOffer && (
              <p className="text-sm text-[#E92215]">
                *This field is required
              </p>
            )}
          </div>
          {/* Type of Offer ==> Discount */}
          {
            typeOfOffer === "DISCOUNT" && (
              <>
                <div className='flex flex-col gap-2'>
                  <label htmlFor="offerType" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                    Offer Type
                  </label>
                  <div className='flex items-center justify-between gap-10 w-full'>
                    <div className='max-h-[43px] flex items-center border border-[#D9D9D9] w-full rounded-md px-2'>
                      <Radio name="offerType"
                        color='green'
                        label={
                          <Typography
                            // color="#858585"
                            className={`font-medium ${offerType === "Amount" ? "text-black" : "text-[#858585]"} ml-2`}
                          >
                            Amount
                          </Typography>
                        }
                        value="Amount"
                        checked={offerType === "Amount"}
                        onChange={handleRadioBtnChange}
                      />
                    </div>
                    <div className='max-h-[43px] flex items-center border border-[#D9D9D9] w-full rounded-md px-2'>
                      <Radio name="offerType"
                        color='green'
                        label={
                          <Typography
                            // color="#858585"
                            className={`font-medium ${offerType === "Percentage" ? "text-black" : "text-[#858585]"} ml-2`}
                          >
                            Percentage
                          </Typography>
                        }
                        value="Percentage"
                        checked={offerType === "Percentage"}
                        onChange={handleRadioBtnChange}
                      />
                    </div>
                  </div>
                  {showError && !offerType && (
                    <div className="text-sm text-[#E92215]">
                      *Please select anyone of the above
                    </div>
                  )}
                </div>
                {
                  offerType !== "" && (
                    offerType === "Amount" ?
                      (<div className='flex flex-col gap-2'>
                        <label htmlFor="discount" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                          Discount Amount
                        </label>
                        <input
                          className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                          type="number"
                          placeholder="Enter Discount Amount"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                        />
                        {showError && !amount && (
                          <div className="text-sm text-[#E92215]">
                            *This field is required
                          </div>
                        )}
                      </div>)
                      :
                      (<div className='flex flex-col gap-2'>
                        <label htmlFor="percentage" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                          Discount Percentage
                        </label>
                        <input
                          className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                          type="number"
                          placeholder="Enter Discount Percentage"
                          value={percentage}
                          onChange={(e) => setPercentage(e.target.value)}
                        />
                        {showError && !percentage && (
                          <div className="text-sm text-[#E92215]">
                            *This field is required
                          </div>
                        )}
                        {percentage > 100 && (
                          <div className="text-sm text-[#E92215]">
                            *Note: Percentage can't be greater than 100
                          </div>
                        )}
                      </div>)
                  )
                }
                <div className='flex flex-col gap-2'>
                  <label htmlFor="specificUsers" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                    Select Lottery
                  </label>
                  <Select
                    isMulti
                    name="lotteries"
                    options={lotteryData}
                    styles={customStyles}
                    value={lotterySelectOptions}
                    className="outline-none"
                    classNamePrefix="select"
                    onChange={handleMultiLotterySelect}
                  />
                  {showError && selectedLotteriesId.length === 0 && (
                    <div className="text-sm text-[#E92215]">
                      *This field is required
                    </div>
                  )}
                </div>
              </>
            )
          }
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="couponCode" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Coupon Code
            </label>
            <div className='flex justify-between items-center py-[0.3rem] px-[1rem] border border-[#D9D9D9] rounded-md '>
              <input
                className="text-[0.75rem] sm:text-[1rem] placeholder:text-[#D9D9D9] outline-none"
                type="text"
                placeholder="Enter Coupon Code"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
              />
              <button onClick={generateCouponCodeHandler}
                type='button'
                className='text-primary border-2 border-primary rounded-[4px] py-[0.13rem] px-[0.5rem]'>
                Auto Generate Code
              </button>
            </div>
            {showError && !couponCode && (
              <p className="text-sm text-[#E92215]">
                *This field is required
              </p>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="title" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Title
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="text"
              placeholder="Enter Title"
              {...register("title", {
                required: "*This field is required",
              })}
            />
            {errors.title && (
              <div className="text-sm text-[#E92215]">
                {errors.title.message}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="specificUsers" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Specific Users (Optional)
            </label>
            <Select
              isMulti
              name="users"
              options={usersData}
              styles={customStyles}
              value={userSelectOptions}
              className="outline-none"
              classNamePrefix="select"
              onChange={handleMultiUsersSelect}
            />

          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="totalLimit" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Total Limit
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="number"
              placeholder="Enter Total Limit"
              {...register("totalLimit", {
                required: "*This field is required",
              })}
            />
            {errors.totalLimit && (
              <div className="text-sm text-[#E92215]">
                {errors.totalLimit.message}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="limitForUser" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Limit For User
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="number"
              placeholder="Enter Limit For User"
              {...register("limitForUser", {
                required: "*This field is required",
              })}
            />
            {errors.limitForUser && (
              <div className="text-sm text-[#E92215]">
                {errors.limitForUser.message}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="startDate" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Start Date
            </label>
            <Flatpickr
              ref={startDateRef}
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              placeholder={`Select Start Date`}
              value={startDate}
              options={dateOptions}
              onChange={(selectedDate) => handleStartDate(selectedDate)}
            />
            {showError && !startDate && (
              <p className="text-sm text-[#E92215]">
                *This field is required
              </p>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="expiryDate" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Expiry Date
            </label>
            <Flatpickr
              ref={expiryDateRef}
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              placeholder={`Select Expiry Date`}
              value={expiryDate}
              options={dateOptions}
              onChange={(inputDate) => handleExpiryDate(inputDate)}
            />
            {showError && !expiryDate && (
              <p className="text-sm text-[#E92215]">
                *This field is required
              </p>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="termsConditions" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Terms & Conditions
            </label>
            <textarea rows={4}
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="text"
              placeholder="Enter Terms & Conditions"
              {...register("termsConditions", {
                required: "*This field is required",
              })}
            />
            {errors.termsConditions && (
              <div className="text-sm text-[#E92215]">
                {errors.termsConditions.message}
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center justify-end mt-[1rem]">
          {(userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("EDIT_OFFERS")) && (
            loaderBtn ? (
            <ThreeDots
              height="50"
              width="50"
              radius="9"
              color="#9FB947"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) :
            (<button
              className="text-[1.2rem] font-semibold text-white bg-primary py-[0.5rem] w-[12rem] rounded-[8px]"
              type="submit"
            >
              Save Offer
            </button>)
          )}
        </div>
      </form>
      <ToastContainer />
    </div>
  )
}

