import React from 'react'
import StepperController from '../../../helpers/StepperController'


export default function KYCDetails({currentStep, handleClick, steps}) {
    return (
        <div>
            <h1>
                KYCDetails
            </h1>
            <div className='px-[2rem]'>
                <StepperController currentStep={currentStep} handleClick={handleClick} steps={steps}/>
            </div>
        </div>
    )
}
