import React from 'react';
import { IconButton } from '@material-tailwind/react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Icon } from '@iconify/react';

export default function WithdrawalRejectModal() {

   const rejectHandler = (close) => {
      console.log("Rejecting Logic Here");
      close();
   }

   return (
      <Popup className='custom-popup'
         trigger={
            <IconButton
               className="shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8]"
               style={{
                  borderRadius: "6px",
                  border: "1px solid #FF0023",
               }}
            >
               <Icon
                  icon="material-symbols:close"
                  className="text-[1.5rem]"
               />
            </IconButton>}
         modal
         nested
      >
         {close => (
            <div className="">
               <div className="flex flex-col p-[1rem]">
                  <div className='flex justify-end w-full'>
                     <IconButton onClick={close}
                        className="shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8]"
                        size='sm'
                        style={{
                           borderRadius: "5px",
                           border: "1px solid #FF0023",
                        }}
                     >
                        <Icon
                           icon="material-symbols:close"
                           className="text-[1.5rem]"
                        />
                     </IconButton>
                  </div>
                  <div className='flex flex-col justify-between gap-[1rem] p-[1rem]'>
                     <h2 className='text-[1.3rem] font-bold'>
                        Are you sure?
                     </h2>
                     <p className='text-[1.1rem]'>
                        You are rejecting agent withdraw. this happens only if frauds occurs.
                     </p>
                     <div className='flex flex-col gap-[0.5rem]'>
                        <label htmlFor="description" className='font-bold'>
                           Reason for Rejecting
                        </label>
                        <textarea
                           name="" id="" rows="5" placeholder='Enter description...'
                           className='font-semibold px-[1rem] py-[0.5rem] border border-gray-600 rounded-lg'
                        />
                     </div>
                     <button onClick={() => rejectHandler(close)}
                        className='text-[1.1rem] text-white bg-[#FF0023] py-[0.6rem] rounded-lg font-semibold my-[0.5rem]'>
                        Reject
                     </button>
                     <button onClick={close}
                        className='text-[1.1rem] text-[#FF0023] border-2 border-[#FF0023] py-[0.6rem] rounded-lg font-semibold'>
                        Close
                     </button>
                  </div>
               </div>
            </div>
         )}
      </Popup>
   )
};

