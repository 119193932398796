import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import StepperController from "../../../helpers/StepperController";
import { useDispatch, useSelector } from "react-redux";
import { updateBankDetails } from "../../../store/createAgentSlice";
import "react-phone-number-input/style.css";
import { Option, Select } from "@material-tailwind/react";


export default function BankDetails({ currentStep, handleClick, steps }) {
  const [error, setError] = useState(false);
  const [showError, setShowError] = useState(false);

  const dispatch = useDispatch();
  const {
    accountNumber, ifscCode, accountHolderName, bankName, bankCity, branch, status
  } = useSelector((store) => store.createAgent.bankDetails);

  useEffect(() => {
    if (!(accountNumber && ifscCode && accountHolderName && bankCity && branch)) {
      setError(true);
    } else {
      setError(false);
    }
  }, [accountNumber, ifscCode, accountHolderName, bankCity, branch]);

  return (
    <div className="flex flex-col gap-[1rem] px-0 sm:px-[1rem] font-[500]">
      {/* <div className="text-primary flex items-center gap-[1rem] px-[1rem]">
        <Icon icon="heroicons-solid:user-group" className="text-[2rem]" />
        <h1 className="text-[1.2rem] sm:text-[1.7rem] font-semibold">
          Create Agent
        </h1>
      </div> */}
      <form
        className="bg-white flex flex-col gap-[2rem] px-[2rem] rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[2rem] gap-y-[1rem]">
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="acountNumber" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Bank Account Number<span className="text-[#E92215]">*</span>
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="text"
              placeholder="Enter Account Number"
              value={accountNumber}
              onChange={(e) => dispatch(updateBankDetails({
                accountNumber: e.target.value
              }))}
            />
            {showError && !accountNumber && (
              <p className="text-sm text-[#E92215]">
                *This field is required
              </p>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="ifscCode" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              IFSC Code<span className="text-[#E92215]">*</span>
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="text"
              placeholder="Enter IFSC Code"
              value={ifscCode}
              onChange={(e) => dispatch(updateBankDetails({
                ifscCode: e.target.value
              }))}
            />
            {showError && !ifscCode && (
              <p className="text-sm text-[#E92215]">
                *This field is required
              </p>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="accountHolderName" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Name of the Account Holder<span className="text-[#E92215]">*</span>
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="text"
              placeholder="Enter Account Holder Name"
              value={accountHolderName}
              onChange={(e) => dispatch(updateBankDetails({
                accountHolderName: e.target.value,
              }))}
            />
            {showError && !accountHolderName && (
              <p className="text-sm text-[#E92215]">
                *This field is required
              </p>
            )}
          </div>
          {/* <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="bankName" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Name of the Bank<span className="text-[#E92215]">*</span>
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="text"
              placeholder="Enter Bank Name"
              value={bankName}
              onChange={(e) => dispatch(updateBankDetails({
                bankName: e.target.value,
              }))}
            />
            {showError && !bankName && (
              <p className="text-sm text-[#E92215]">
                *This field is required
              </p>
            )}
          </div> */}
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="bankCity" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Bank City<span className="text-[#E92215]">*</span>
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="text"
              placeholder="Enter Bank City"
              value={bankCity}
              onChange={(e) => dispatch(updateBankDetails({
                bankCity: e.target.value,
              }))}
            />
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="branch " className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Branch<span className="text-[#E92215]">*</span>
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="branch"
              placeholder="Enter Branch"
              value={branch}
              onChange={(e) => dispatch(updateBankDetails({
                branch: e.target.value,
              }))}
            />
          </div>
          {/* <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="totalWinningAmount" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Status<span className="text-[#E92215]">*</span>
            </label>
            <Select className="text-black text-[1rem]"
              label="Select Status"
              value={status}
              onChange={(value) => dispatch(updateBankDetails({
                status: value,
              }))}
            >
              <Option value="Active">Active</Option>
              <Option value="Inactive">Inactive</Option>
            </Select>
            {showError && !status && (
              <p className="text-sm text-[#E92215]">
                *This field is required
              </p>
            )}
          </div> */}
        </div>
        <div>
          <StepperController currentStep={currentStep} error={error} setShowError={setShowError} handleClick={handleClick} steps={steps} />
        </div>
      </form>
    </div>
  )
}

