import { useEffect, useState } from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line, CartesianAxis } from 'recharts';
import { Icon } from '@iconify/react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAuth } from '../../../../store/AuthContext';
import { APIurls } from '../../../../api/apiConstant';
import { toast } from 'react-toastify';

const data = [
  { month: 'May', "totalAmount": 10 },
  { month: 'Jun', "totalAmount": 20 },
  { month: 'Jul', "totalAmount": 30 },
  { month: 'Aug', "totalAmount": 60 },
  { month: 'Sep', "totalAmount": 50 },
  { month: 'Oct', "totalAmount": 40 },
  { month: 'Nov', "totalAmount": 70 },
  { month: 'Dec', "totalAmount": 40 },
  { month: 'Dec', "totalAmount": 40 },
  { month: 'Dec', "totalAmount": 40 },
  { month: 'Dec', "totalAmount": 40 },
  { month: 'Dec', "totalAmount": 40 },
];

const renderTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className='shadow-lg'>
        <header className='bg-[#25BF17] px-5 py-2 text-[12px] font-semibold text-white'>{label}</header>
        <div className='flex items-center px-5 py-3 gap-2 bg-white'>
          <Icon icon="material-symbols-light:circle" className="text-[#25BF17] w-2 h-2" />
          <p className='text-[10px]'>Total Amount : {payload[0]?.value}</p>
        </div>
      </div>
    )
  }

  return null;
}


export default function TotalAmount() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loaderBtn, setLoaderBtn] = useState(false);
  const [dateType, setDateType] = useState("MONTH");

  const [totalAmount, setTotalAmount] = useState([]);
  const { getAccessToken, userRole } = useAuth();

  const fetchUserCountHandler = async () => {
    setLoaderBtn(true);
    const token = await getAccessToken()
    try {
      const response = await fetch(`${APIurls.fetchTotalAmount}/${dateType}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        throw new Error("Error fetching Total Amount");
      }
      const result = await response.json();
      console.log("Total Amount", result?.response?.totalAmount);
      setTotalAmount(result?.response?.totalAmount);
    } catch (error) {
      console.log("Error fetching Total Amount");
      toast.error("Error fetching Total Amount");
    }
    setLoaderBtn(false);
  }

  useEffect(() => {
      fetchUserCountHandler();
  }, [dateType])

  return (
    <div className='p-4 rounded-lg border-gray-300 border-[1px] lg:w-4/6 w-full flex flex-col justify-between h-[500px]'>
      <header className='flex justify-between items-center pb-10'>
        <div className='flex items-center gap-2'>
          <Icon icon="material-symbols-light:circle" className="text-[#25BF17]" />
          <h3 className='text-[24px] font-medium'>Total Amount</h3>
        </div>
        {/* <div className="flex gap-2">
          <div className='flex items-center border-2 border-gray-200 p-1 rounded-lg'>
            <DatePicker className='outline-none w-[90px]' selected={startDate} placeholderText='From' onChange={(date) => setStartDate(date)} />
            <Icon icon="healthicons:calendar" className='w-[18px] h-[16px] text-gray-600' />
          </div>

          <div className='flex items-center border-2 border-gray-200 p-1 rounded-lg'>
            <DatePicker className='outline-none w-[90px]' selected={endDate} placeholderText='To' onChange={(date) => setEndDate(date)} />
            <Icon icon="healthicons:calendar" className='w-[18px] h-[16px] text-gray-600' />
          </div>
        </div> */}
        <div>
          <button onClick={() => setDateType("WEEK")}
            className={`${dateType === "WEEK" && "bg-[#25BF17] text-white font-medium"} px-3 py-[2px]`}>
            Week
          </button>
          <button onClick={() => setDateType("MONTH")}
            className={`${dateType === "MONTH" && "bg-[#25BF17] text-white font-medium"} px-3 py-[2px]`}>
            Month
          </button>
          <button onClick={() => setDateType("YEAR")}
            className={`${dateType === "YEAR" && "bg-[#25BF17] text-white font-medium"} px-3 py-[2px]`}>
            Year
          </button>
        </div>
      </header>

      <ResponsiveContainer width="100%" height="100%">
        <LineChart width={300} height={300} data={totalAmount}>
          {/* <XAxis dataKey={dateType === "WEEK" ? "day" : dateType === "MONTH" ? "month" : "year"}/> */}
          <XAxis dataKey={dateType === "WEEK" ? "day" : dateType === "MONTH" ? "month" : "year"} tick={{ fontSize: 11, color: 'black', fontWeight: '600' }} interval={0} />
          <YAxis tick={{ fontSize: 14, color: 'black', fontWeight: '600' }} />
          <Tooltip content={renderTooltip} />
          <CartesianGrid horizontal={true} vertical={false} strokeDasharray="3 3" />
          <Line type="monotone" dataKey="revenue" stroke="#25BF17" strokeWidth={2} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}
