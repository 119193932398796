import { Outlet, useNavigate } from "react-router-dom";
import NumberGameViewTabs from "./NumberGameViewTabs";
import { Icon } from "@iconify/react/dist/iconify.js";
import NumberGameViewCards from "./NumberGameViewCards";

export default function NumberGameViewPanel() {
  const navigate = useNavigate()
  return (
    <main>
      <div className="flex justify-between items-center">
        <div onClick={()=>navigate("/admin/number-game")} className="bg-primary cursor-pointer text-white flex items-center gap-5 md:px-5 px-3 py-2 font-medium rounded-[5px]">
          <Icon className="text-[1.5rem]" icon="weui:back-filled" />
          <button>Back</button>
        </div>
        <button className="bg-primary text-white md:px-5 px-3 py-2 font-medium rounded-[5px]">
          Announce Winners
        </button>
      </div>

      <NumberGameViewCards/>
      <NumberGameViewTabs />
      <Outlet />
    </main>
  );
}
