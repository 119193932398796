import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  personalDetails: {
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    whatsAppNumber: "",
    telegramNumber: "",
    nationality: "",
    passportNumber: "",
    address: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
  },
  bankDetails: {
    accountNumber: "",
    ifscCode: "",
    accountHolderName: "",
    bankName: "",
    bankCity: "",
    branch: "",
    status: "",
  },
  loaderBtn: false,
}

const createWalletAgentSlice = createSlice({
  name: "createWalletAgent",
  initialState: initialState,
  reducers: {
    updatePersonalDetails(state, action) {
      state.personalDetails = { ...state.personalDetails, ...action.payload }
    },
    updateBankDetails(state, action) {
      state.bankDetails = { ...state.bankDetails, ...action.payload }
    },
    updateLoaderBtn(state, action) {
      state.loaderBtn = action.payload;
    }
  }
})

export const { updatePersonalDetails, updateBankDetails, updateLoaderBtn } = createWalletAgentSlice.actions;

export default createWalletAgentSlice.reducer;
