import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import "flatpickr/dist/themes/material_green.css";
import StepperController from "../../../helpers/StepperController";
import { useDispatch, useSelector } from "react-redux";
import { updatePersonalDetails } from "../../../store/createAgentSlice";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import "react-phone-number-input/style.css";
import { formatPhoneNumberIntl } from "react-phone-number-input";


export default function PersonalDetails({ currentStep, handleClick, steps }) {
   const [selectImg, setSelectImg] = useState();
   const [error, setError] = useState(false);
   const [showError, setShowError] = useState(false);
   const [isEmailValid, setIsEmailValid] = useState(true);
   const [isMobileNumberValid, setIsMobileNumberValid] = useState(true);
   const [isWhatsAppNumberValid, setIsWhatsAppNumberValid] = useState(true);
   // const navigate = useNavigate();
   const dispatch = useDispatch();
   const {
      firstName, lastName, email, mobileNumber, whatsAppNumber, telegramNumber, nationality, passportNumber, country, state, city, pincode, address } = useSelector((store) => store.createAgent.personalDetails);

   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

   useEffect(() => {
      if (!(firstName && lastName && email && isEmailValid && mobileNumber && isMobileNumberValid && whatsAppNumber && isWhatsAppNumberValid && address && city && state && country && pincode)) {
         setError(true);
      } else {
         setError(false);
      }
   }, [firstName, lastName, email, isEmailValid, mobileNumber, isMobileNumberValid, whatsAppNumber, isWhatsAppNumberValid, address, city, state, country, pincode]);

   return (
      <div className="flex flex-col gap-[1rem] px-0 sm:px-[1rem] font-[500]">
         <form
            className="bg-white flex flex-col gap-[2rem] px-[2rem] rounded-lg">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[2rem] gap-y-[1rem]">
               <div className="flex flex-col gap-[0.5rem]">
                  <label htmlFor="firstName" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                     First Name<span className="text-[#E92215]">*</span>
                  </label>
                  <input
                     className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                     type="text"
                     placeholder="Enter First Name"
                     value={firstName}
                     onChange={(e) => {
                        if (!(/[\d]/.test(e.target.value))) {
                           dispatch(updatePersonalDetails({ firstName: e.target.value }));
                        }
                     }}
                  />
                  {showError && !firstName && (
                     <p className="text-sm text-[#E92215]">
                        *This field is required
                     </p>
                  )}
               </div>
               <div className="flex flex-col gap-[0.5rem]">
                  <label htmlFor="lastName" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                     Last Name<span className="text-[#E92215]">*</span>
                  </label>

                  <input
                     className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                     type="text"
                     placeholder="Enter Last Name"
                     value={lastName}
                     onChange={(e) => {
                        if (!(/[\d]/.test(e.target.value))) {
                           dispatch(updatePersonalDetails({ lastName: e.target.value }));
                        }
                     }}
                  />
                  {showError && !lastName && (
                     <p className="text-sm text-[#E92215]">
                        *This field is required
                     </p>
                  )}
               </div>
               <div className="flex flex-col gap-[0.5rem]">
                  <label htmlFor="emailId" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                     Email Id<span className="text-[#E92215]">*</span>
                  </label>
                  <input
                     className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder-[#D9D9D9] rounded-md outline-none"
                     type="email"
                     placeholder="Enter Email"
                     value={email}
                     onChange={(e) => {
                        if (emailRegex.test(e.target.value)) {
                           setIsEmailValid(true)
                        }
                        else {
                           setIsEmailValid(false)
                        }
                        dispatch(updatePersonalDetails({ email: e.target.value }));
                     }}
                  />
                  {((showError && !email) || !isEmailValid) && <p className="text-[#E92215] text-sm">{(!isEmailValid && showError) ? "Invalid email" : showError ? "*This field is required." : ""}</p>}
               </div>
               <div className="flex flex-col gap-[0.5rem]">
                  <label htmlFor="mobileNumber" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                     Mobile Number<span className="text-[#E92215]">*</span>
                  </label>
                  <PhoneInput className='text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none'
                     initialValueFormat="national"
                     type="text"
                     defaultCountry="IN"
                     placeholder='Enter Your Mobile Number'
                     value={formatPhoneNumberIntl(mobileNumber + '')}
                     onChange={(value) => {
                        if (isValidPhoneNumber(value + '')) {
                           dispatch(updatePersonalDetails({ mobileNumber: value }))
                           setIsMobileNumberValid(true)
                        } else {
                           setIsMobileNumberValid(false);
                        }
                     }}
                  />
                  {((showError && !mobileNumber) || !isMobileNumberValid) && <p className="text-[#E92215] text-sm">{(!isMobileNumberValid && showError) ? "Invalid mobile number" : showError ? "*This field is required." : ""}</p>}
               </div>
               <div className="flex flex-col gap-[0.5rem]">
                  <label htmlFor="whatsAppNumber" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                     WhatsApp Number<span className="text-[#E92215]">*</span>
                  </label>
                  <PhoneInput className='text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none'
                     initialValueFormat="national"
                     type="text"
                     defaultCountry="IN"
                     placeholder='Enter Your WhatsApp Number'
                     value={formatPhoneNumberIntl(whatsAppNumber + '')}
                     onChange={(value) => {
                        if (isValidPhoneNumber(value + '')) {
                           dispatch(updatePersonalDetails({ whatsAppNumber: value }))
                           setIsWhatsAppNumberValid(true);
                        } else {
                           setIsWhatsAppNumberValid(false);
                        }
                     }}
                  />
                  {((showError && !whatsAppNumber) || !isWhatsAppNumberValid) && <p className="text-[#E92215] text-sm">{(!isWhatsAppNumberValid && showError) ? "Invalid mobile number" : showError ? "*This field is required." : ""}</p>}
               </div>
               {/* <div className="flex flex-col gap-[0.5rem]">
                  <label htmlFor="termsAndConditions " className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                     Telegram Number
                  </label>
                  <PhoneInput className='text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none'
                     initialValueFormat="national"
                     type="text"
                     defaultCountry="IN"
                     placeholder='Enter Your Telegram Number'
                     value={formatPhoneNumberIntl(telegramNumber + '')}
                     onChange={(value) => {
                        if (isValidPhoneNumber(value + '')) {
                           dispatch(updatePersonalDetails({ telegramNumber: value }))
                        }
                     }}
                  />
               </div> */}
               {/* <div className="flex flex-col gap-[0.5rem]">
                  <label htmlFor="totalWinningAmount" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                     Nationality<span className="text-[#E92215]">*</span>
                  </label>
                  <input
                     className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                     type="text"
                     placeholder="Enter Nationality"
                     value={nationality}
                     onChange={(e) => dispatch(updatePersonalDetails({
                        nationality: e.target.value,
                     }))}
                  />
                  {showError && !nationality && (
                     <p className="text-sm text-[#E92215]">
                        *This field is required
                     </p>
                  )}
               </div> */}
               {/* <div className="flex flex-col gap-[0.5rem]">
                  <label htmlFor="numOfWinners" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                     Passport Number<span className="text-[#E92215]">*</span>
                  </label>
                  <input
                     className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                     type="text"
                     placeholder="Enter Passport Number"
                     value={passportNumber}
                     onChange={(e) => dispatch(updatePersonalDetails({
                        passportNumber: e.target.value,
                     }))}
                  />
                  {showError && !passportNumber && (
                     <p className="text-sm text-[#E92215]">
                        *This field is required
                     </p>
                  )}
               </div> */}
               <div className="flex flex-col gap-[0.5rem]">
                  <label htmlFor="city " className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                     Address<span className="text-[#E92215]">*</span>
                  </label>
                  <input
                     className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                     type="text"
                     placeholder="Enter Address"
                     value={address}
                     onChange={(e) => dispatch(updatePersonalDetails({
                        address: e.target.value,
                     }))}
                  />
                  {showError && !address && (
                     <p className="text-sm text-[#E92215]">
                        *This field is required
                     </p>
                  )}
               </div>
               <div className="flex flex-col gap-[0.5rem]">
                  <label htmlFor="city " className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                     City<span className="text-[#E92215]">*</span>
                  </label>
                  <input
                     className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                     type="text"
                     placeholder="Enter City"
                     value={city}
                     onChange={(e) => dispatch(updatePersonalDetails({
                        city: e.target.value,
                     }))}
                  />
                  {showError && !city && (
                     <p className="text-sm text-[#E92215]">
                        *This field is required
                     </p>
                  )}
               </div>
               <div className="flex flex-col gap-[0.5rem]">
                  <label htmlFor="state " className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                     State<span className="text-[#E92215]">*</span>
                  </label>
                  <input
                     className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                     type="text"
                     placeholder="Enter State"
                     value={state}
                     onChange={(e) => dispatch(updatePersonalDetails({
                        state: e.target.value,
                     }))}
                  />
                  {showError && !state && (
                     <p className="text-sm text-[#E92215]">
                        *This field is required
                     </p>
                  )}
               </div>
               <div className="flex flex-col gap-[0.5rem]">
                  <label htmlFor="country " className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                     Country<span className="text-[#E92215]">*</span>
                  </label>
                  <input
                     className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                     type="text"
                     placeholder="Enter Country"
                     value={country}
                     onChange={(e) => dispatch(updatePersonalDetails({
                        country: e.target.value,
                     }))}
                  />
                  {showError && !country && (
                     <p className="text-sm text-[#E92215]">
                        *This field is required
                     </p>
                  )}
               </div>
               <div className="flex flex-col gap-[0.5rem]">
                  <label htmlFor="pincode " className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                     Pincode<span className="text-[#E92215]">*</span>
                  </label>
                  <input
                     className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                     type="number"
                     placeholder="Enter Pincode"
                     value={pincode}
                     onChange={(e) => dispatch(updatePersonalDetails({
                        pincode: e.target.value,
                     }))}
                  />
                  {showError && !pincode && (
                     <p className="text-sm text-[#E92215]">
                        *This field is required
                     </p>
                  )}
               </div>
               <div className="flex flex-col gap-[0.5rem]">
                  <label htmlFor="profilePicture" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                     Profile Picture
                  </label>
                  <div className="flex flex-col items-center py-[2rem] px-[1rem] border-2 border-dashed
                         border-[#D9D9D9] rounded-lg">
                     {selectImg?.name ? (
                        <>
                           <h2 className="text-[1.1rem] font-semibold text-green-600 my-[1rem]">
                              Uploaded document
                           </h2>
                           <div className="flex gap-[1rem] items-center">
                              <h2 className="text-[1.1rem] text-black font-[500]">
                                 {selectImg?.name.length > 30
                                    ? selectImg.name.substring(0, 30) + "..."
                                    : selectImg.name}
                              </h2>
                              <Icon
                                 onClick={() => setSelectImg(null)}
                                 icon="akar-icons:cross"
                                 className="text-[1.2rem] cursor-pointer hover:bg-[#3D4A5C]/[0.3]"
                              />
                           </div>
                        </>
                     ) : (
                        <>
                           <button
                              type="button"
                              onClick={() =>
                                 document.querySelector(".input-field").click()
                              }
                           >
                              <Icon
                                 icon="material-symbols:cloud-upload"
                                 className="text-[#D9D9D9] text-[4rem] mb-[0.5rem]"
                              />
                           </button>
                           <input
                              type="file"
                              // accept="image/*"
                              value=""
                              className="input-field"
                              hidden
                              onChange={({ target: { files } }) => {
                                 if (files[0]) {
                                    setSelectImg(files[0]);
                                 }
                              }}
                           />
                           <p className="text-[0.9rem] text-[#D9D9D9]">
                              Upload jpg, png, pdf. Size up to 20MB
                           </p>
                        </>
                     )}
                  </div>
               </div>
            </div>
            <div>
               <StepperController currentStep={currentStep} error={error} setShowError={setShowError} handleClick={handleClick} steps={steps} />
            </div>
         </form>
      </div>
   )
}
