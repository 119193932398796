import React from 'react'
import { Outlet } from 'react-router-dom'


export default function AdminPanelAgents() {
  return (
    <div className=''>
      <Outlet />
    </div>
  )
}
