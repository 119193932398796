import React, { useState } from "react";
import { IconButton } from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";
import { toast } from "react-toastify";
import NumberInput from "../../../helpers/NumberInput";
import { ThreeDots } from 'react-loader-spinner'

export default function ViewPrivateAgentRechargeModal({ setShowModal, id, setIsRefetch, setViewRefetch }) {
  const [coins, setCoins] = useState("");
  const [showError, setShowError] = useState("");
  const [loaderBtn, setLoaderBtn] = useState(false);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const { getAccessToken } = useAuth();

  const submitHandler = async () => {
    if (!coins || coins < 100) {
      setShowError(true);
      return;
    }
    setDisableSubmitBtn(true);
    const token = await getAccessToken();
    setLoaderBtn(true);
    try {
      const response = await fetch(`${APIurls.addCoinsToWalletAgent}/${id}`, {
        method: "POST",
        body: JSON.stringify({
          "walletAmt": parseFloat(coins),
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
      );
      if (!response.ok) {
        if (response.statusCode === 400) {
          const errorResult = await response.json();
          toast.error(errorResult);
          throw new Error(errorResult);
        } else {
          toast.error("Failed to Add Coins");
          throw new Error("Failed to Add Coins");
        }
      }
      const result = await response.json();
      toast.success("Coins Added successfully");
      setShowModal(false);
      setIsRefetch(true);
      setViewRefetch(true);
      console.log("Coins Added -->", result);
    } catch (error) {
      console.warn("Add Coins Error -->", error);
    }
    setLoaderBtn(false);
    setDisableSubmitBtn(false);
  };

  return (
    <div className="flex items-center justify-center absolute bg-[#000000E6] h-full w-full inset-0 z-10">
      <div className="flex flex-col gap-[30px] bg-white p-[40px] min-w-[500px] rounded-2xl">
        <div className="flex gap-24 items-center justify-between mb-[-10px]">
          <div className="text-[#212529] text-[24px] font-bold ">
            Add Coins to Agent wallet
          </div>
          <IconButton
            onClick={() => setShowModal(false)}
            className="shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-md"
            size="sm"
          >
            <Icon icon="material-symbols:close" className="text-[1.5rem]" />
          </IconButton>
        </div>

        <div className="flex flex-col gap-[0.5rem]">
          <label htmlFor="amount" className="text-[#858585] font-medium">
            Enter Amount{" "}
            <span className="text-red-600 text-base font-semibold">*</span>
          </label>
          <div className="flex flex-col gap-[0.5rem]">
            <NumberInput
              className="text-[0.75rem] sm:text-[1rem] font-medium placeholder:text-[#7F7F7F] placeholder:text-[0.9rem] outline-none border border-[#D9D9D9] py-[0.6rem] px-[1rem] rounded-[10px]"
              type="number"
              placeholder="1000"
              value={coins}
              onChange={(e) => setCoins(e.target.value)}
            />
            {showError && !coins && (
              <p className="text-[#E92215] text-sm">*This field is required</p>
            )}
            {showError && coins < 100 && coins && (
              <p className="text-[#E92215] text-sm">*Wallet amount should be greater than or equal to 100</p>
            )}
          </div>
        </div>
        <div className="flex items-center justify-end">
          {
            loaderBtn ? (
              <ThreeDots
                height="50"
                width="50"
                radius="9"
                color="#24B24B"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <button
                disabled={disableSubmitBtn}
                type="button"
                className="text-center justify-center text-[0.8rem] sm:text-[18px] flex items-center max-xs:w-full gap-[0.3rem] xs:gap-[1rem] bg-[#24B24B] text-white py-[0.5rem] px-[2rem] xs:px-[2rem] font-semibold rounded-[10px]"
                onClick={submitHandler}
              >
                Add Coins
              </button>
            )
          }
        </div>
      </div>
    </div>
  );
}

