import { NavLink } from "react-router-dom";
const setActiveStyle = ({ isActive }) => {
  return {
    color: isActive ? "#25BF17" : "#858585",
    fontWeight: isActive ? "600" : "400",
    borderBottom: isActive ? "2px solid #25BF17" : "2px solid #fff",
  };
};
export default function NumberGameViewTabs() {
  return (
    <div className="flex items-center gap-[2.5rem] border-b-[2px] border-b-[#989898] mb-[1rem]">
      <NavLink style={setActiveStyle} to="" end>
        <p className="text-[1.25rem] cursor-pointer  pb-3">Users</p>
      </NavLink>
      <NavLink style={setActiveStyle} to="wishlist" end>
        <p className="text-[1.25rem] cursor-pointer  pb-3">Wishlist</p>
      </NavLink>
      <NavLink style={setActiveStyle} to="winners" end>
        <p className="text-[1.25rem] cursor-pointer  pb-3">Winners</p>
      </NavLink>
    </div>
  );
}
