import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";
import { RotatingLines, ThreeDots } from 'react-loader-spinner';
import _ from "lodash";
import { generateLotteryNumber } from "../../../utils/lottaryGen";
import moment from "moment";

export default function CreatePrivateLotteries() {
  const [selectImg, setSelectImg] = useState();
  const navigate = useNavigate();
  const [selectDate, setSelectDate] = useState(null);
  const [postLoaderBtn, setPostLoaderBtn] = useState(false);
  const [fetchLoaderBtn, setFetchLoaderBtn] = useState(false);
  const { register, handleSubmit, formState: { errors }, setValue, control, reset } = useForm();
  const [lottarynumber, setLottarynumber] = useState("")
  const { id } = useParams();
  const [privateLotteryData, setPrivateLotteryData] = useState([]);

  const [winnerSlotsdata, setWinnerslot] = useState(0)
  const { getAccessToken } = useAuth();

  // Initialize state to store input values
  const [inputValues, setInputValues] = useState(Array(winnerSlotsdata).fill(''));

  const submitWinnerSlot = (num) => {
    if (num < 0) {
      return setWinnerslot(0)
    }
    if (num > 0) {
      const data = {
        winnerNum: 0
      }
      setWinnerslot(num)
    }
  }

  const fetchPrivateLotteryDataById = async () => {
    setFetchLoaderBtn(true);
    const token = await getAccessToken()

    try {
      const response = await fetch(`${APIurls.fetchSinglePrivateLottary}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        throw new Error("Error Fetching Private Lottery Data");
      }
      const result = await response.json();
      // setAgentsData(result?.response)
      const {
        Name, LottaryNumber, Totaltickets, ticketPrice, winneramount, termsandcondi, expieryDate
      } = result?.response?.LottaryData;
      setValue("Name", Name);
      setValue("LottaryNumber", LottaryNumber);
      setValue("Totaltickets", Totaltickets);
      setValue("ticketPrice", ticketPrice);
      setValue("winneramount", winneramount);
      setValue("termsAndConditions", termsandcondi);
      const formattedDate = moment(expieryDate).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)');
      setSelectDate(formattedDate)
      setPrivateLotteryData(result?.response?.LottaryData);
      console.log("Private Lottery Data", result?.response?.LottaryData);
    } catch (error) {
      console.warn("Error Fetching Private Lottery Data");
      toast.error("Error Fetching Private Lottery Data");
    }
    setFetchLoaderBtn(false);
  }

  useEffect(() => {
    setLottarynumber(generateLotteryNumber());
    fetchPrivateLotteryDataById();
  }, [])


  const onSubmit = async (data, event) => {
    event.preventDefault();
    const { Name, Totaltickets, ticketPrice, termsAndConditions } = data;
    const formData = new FormData();
    formData.append("lottaryUniqueId", privateLotteryData?.UniqueID);
    formData.append("Names", Name);
    formData.append("totalTickets", Totaltickets);
    formData.append("priceper", ticketPrice);
    formData.append("expieryDates", selectDate);
    formData.append("termsandcondis", termsAndConditions);
    if (selectImg) {  // if the new image is selected
      formData.append("lottaryImage", selectImg);
    }
    // formData.append("winneramount", "50000");
    // formData.append("winnerSlot", "2");
    // formData.append("Currency", "IND");
    // formData.append("LottaryNumber", uniqueNumber);
    // formData.append("winnerSlotArray", []);
    const token = await getAccessToken()
    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      }
    };

    try {
      setPostLoaderBtn(true)
      const response = await fetch(`${APIurls.editPrivateLottary}`, requestOptions);

      if (!response.ok) {
        setPostLoaderBtn(false)
        if (response.status === 400) {
          // Handle 400 Bad Request error
          const result = await response.json();
          console.error("Error creating Lottery:", result.error);
          // Additional handling for 400 status code, if needed
          // Example: setValidationError(result.validationError);
          console.log(result?.message)
          toast.error(result?.message)
        } else {
          console.log(response.status)
          // Handle other non-OK responses
          throw new Error("Error creating Lottery");
        }
      } else {
        setPostLoaderBtn(false)
        // Handle successful response (status code 200 or 201)
        const result = await response.json();
        setSelectDate(null);
        setSelectImg("");
        toast.success("Successfully updated Private Lottery !");
        navigate('/admin/private-lotteries')
        console.log(result);
        reset();
      }
    } catch (error) {
      setPostLoaderBtn(false)
      // Handle network errors or unexpected errors
      // toast.error("Error creating Lottery");
      toast.error("Error creating Lottery")
      console.warn(error);
    }
  }

  const handleDateChange = (selectedDate) => {
    setSelectDate(selectedDate[0]);
  };
  const dateOptions = {
    mode: "single",  // for single date
    dateFormat: "d M Y",
  };

  if (fetchLoaderBtn) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <RotatingLines
          strokeColor="green"
          strokeWidth="5"
          animationDuration="0.75"
          width="200"
          visible={true}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-[1rem] px-0 sm:px-[1rem] py-[1.5rem] font-[500]">
      <div className="text-primary flex items-center gap-[1rem] px-[1rem]">
        <Icon icon="game-icons:ticket" className="text-[2rem]" />
        <h1 className="text-[1.2rem] sm:text-[1.7rem] font-semibold">
          Edit Private Lottery
        </h1>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}
        className="bg-white flex flex-col gap-[2rem] px-[2rem] py-[1rem] rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[2rem] gap-y-[1rem]">
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="nameOfLottery" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Name of Private Lottery
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="text"
              placeholder="Enter Name"
              {...register("Name", {
                required: "*This field is required.",
              })}
            />
            {errors.Name && (
              <div className="text-sm text-[#E92215]">
                {errors.Name.message}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="uniqueNumber" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Unique Number
              {/* <span className="text-[#E92215]">*</span> */}
            </label>

            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="text"
              placeholder="Enter Unique Number"
              value={lottarynumber}
              disabled={true}
            />
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="Totaltickets" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Total Number Of Tickets
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="number"
              placeholder="Eg: 500"
              {...register("Totaltickets", {
                required: "*This field is required.",
              })}
            />
            {errors.Totaltickets && (
              <div className="text-sm text-[#E92215]">
                {errors.Totaltickets.message}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="ticketPrice" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Price Per Each Ticket
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="number"
              placeholder="Eg: 300"
              {...register("ticketPrice", {
                required: "*This field is required.",
              })}
            />
            {errors.ticketPrice && (
              <div className="text-sm text-[#E92215]">
                {errors.ticketPrice.message}
              </div>
            )}
          </div>
          {/* <div className="flex flex-col gap-[0.5rem]">
                  <label htmlFor="totalWinningAmount" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                     Total Winning Amount
                  </label>
                  <input
                     className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                     type="number"
                     placeholder="Eg: 50,000"
                     readOnly
                     {...register("winneramount",)}
                  />
               </div>
               <div className="flex flex-col gap-[0.5rem]">
                  <label htmlFor="numOfWinners" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                     Number Of Winners
                  </label>
                  <input
                     className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                     type="number"
                     value={privateLotteryData?.winnerSlot}
                     readOnly
                  />
               </div> */}

          {/* {
                  Array.from({ length: privateLotteryData?.winnerSlot }).map((data, index) => (

                     <div className="flex flex-col gap-[0.5rem]">
                        <label htmlFor="winner1" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                           Winner {index + 1}
                        </label>
                        <input
                           className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                           type="text"
                           placeholder="Eg: 30,000"
                           value={privateLotteryData?.winnerSlotObject[index]}
                           readOnly
                        />
                     </div>
                  ))} */}
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="expectedDateOfDraw" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Expected Date Of Draw
            </label>
            <Flatpickr
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              placeholder="Select Expected Date"
              options={dateOptions}
              onChange={(selectedDate) => handleDateChange(selectedDate)}
              value={selectDate}
            />
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="termsAndConditions " className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Terms & Conditions
            </label>
            <textarea
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="text"
              rows={4}
              placeholder="Description goes here"
              {...register("termsAndConditions", {
                required: "*This field is required.",
              })}
            />
            {errors.termsAndConditions && (
              <div className="text-sm text-[#E92215]">
                {errors.termsAndConditions.message}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="profilePicture" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Profile Picture
            </label>
            <div className="flex flex-col items-center py-[2rem] px-[1rem] border-2 border-dashed
                         border-[#D9D9D9] rounded-lg">
              {selectImg?.name ? (
                <>
                  <h2 className="text-[1.1rem] font-semibold text-green-600 my-[1rem]">
                    Uploaded document
                  </h2>
                  <div className="flex gap-[1rem] items-center">
                    <h2 className="text-[1.1rem] text-black font-[500]">
                      {selectImg?.name.length > 30
                        ? selectImg.name.substring(0, 30) + "..."
                        : selectImg.name}
                    </h2>
                    <Icon
                      onClick={() => setSelectImg(null)}
                      icon="akar-icons:cross"
                      className="text-[1.2rem] cursor-pointer hover:bg-[#3D4A5C]/[0.3]"
                    />
                  </div>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() =>
                      document.querySelector(".input-field").click()
                    }
                  >
                    <Icon
                      icon="material-symbols:cloud-upload"
                      className="text-[#D9D9D9] text-[4rem] mb-[0.5rem]"
                    />
                  </button>
                  <input
                    type="file"
                    // accept="image/*"
                    value=""
                    className="input-field"
                    hidden
                    onChange={({ target: { files } }) => {
                      if (files[0]) {
                        setSelectImg(files[0]);
                      }
                    }}
                  />
                  <p className="text-[0.9rem] text-[#D9D9D9]">
                    Upload jpg, png, pdf. Size up to 20MB
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex gap-[2rem] items-center justify-end">
          <button onClick={() => navigate(-1)}
            className="text-[0.9rem] sm:text-[1.2rem] font-semibold text-primary bg-white 
                        border border-primary py-[0.4rem] w-[10rem] sm:w-[12rem] rounded-[8px]"
            type="button"
          >
            Back
          </button>

          {postLoaderBtn ? (
            <ThreeDots
              height="50"
              width="50"
              radius="9"
              color="#9FB947"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) :
            (<button
              className="text-[0.9rem] sm:text-[1.2rem] font-semibold text-white bg-primary py-[0.5rem] px-[1rem] rounded-[8px]"
              type="submit"
            >
              Save Private Lottery
            </button>)}

        </div>
      </form>
      <ToastContainer />
    </div>
  )
}

