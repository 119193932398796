import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Icon } from "@iconify/react";
import "flatpickr/dist/themes/material_green.css";

import {
  IconButton,
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import CustomCheckbox from "../../../helpers/CustomCheckbox";
import { Link } from "react-router-dom";
import { APIurls } from "../../../api/apiConstant";
import { useQuery } from "react-query";
import { useAuth } from "../../../store/AuthContext";
import moment from "moment";

export default function OrderList() {
  const { getAccessToken, userRole } = useAuth();
  const [searchKey, setSearchKey] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        padding: "10px 10px",
        color: "#212529",
      },
    },
    head: {
      style: {
        borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        fontSize: "15px",
      },
    },

    rows: {
      style: {
        background: "#FFF",
        color: "#565656",
        padding: "10px 10px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    pagination: {
      style: {
        // boxShadow: "10px 5px 5px #ddd",
        marginBottom: "5px",
        borderBottom: "1px solid #E9E9E9",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1200px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto",
      },
    },
  };
  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      maxWidth: "0.5rem",
    },
    {
      name: "Customer Name",
      id: "customerName",
      selector: (row) => row.userName,
      center: true,
      grow: 2,
      icon: "solar:sort-outline",
    },
    {
      name: "Date of Purchase",
      id: "description",
      selector: (row) => moment(row.dateOfPurchase).format("DD MMMM YYYY"),
      center: true,
      grow: 1,
      icon: "solar:sort-outline",
    },
    {
      name: "Price",
      id: "createdAt",
      selector: (row) => row.price,
      center: true,
      grow: 1,
      icon: "solar:sort-outline",
    },
    {
      name: "Payment Status",
      id: "image",
      selector: (row) => (
        <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold w-[8rem] 
          py-[0.5rem] ${row.paymentStatus === "SUCCESS" ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"}`}>
          <span className={`${row.paymentStatus === "SUCCESS" ? "bg-[#25BF17]" : "bg-[#FF0023]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
          <h2>{row.paymentStatus}</h2>
        </div>
      ),
      center: true,
      grow: 1,
      icon: "solar:sort-outline",
    },
    {
      name: "Action",
      id: "action",
      cell: (row) => (
        <div className="flex space-x-2">
          <Link to={`edit/:id`}>
            <IconButton
              className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656]"
              style={{
                borderRadius: "5.55px",
                border: "0.925px solid #D9D9D9",
                background: "#FFF",
              }}
            >
              <Icon icon="icon-park-outline:down" className="text-[1.5rem]" />
            </IconButton>
          </Link>
        </div>
      ),
      center: true,
      grow: 1,
    },
  ];

  const fetchCustomerOrders = async () => {
    const token = await getAccessToken()

    const response = await fetch(APIurls.fetchCustomerOrders, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (!response.ok) {
      throw new Error("Failed to Fetch Customer Orders");
    }
    const result = await response.json();

    console.log("Customer Orders...", result?.response?.customerOrders);
    return result?.response?.customerOrders?.reverse();
  };
  const { data: customerOrders, isLoading } = useQuery('customerOrdersData', fetchCustomerOrders);

  useEffect(() => {
    let mapData = customerOrders?.map((item, index) => {
      return { ...item };
    });
    if (searchKey !== "") {
      mapData = mapData.filter((item) => {
        return item?.userName?.toLowerCase().includes(searchKey.toLowerCase());
      });
    }
    // if (startDate && endDate) {
    //   console.log("DATE...........DATE...........DATE");
    //   const dStart = new Date(startDate);
    //   dStart.setUTCHours(0, 0, 0, 0);
    //   const dEnd = new Date(endDate);
    //   dEnd.setUTCHours(23, 59, 59, 999);
    //   mapData = mapData.filter((item) => {
    //     const itemDate = new Date(item.createdAt);
    //     return (
    //       itemDate.getTime() >= dStart.getTime() && itemDate.getTime() <= dEnd.getTime()
    //     );
    //   });
    // }
    // if (status !== "") {
    //   if (status === "ALL") {
    //     setFilteredLotteries(mapData);
    //   } else {
    //     mapData = mapData.filter((item) => item.status === status);
    //   }
    // }
    setFilteredItems(mapData);
  }, [searchKey, customerOrders]);

  return (
    <div className="py-[1rem] w-auto mb-10">
      {/* visible till Medium screen */}
      <div
        className="md:hidden flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[full] my-[1rem]"
        style={{
          border: "0.533975px solid #DFDFDF",
          borderRadius: "6px",
        }}
      >
        <div className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md ">
          <Icon
            icon="iconamoon:search-bold"
            className="text-[#272727] max-sm:text-[0.9rem]"
          />
        </div>
        <input
          type="text"
          className="text-[0.8rem] sm:text-[1rem] flex-1 bg-transparent placeholder-[#848484] outline-none "
          placeholder="Search by Lottery Name"
          value={searchKey}
          onChange={(e) => {
            setSearchKey(e.target.value);
          }}
        />
      </div>

      <div className="flex flex-col max-xs:items-start xs:flex-row gap-[0.5rem] sm:gap-[1rem] justify-center sm:justify-between items-center px-[1rem] py-[0.5rem] rounded-md mb-[1rem]">
        {/* Visible from Medium Screen */}
        <h1 className="text-[16px] sm:text-[1.1rem] font-semibold">
          Customer Orders
        </h1>
        <div className="flex gap-[2rem] items-center">
          <div
            className="max-md:hidden flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[300px] lg:w-[350px]"
            style={{
              border: "0.6px solid #DFDFDF",
              borderRadius: "6px",
            }}
          >
            <input
              type="text"
              className="flex-1 bg-transparent  placeholder-[#848484] outline-none "
              placeholder="Search by Lottery Name"
              value={searchKey}
              onChange={(e) => {
                setSearchKey(e.target.value);
              }}
            />
            {
              !searchKey ? (
                <button type="button"
                  className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:search" className="text-white" />
                </button>
              ) : (
                <button onClick={() => setSearchKey("")}
                  className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:cross" className="text-white" />
                </button>
              )
            }
          </div>

          {/* Buttons with icons  hidden from lg*/}
          <div className="flex items-center gap-4">
            <button className="flex items-center gap-2  p-[4px] rounded-[4px] border-[#858585] border-[1px] text-[#858585] w-[80px] h-[29px]"><Icon icon="mingcute:filter-line" />Filter</button>
            <button className="flex items-center gap-2  p-[4px] rounded-[4px] border-[#858585] border-[1px] text-[#858585] w-[80px] h-[29px]"><Icon icon="solar:calendar-linear" />Date</button>
            <button className="flex items-center gap-2  p-[4px] rounded-[4px] border-[#858585] border-[1px] text-[#858585] w-[80px] h-[29px]"><Icon icon="mynaui:send" />Share</button>
          </div>

          <div className="flex flex-row gap-[0.5rem] xs:gap-[1rem] items-center justify-center py-[0.2rem]">
            <div className="">
              <Menu>
                <MenuHandler>
                  <Button className="text-[0.8rem] sm:text-[1rem] flex w-full flex-row justify-center whitespace-nowrap capitalize text-white bg-primary px-[0.4rem] xs:px-[1rem] py-[0.5rem] rounded-md items-center gap-[0.5rem] sm:gap-[1rem] hover:shadow-white shadow-white outline-none">
                    <span className="font-[500]">View full details</span>
                  </Button>
                </MenuHandler>
                <MenuList>
                  <MenuItem
                    //  onClick={downloadPdf}
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                  >
                    {" "}
                    <Icon
                      icon="bxs:file-pdf"
                      className="text-[#BE0A23] w-[1.6rem] h-auto"
                    />
                    Download as PDF
                  </MenuItem>
                  <hr className="my-[0.2rem] sm:my-[0.5rem]" />
                  <MenuItem
                    //  onClick={downloadCSV}
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.9rem]"
                  >
                    <Icon
                      icon="fa-solid:file-csv"
                      className="text-[#29672E] w-[1.1rem] h-auto"
                    />
                    Download as CSV
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <DataTable
          columns={columns.map((column) => ({
            ...column,
            name: (
              <div className="flex items-center justify-between">
                <span>{column.name}</span>
                {column.icon && <Icon icon={column.icon} className="text-gray-600 ml-1" />}
              </div>
            ),
          }))}
          data={filteredItems}
          customStyles={customStyles}
          pagination
          selectableRows
          progressPending={isLoading}
          selectableRowsComponent={CustomCheckbox}
        />
      </div>
    </div>
  );
}
