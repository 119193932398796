import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import { Icon } from "@iconify/react";
import "flatpickr/dist/themes/material_green.css";
import {
  IconButton,
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import CustomCheckbox from "../../../helpers/CustomCheckbox";
import moment from "moment";
import { Link } from "react-router-dom";
import { useAuth } from "../../../store/AuthContext";
import { APIurls } from "../../../api/apiConstant";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DownloadPDF from "../../../exportData/Export PDF";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";

export default function NotificationsList({ updateNotificationsList, setUpdateNotificationsList }) {
  const [searchKey, setSearchKey] = useState("");
  const [loadingStates, setLoadingStates] = useState({});
  const [filteredItems, setFilteredItems] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const flatpickrRef = useRef();
  const { getAccessToken, userRole } = useAuth();

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        padding: "10px 10px",
        color: "#212529",
      },
    },
    head: {
      style: {
        borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        fontSize: "15px",
      },
    },

    rows: {
      style: {
        background: "#FFF",
        color: "#565656",
        padding: "10px 10px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    pagination: {
      style: {
        boxShadow: "10px 5px 5px #ddd",
        marginBottom: "5px",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1500px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };
  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      maxWidth: "0.5rem",
    },
    {
      name: "Title",
      id: "title",
      selector: (row) => row.title,
      center: true,
      grow: 2,
    },
    {
      name: "Description",
      id: "description",
      selector: (row) => (
        <h2 className="whitespace-break-spaces text-center">
          {row.description}
        </h2>
      ),
      center: true,
      grow: 4,
    },
    {
      name: "Created At",
      id: "createdAt",
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY, hh:mm A"),
      center: true,
      grow: 3,
    },
    {
      name: "Send To",
      id: "sendTo",
      selector: (row) => (
        <div className="flex flex-col">
          <span>{row?.sendTo.agents && "Agents"}</span>
          <span>{row?.sendTo.customers && "Customers"}</span>
          <span>{row?.sendTo.lotteryAgents && "Lottery Agents"}</span>
        </div>
      ),
      center: true,
      grow: 2,
    },
    {
      name: "Image",
      id: "image",
      selector: (row) => row.notificationImg ?
        (
          <img src={row.notificationImg} alt="Notification"
            className="h-[55px] w-[110px] bg-center bg-cover rounded-md" />
        ) : "NA",
      center: true,
      grow: 2,
    },
    {
      name: "Actions",
      id: "action",
      cell: (row) => (
        <div className="flex gap-2">
          {/* <Link to={`edit/:id`}>
            <IconButton
              className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656]"
              style={{
                borderRadius: "5.55px",
                border: "0.925px solid #D9D9D9",
                background: "#FFF",
              }}
            >
              <Icon
                icon="material-symbols:edit-rounded"
                className="text-[1.5rem]"
              />
            </IconButton>
          </Link> */}
          {(userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("DELETE_NOTIFICATIONS")) && (
            loadingStates[row._id] ? (
              <ThreeDots
                height="50"
                width="50"
                radius="9"
                color="#9FB947"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <IconButton onClick={() => deleteNotificationHandler(row._id)}
                className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656]"
                style={{
                  borderRadius: "5.55px",
                  border: "0.925px solid #D9D9D9",
                  background: "#FFF",
                }}
              >
                <Icon
                  icon="material-symbols:delete-rounded"
                  className="text-[1.5rem]"
                />
              </IconButton>
            )
          )}
          {
            (userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("SHARE_NOTIFICATIONS")) && (
              <Link to={"edit/:id"}>
                <IconButton
                  className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656]"
                  style={{
                    borderRadius: "5.55px",
                    border: "0.925px solid #D9D9D9",
                    background: "#FFF",
                  }}
                >
                  <Icon
                    icon="fluent:send-24-filled"
                    className="text-[1.5rem]"
                  />
                </IconButton>
              </Link>
            )
          }
        </div>
      ),
      center: true,
      grow: 2,
    },
  ]

  const deleteNotificationHandler = async (id) => {
    // eslint-disable-next-line no-restricted-globals
    let isConfirm = confirm("Are you sure you want to Delete Notification?");
    if (isConfirm) {
      const token = await getAccessToken()
      try {
        setLoadingStates((prevState) => ({
          ...prevState,
          [id]: true,
        }));
        const response = await fetch(`${APIurls.deleteNotification}/${id}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          }
        })
        if (!response.ok) {
          throw new Error("Failed to Delete Notification");
        }
        const result = await response.json();
        console.log(result);
        toast.success("Notification Deleted Successfully");
        refetch();  // to trigger lottery data for updated data
      } catch (error) {
        console.warn("Failed to Delete Notification");
        toast.error("Failed to Delete Notification");
      }
      setLoadingStates((prevState) => ({
        ...prevState,
        [id]: false,
      }));
    }
  }

  const fetchNotificationsData = async () => {
    const token = await getAccessToken();

    const response = await fetch(APIurls.fetchNotifications, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (!response.ok) {
      throw new Error("Failed to Fetch Notifications Data");
    }
    const result = await response.json();

    console.log("Notifications........", result?.response?.Notifications);
    return result?.response?.Notifications.reverse();
  };
  const { data: notificationsData, isLoading, refetch } = useQuery('notifications', fetchNotificationsData);

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 1) {
      setStartDate(selectedDates[0]);
    }
    if (selectedDates.length === 2) {
      setEndDate(selectedDates[1]);
    }
  };
  const dateOptions = {
    mode: "range",
    dateFormat: "d-m-Y",
  };
  const clearDatesHandler = () => {
    setStartDate(null);
    setEndDate(null);
    flatpickrRef.current.flatpickr.clear();
  }

  useEffect(() => {
    let mapData = notificationsData?.map((item, index) => {
      return { ...item };
    });
    if (searchKey !== "") {
      mapData = mapData.filter((item) => {
        return item?.title?.toLowerCase().includes(searchKey.toLowerCase());
      });
    }
    if (startDate && endDate) {
      const dStart = new Date(startDate);
      dStart.setUTCHours(0, 0, 0, 0);
      const dEnd = new Date(endDate);
      dEnd.setUTCHours(23, 59, 59, 999);
      mapData = mapData.filter((item) => {
        const itemDate = new Date(item.createdAt);
        return (
          itemDate.getTime() >= dStart.getTime() && itemDate.getTime() <= dEnd.getTime()
        );
      });
    }
    setFilteredItems(mapData);
  }, [searchKey, startDate, endDate, notificationsData]);

  if (updateNotificationsList) {
    refetch();
    setUpdateNotificationsList(false);
  }

  return (
    <div className="px-[1rem] py-[1rem] w-auto">
      {/* visible till Medium screen */}
      <div
        className="md:hidden flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[full] my-[1rem]"
        style={{
          border: "0.6px solid #DFDFDF",
          borderRadius: "6px",
        }}
      >
        <div className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md ">
          <Icon
            icon="iconamoon:search-bold"
            className="text-[#272727] max-sm:text-[0.9rem]"
          />
        </div>
        <input
          type="text"
          className="text-[0.8rem] sm:text-[1rem] flex-1 bg-transparent placeholder-[#848484] outline-none "
          placeholder="Search here..."
          value={searchKey}
          onChange={(e) => {
            setSearchKey(e.target.value);
          }}
        />
      </div>
      <div className="flex flex-col max-xs:items-start xs:flex-row gap-[0.5rem] sm:gap-[1rem] justify-center sm:justify-between items-center px-[1rem] py-[0.5rem] border border-[#D9D9D9] rounded-md mb-[1rem]">
        {/* Visible from Medium Screen */}
        <h1 className="text-[1rem] sm:text-[1.1rem] font-semibold">
          Manage Notifications
        </h1>
        <div className="flex gap-[2rem] items-center">
          <div
            className="max-md:hidden flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[300px] lg:w-[350px]"
            style={{
              border: "0.6px solid #DFDFDF",
              borderRadius: "6px",
            }}
          >
            <input
              type="text"
              className="flex-1 bg-transparent  placeholder-[#848484] outline-none "
              placeholder="Search by Title"
              value={searchKey}
              onChange={(e) => {
                setSearchKey(e.target.value);
              }}
            />
            {
              !searchKey ? (
                <button type="button"
                  className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:search" className="text-white" />
                </button>
              ) : (
                <button onClick={() => setSearchKey("")}
                  className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:cross" className="text-white" />
                </button>
              )
            }
          </div>
          <div className="max-md:w-full flex items-center gap-[0.5rem] py-[0.5rem] px-[1rem]"
            style={{
              border: "0.6px solid #DFDFDF",
              borderRadius: "6px",
            }}
          >
            <Flatpickr
              ref={flatpickrRef}
              className="text-[0.8rem] sm:text-[1rem] bg-transparent placeholder-[#848484] outline-none"
              placeholder="Select Date"
              options={dateOptions}
              onChange={(selectedDate) => handleDateChange(selectedDate)}
            />
            {
              !startDate || !endDate ? (
                <button type="button"
                  className="flex items-center cursor-pointer bg-primary py-[0.25rem] px-[0.2rem] rounded-md">
                  <Icon icon="solar:calendar-outline" className="text-white" />
                </button>
              ) : (
                <button onClick={clearDatesHandler}
                  className="flex items-center cursor-pointer bg-primary py-[0.25rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:cross" className="text-white" />
                </button>
              )
            }
          </div>
          <div className="flex flex-row gap-[0.5rem] xs:gap-[1rem] items-center justify-center py-[0.2rem]">
            <div className="">
              <Menu>
                <MenuHandler>
                  <Button className="text-[0.8rem] sm:text-[1rem] flex w-full flex-row justify-center whitespace-nowrap capitalize text-white bg-primary px-[0.4rem] xs:px-[1rem] py-[0.5rem] rounded-md items-center gap-[0.5rem] sm:gap-[1rem] hover:shadow-white shadow-white outline-none"
                  // onClick={()=>{alert("Export Button Clicked")}}
                  >
                    <Icon
                      icon="material-symbols:download"
                      className="text-[1rem] w-[1.2rem] sm:w-[1.5rem] h-auto"
                    />
                    <span className="font-[500]">Export</span>
                  </Button>
                </MenuHandler>
                <MenuList>
                  <PDFDownloadLink document={<DownloadPDF data={filteredItems} notificationData={true} />}>
                    <MenuItem
                      className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                    >
                      {" "}
                      <Icon
                        icon="bxs:file-pdf"
                        className="text-[#BE0A23] w-[1.6rem] h-auto"
                      />
                      Download as PDF
                    </MenuItem>
                  </PDFDownloadLink>
                  <hr className="my-[0.2rem] sm:my-[0.5rem]" />
                  <MenuItem
                    //  onClick={downloadCSV}
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.9rem]"
                  >
                    <Icon
                      icon="fa-solid:file-csv"
                      className="text-[#29672E] w-[1.1rem] h-auto"
                    />
                    Download as CSV
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <DataTable
          columns={columns}
          data={filteredItems}
          customStyles={customStyles}
          pagination
          progressPending={isLoading}
          selectableRows
          selectableRowsComponent={CustomCheckbox}
        />
      </div>
    </div>
  );
}
