import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Icon } from "@iconify/react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
// import 'flatpickr/dist/flatpickr.min.css';
import {
  IconButton,
  Option,
  Select,
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import CustomCheckbox from "../../../helpers/CustomCheckbox";
import moment from "moment";
import { Link } from "react-router-dom";
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";
import { useQuery } from "react-query";
import { ToastContainer, toast } from "react-toastify";
import { RotatingLines, ThreeDots } from "react-loader-spinner";

export default function AgentsRequestList() {
  const [status, setStatus] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredItems, setFilteredItems] = useState();
  const [loadingState, setLoadingState] = useState({});

  const { getAccessToken, userRole } = useAuth();

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        padding: "10px 10px",
        color: "#212529",
      },
    },
    head: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        fontSize: "15px",
      },
    },

    rows: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        color: "#565656",
        padding: "10px 10px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    pagination: {
      style: {
        boxShadow: "10px 5px 5px #ddd",
        marginBottom: "5px",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1400px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };
  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      maxWidth: "0.5rem",
    },
    {
      name: "Full Name",
      id: "fullName",
      selector: (row) => row.FirstName + " " + row.LastName,
      center: true,
      grow: 2,
    },
    {
      name: "Date of Birth",
      id: "dob",
      selector: (row) => row.dob,
      center: true,
      grow: 2,
    },
    {
      name: "Mobile Number",
      id: "mobileNumber",
      selector: (row) => row.mobileNumber,
      center: true,
      grow: 2,
    },
    {
      name: "Email Address",
      id: "emailAddress",
      selector: (row) => row.email,
      center: true,
      grow: 3,
    },
    {
      name: "Request Status",
      id: "accountStatus",
      selector: (row) => (
        <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold px-[1rem]
           py-[0.6rem] ${row.status === "REJECTED" ? "text-[#FF0023] bg-[#FDCAD1]" : row.status === "APPROVED" ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FFCE06] bg-[#FDF9EF]"}`}>
          <span className={`${row.status === "REJECTED" ? "bg-[#FF0023]" : row.status === "APPROVED" ? "bg-[#25BF17]" : "bg-[#FFCE06]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
          <h2>{row.status}</h2>
        </div>
      ),
      center: true,
      grow: 2,
    },
    {
      name: "Actions",
      id: "action",
      cell: (row) => (
        <div className="flex gap-[0.5rem]">
          {(userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("APPROVE_LOTTERY_AGENT_REQUEST")) && (
            (loadingState[row._id] && loadingState["approved"]) ? (
              <ThreeDots
                height="50"
                width="50"
                radius="9"
                color="#25BF17"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <IconButton onClick={() => approveAgentRequestHandler(row._id, "approved")}
                className="shadow-none hover:shadow-none focus:shadow-none text-[#25BF17] bg-[#E8F7E6]"
                style={{
                  borderRadius: "6px",
                  border: "1px solid #25BF17",
                }}
              >
                <Icon icon="mdi:tick" className="text-[1.5rem]" />
              </IconButton>
            )
          )}
          {(userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("REJECT_LOTTERY_AGENT_REQUEST")) && (
            (loadingState[row._id] && loadingState["rejected"]) ? (
              <ThreeDots
                height="50"
                width="50"
                radius="9"
                color="#25BF17"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <IconButton onClick={() => rejectAgentRequestHandler(row._id, "rejected")}
                className="shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8]"
                style={{
                  borderRadius: "6px",
                  border: "1px solid #FF0023",
                }}
              >
                <Icon
                  icon="material-symbols:close"
                  className="text-[1.5rem]"
                />
              </IconButton>
            )
          )}
        </div>
      ),
      center: true,
      grow: 2,
    },
  ];

  const approveAgentRequestHandler = async (id, actionType) => {
    const token = await getAccessToken()
    try {
      setLoadingState((prevState) => {
        return {
          ...prevState,
          [id]: true,
          [actionType]: true,
        }
      })
      const response = await fetch(`${APIurls.approveAgentRequest}/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      if (!response.ok) {
        throw new Error("Failed to Approve Agent Request");
      }
      const result = await response.json();
      console.log(result);
      toast.success("Agent Request Approved Successfully");
      refetch();  // to trigger Agent Requests for updated data
    } catch (error) {
      console.warn("Failed to Approve Agent Request");
      toast.error("Failed to Approve Agent Request");
    }
    setLoadingState((prevState) => {
      return {
        ...prevState,
        [id]: false,
        [actionType]: false,
      }
    })
  }

  const rejectAgentRequestHandler = async (id, actionType) => {
    const token = await getAccessToken()
    try {
      setLoadingState((prevState) => {
        return {
          ...prevState,
          [id]: true,
          [actionType]: true,
        }
      })
      const response = await fetch(`${APIurls.rejectAgentRequest}/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      if (!response.ok) {
        throw new Error("Failed to Reject Agent Request");
      }
      const result = await response.json();
      console.log(result);
      toast.success("Agent Request Rejected Successfully");
      refetch();  // to trigger Agent Requests for updated data
    } catch (error) {
      console.warn("Failed to Reject Agent Request");
      toast.error("Failed to Reject Agent Request");
    }
    setLoadingState((prevState) => {
      return {
        ...prevState,
        [id]: false,
        [actionType]: false,
      }
    })
  }

  const fetchAgentRequestData = async () => {
    const token = await getAccessToken()
    const response = await fetch(APIurls.fetchAgentRequests, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (!response.ok) {
      throw new Error("Failed to Fetch Lottery Agents Request Data");
    }
    const result = await response.json();
    console.log("LOTTERY AGENT REQUESTS LIST...", result?.response?.AgentRequests);
    return result?.response?.AgentRequests?.reverse();
  };

  const { data: agentRequestData, isError, error, isLoading, refetch } = useQuery('agentRequests', fetchAgentRequestData);

  useEffect(() => {
    let mapData = agentRequestData?.map((item, index) => {
      return { ...item };
    });
    if (searchKey !== "") {
      mapData = mapData.filter((item) => {
        const agentName = item.FirstName + " " + item.LastName;
        return agentName?.toLowerCase().includes(searchKey.toLowerCase());
      });
    }
    setFilteredItems(mapData);
  }, [searchKey, agentRequestData]);

  const handleDateChange = (selectedDates) => {
    console.log(selectedDates.length);

    if (selectedDates.length === 1) {
      const formatedStartDate = moment(selectedDates[0]).format("YYYY-MM-DD");
      setStartDate(formatedStartDate);
    }
    if (selectedDates.length === 2) {
      const formatedEndDate = moment(selectedDates[1]).format("YYYY-MM-DD");
      setEndDate(formatedEndDate);
    }
  };

  const dateOptions = {
    mode: "range",
    dateFormat: "Y-m-d",
  };

  if (isError) {
    return (
      <div>
        {toast.error(error.message)}
        <h1 className='text-[2rem] font-bold flex items-center justify-center h-screen'>
          Failed to Fetch Lottery Agents Requests List...!
        </h1>
      </div>
    )
  }
  if (isLoading) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <RotatingLines
          strokeColor="green"
          strokeWidth="5"
          animationDuration="0.75"
          width="100"
          visible={true}
        />
      </div>
    )
  }

  return (
    <div className="py-[1rem]">
      <div className="flex flex-col md:flex-row md:items-center justify-between my-[0.5rem] px-[1rem]">
        <h1 className="text-[1.2rem] sm:text-[1.4rem] font-semibold whitespace-nowrap">
          Lottery Agent Requests
        </h1>
        <div className="">
          {
            (userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("CREATE_LOTTERY_AGENT")) && (
              <Link to={"create"}>
                <button className="text-[0.8rem] sm:text-[1rem] flex items-center justify-center max-xs:w-full  gap-[0.3rem] xs:gap-[0.5rem] bg-primary text-white py-[0.5rem] px-[0.4rem] xs:px-[1rem] rounded-md">
                  <Icon
                    icon="material-symbols:add"
                    className="text-[1.2rem] sm:text-[1.5rem]"
                  />
                  <span className="whitespace-nowrap">Create Lottery Agent</span>
                </button>
              </Link>
            )
          }
        </div>
      </div>
      {/* visible till Medium screen */}
      <div
        className="md:hidden flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[full] my-[1rem]"
        style={{
          border: "0.533975px solid #DFDFDF",
          borderRadius: "6px",
        }}
      >
        <div className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md ">
          <Icon
            icon="iconamoon:search-bold"
            className="text-[#272727] max-sm:text-[0.9rem]"
          />
        </div>
        <input
          type="text"
          className="text-[0.8rem] sm:text-[1rem] flex-1 bg-transparent placeholder-[#848484] outline-none "
          placeholder="Search here..."
          value={searchKey}
          onChange={(e) => {
            setSearchKey(e.target.value);
          }}
        />
      </div>
      <div className="grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-6 my-7 px-4">
        {/* badge -1: Total Agents  */}
        <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <div>
            <header className='flex justify-between'>
              <h3 className='text-[14px] text-[#858585]'>Total Requests</h3>
              <Icon className='text-[#25BF17] text-[1.9rem]' icon="heroicons-solid:user-group" />
            </header>
            <h3 className='text-[#25BF17] text-[26px] font-bold'>
              {agentRequestData?.length !== "undefined" ? agentRequestData?.length : "NA"}
            </h3>
          </div>
          <div>
            <div className='mb-3'><hr /></div>
            <footer className='flex justify-between items-center gap-1'>
              <div className='flex items-center gap-1'>
                <Icon className='text-[#198754] w-3 h-3' icon="mdi:arrow-up-thin" />
                <small className='text-[#198754] text-[10px]'>3.05%</small>
                <small className='text-[9px] text-[#8898AA]'>Since last month</small>
              </div>
            </footer>
          </div>
        </div>
        {/* badge -2: Active Agents   */}
        {/* <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <div>
            <header className='flex justify-between'>
              <h3 className='text-[14px] text-[#858585]'>Active</h3>
              <Icon icon="ooui:user-active" className="text-primary text-[1.8rem]" />
            </header>
            <h3 className='text-[#25BF17] text-[26px] font-bold'>
              1
            </h3>
          </div>
          <div>
            <div className='mb-3'><hr /></div>
            <footer className='flex justify-between items-center gap-1'>
              <div className='flex items-center gap-1'>
                <Icon className='text-[#198754] w-3 h-3' icon="mdi:arrow-up-thin" />
                <small className='text-[#198754] text-[10px]'>3.05%</small>
                <small className='text-[9px] text-[#8898AA]'>Since last month</small>
              </div>
            </footer>
          </div>
        </div> */}
        {/* badge -3: Inactive Agents  */}
        {/* <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <div>
            <header className='flex justify-between'>
              <h3 className='text-[14px] text-[#858585]'>Inactive</h3>
              <Icon className='text-[#25BF17] text-[1.6rem]' icon="grommet-icons:in-progress" />
            </header>
            <h3 className='text-[#25BF17] text-[26px] font-bold mb-5'>
              0
            </h3>
          </div>
          <div>
            <div className='mb-3'><hr /></div>
            <footer className='flex justify-between items-center gap-1'>
              <div className='flex items-center gap-1'>
                <Icon className='text-[#198754] w-3 h-3' icon="mdi:arrow-up-thin" />
                <small className='text-[#198754] text-[10px]'>3.05%</small>
                <small className='text-[9px] text-[#8898AA]'>Since last month</small>
              </div>
            </footer>
          </div>
        </div> */}
        <div></div>

      </div>
      <div className="flex flex-col max-xs:items-start xs:flex-row gap-[0.5rem] sm:gap-[1rem] justify-between items-center my-[1rem] xl:mx-[1rem] ">
        {/* Visible from Medium Screen */}
        <h1 className="text-[1.2rem] font-semibold">
          List
        </h1>
        <div className="flex gap-[1rem] items-center px-[1rem]">
          <div
            className="max-md:hidden flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[300px] lg:w-[350px]"
            style={{
              border: "0.533975px solid #DFDFDF",
              borderRadius: "6px",
            }}
          >
            <input
              type="text"
              className="flex-1 bg-transparent  placeholder-[#848484] outline-none "
              placeholder="Search here..."
              value={searchKey}
              onChange={(e) => {
                setSearchKey(e.target.value);
              }}
            />
            <button onClick={() => setSearchKey("")}
              className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
              <Icon icon="akar-icons:cross" className="text-white" />
            </button>
          </div>
          <div className="max-md:w-full">
            <Flatpickr
              className="text-[0.8rem] sm:text-[1rem] bg-transparent placeholder-[#848484] outline-none py-[0.5rem] px-[1rem]"
              style={{
                border: "0.533975px solid #DFDFDF",
                borderRadius: "6px",
              }}
              placeholder="Select Date"
              options={dateOptions}
              onChange={(selectedDate) => handleDateChange(selectedDate)}
            />
          </div>
          <div className="">
            <Select label="Select Country"
              className="text-[0.8rem] sm:text-[1rem] bg-transparent"
            >
              <Option value="India">India</Option>
              <Option value="UAE">UAE</Option>
              <Option value="England">England</Option>
            </Select>
          </div>
          <div className="flex flex-row gap-[0.5rem] xs:gap-[1rem] items-center justify-center py-[0.2rem]">
            <div className="">
              <Menu>
                <MenuHandler>
                  <Button className="text-[0.8rem] sm:text-[1rem] flex w-full flex-row justify-center whitespace-nowrap capitalize text-white bg-primary px-[0.4rem] xs:px-[1rem] py-[0.5rem] rounded-md items-center gap-[0.5rem] sm:gap-[1rem] hover:shadow-white shadow-white outline-none">
                    <Icon
                      icon="material-symbols:download"
                      className="text-[1rem] w-[1.2rem] sm:w-[1.5rem] h-auto"
                    />
                    <span className="font-[500]">Export</span>
                  </Button>
                </MenuHandler>
                <MenuList>
                  <MenuItem
                    //  onClick={downloadPdf}
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                  >
                    {" "}
                    <Icon
                      icon="bxs:file-pdf"
                      className="text-[#BE0A23] w-[1.6rem] h-auto"
                    />
                    Download as PDF
                  </MenuItem>
                  <hr className="my-[0.2rem] sm:my-[0.5rem]" />
                  <MenuItem
                    //  onClick={downloadCSV}
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.9rem]"
                  >
                    <Icon
                      icon="fa-solid:file-csv"
                      className="text-[#29672E] w-[1.1rem] h-auto"
                    />
                    Download as CSV
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white px-[1rem]">
        <DataTable
          columns={columns}
          data={filteredItems}
          customStyles={customStyles}
          pagination
          progressPending={isLoading}
          selectableRows
          selectableRowsComponent={CustomCheckbox}
        />
      </div>
      <ToastContainer />
    </div>
  );
}

