import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react';
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom';
import userImg from '../../assets/user-img.png'
import { useQuery } from 'react-query';
import { APIurls } from "../../api/apiConstant";
import { useAuth } from "../../store/AuthContext";
import moment from 'moment';
import { animateScroll as scroll } from "react-scroll";
import { RotatingLines, ThreeDots } from 'react-loader-spinner';
import Userprofile from "../../assets/profile.png"
import noImage1 from "../../assets/no-image1.jpg"
import { formatToIndianNumber } from '../../helpers/numberConversion';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

export default function UserView() {
  const { id } = useParams();
  const [viewRefetch, setViewRefetch] = useState();
  const navigate = useNavigate();
  const { getAccessToken, userRole } = useAuth();
  const [loaderBtn, setLoaderBtn] = useState(false);
  const [winningsData, setWinningsData] = useState([]);
  const totalTransactionsDoneByUser = useSelector((store) => store.userData.totalTransactionsDoneByUser)

  // useEffect(() => {
  //   transactionsRefetch()
  // }, [])

  const fetchUserDataById = async () => {
    const token = await getAccessToken()
    const response = await fetch(`${APIurls.fetchUserById}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (!response.ok) {
      throw new Error("Failed to Fetch User Data");
    }
    const result = await response.json();
    console.log("User Data from API", result?.response?.UserData);
    return result?.response?.UserData;
  };
  const { data: userData, isError, error, isLoading, refetch } = useQuery(`userDatas${id}`, fetchUserDataById);

  // const fetchUserTransactionsById = async () => {
  //   const token = await getAccessToken()
  //   const response = await fetch(`${APIurls.fetchUserTransactions}/${mongodbId}`, {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     }
  //   });
  //   if (!response.ok) {
  //     throw new Error("Failed to Fetch User Data");
  //   }
  //   const result = await response.json();
  //   console.log("User Transactions Data", result?.response);
  //   return result?.response?.WalletHistory;
  // };

  // const { data: transactions, isLoading: transactionsLoading, refetch: transactionsRefetch } = useQuery('userTransactions', fetchUserTransactionsById);

  const fetchWinningsDataHandler = async (mongoId) => {
    const token = await getAccessToken()
    try {
      const response = await fetch(`${APIurls.fetchUserWinnnings}/${mongoId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      if (!response.ok) {
        throw new Error("Failed to fetch user winnings data");
      }
      const result = await response.json();
      console.log("WINNINGS DATA", result);
      let data = result?.response?.fetchWinnerss.reduce((total, curr) => total + curr.winningAmount, 0)
      setWinningsData(data);
      console.log("REQUIRED DATA", data);
    } catch (error) {
      console.warn("Failed to fetch user winnings data");
      toast.error("Failed to fetch user winnings data");
    }
  }
  useEffect(() => {
    if (userData?._id) {
      fetchWinningsDataHandler(userData._id);
    }
  }, [userData?._id])

  const disableUserHandler = async () => {
    setLoaderBtn(true);
    const token = await getAccessToken()
    try {
      const response = await fetch(`${APIurls.disableUser}/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      if (!response.ok) {
        throw new Error("Failed to Block User");
      }
      const result = await response.json();
      console.log(result);
      toast.success("User Blocked Successfully");
      refetch();
    } catch (error) {
      console.warn("Failed to Block User");
      toast.error("Failed to Block User");
    }
    setLoaderBtn(false);
  }

  if (viewRefetch) {
    refetch();
    setViewRefetch(false);
  }

  if (isError) {
    return (
      <div>
        {toast.error(error.message)}
        <h1 className='text-[2rem] font-bold flex items-center justify-center h-screen'>
          Failed to Fetch User Data...!
        </h1>
      </div>
    )
  }
  if (isLoading) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <RotatingLines
          strokeColor="green"
          strokeWidth="5"
          animationDuration="0.75"
          width="100"
          visible={true}
        />
      </div>
    );
  }

  return (
    <div className='flex flex-col gap-[2rem] my-[1rem] px-[1rem]'>

      <div className='flex justify-between'>
        <button
          onClick={() => navigate("/admin/users")}
          className="flex items-center gap-[0.5rem] text-[0.8rem] sm:text-[1rem] font-[500] text-white bg-primary py-[0.4rem] px-[1rem] rounded-md"
        >
          <Icon icon="material-symbols:arrow-back-ios-new-rounded" />
          <span>Back</span>
        </button>
        {(userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("UPDATE_USER_STATUS")) && (
          loaderBtn ? (
            <ThreeDots
              height="50"
              width="50"
              radius="9"
              color="#FF0023"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) : (
            userData?.status === "ACTIVE" && (
              <button
                onClick={disableUserHandler}
                className="text-[0.8rem] sm:text-[1rem] font-[500] text-white bg-[#FF0023] py-[0.4rem] px-[1rem] rounded-md"
              >
                Block User
              </button>
            )
          )
        )}
      </div>
      <div className='h-[155px] flex gap-[1rem]'>
        <div className='flex items-center justify-center'>
          <img src={userData?.imageUrl || noImage1} alt="userImg" className='rounded-xl max-h-[155px] w-[170px] bg-cover bg-center' />
        </div>
        <div className='flex flex-col justify-between gap-[1rem] p-[1rem] rounded-xl'
          style={{
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          <div className='flex gap-[2rem] items-center'>
            <div className='bg-[#D1F0CF] p-[0.1rem] rounded-md'>
              <Icon icon="mdi:user" className='text-primary text-[2rem]' />
            </div>
            <div>
              <h1 className='font-bold whitespace-nowrap'>{(userData?.FirstName + " " + userData?.LastName)}</h1>
              <p className='text-[#8B8D97] text-[0.8rem] whitespace-nowrap'>Created on <span className='font-bold'>{moment(userData?.createdAt).format("D MMMM YYYY")}</span></p>
            </div>
            <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold py-[0.4rem] px-[1rem] ${userData?.status === "ACTIVE" ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"}`}>
              <span className={`${userData?.status === "ACTIVE" ? "bg-[#25BF17]" : "bg-[#FF0023]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
              <h2>{userData?.status}</h2>
            </div>
          </div>
          <div className='flex items-center justify-between'>
            <div>
              <label htmlFor="emailId" className='text-[#8B8D97] text-[0.9rem]'>Email Id</label>
              <h2 className='font-semibold'>{userData?.email}</h2>
            </div>
            <div>
              <label htmlFor="mobileNumber" className='text-[#8B8D97] text-[0.9rem] whitespace-nowrap'>Mobile Number</label>
              <h2 className='font-semibold'>{userData?.mobileNumber}</h2>
            </div>
          </div>
        </div>
        <div className='flex flex-wrap justify-between gap-[1.5rem] p-[1rem] rounded-xl'
          style={{
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          <div>
            <label htmlFor="emailId" className='text-[#8B8D97] text-[0.9rem]'>Nationality</label>
            <div className='flex gap-[0.5rem] items-center'>
              {/* <Icon icon="twemoji:flag-india" className='text-[2rem]' /> */}
              <h2 className='font-semibold'>{userData?.location?.country}</h2>
            </div>
          </div>
          <div>
            <label htmlFor="mobileNumber" className='text-[#8B8D97] text-[0.9rem]'>Date of Birth</label>
            <h2 className='font-semibold'>{userData?.dob}</h2>
          </div>
          <div>
            <label htmlFor="emailId" className='text-[#8B8D97] text-[0.9rem]'>Gender</label>
            <h2 className='font-semibold'>{userData?.Gender}</h2>
          </div><br></br>
          <div>
            <label htmlFor="mobileNumber" className='text-[#8B8D97] text-[0.9rem]'>Address</label>
            <h2 className='font-semibold'>{userData?.location?.address} , {userData?.location?.state} , {userData?.location?.city} - {userData?.location?.pincode}</h2>
          </div>
        </div>
      </div>
      <div className='flex gap-7 items-center justify-between'>
        <div className='p-3 rounded-[10px] w-full'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <div className='flex gap-4 justify-between items-center'>
            <label htmlFor="transactions" className='text-[#858585]'>
              Total Number of Transactions
            </label>
            <Icon icon="ant-design:transaction-outlined" className='text-primary text-[1.8rem]' />
          </div>
          <h1 className='text-primary text-[2rem] font-bold'>
            {userData?.Transaction.length !== "undefined" ? userData?.Transaction.length : "NA"}
          </h1>
        </div>
        <div className='p-3 rounded-[10px] w-full'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <div className='flex gap-4 justify-between items-center'>
            <label htmlFor="transactions" className='text-[#858585]'>
              Total Amount Earned
            </label>
            <Icon icon="tdesign:money" className='text-primary text-[1.8rem]' />
          </div>
          <h1 className='text-primary text-[2rem] font-bold'>
            {winningsData !== "undefined" ? formatToIndianNumber(winningsData) : "NA"}
          </h1>
        </div>
        <div className='p-3 rounded-[10px] w-full'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <div className='flex gap-4 justify-between items-center'>
            <label htmlFor="transactions" className='text-[#858585]'>
              Total Amount In Wallet
            </label>
            <Icon icon="fluent:wallet-credit-card-32-regular" className='text-primary text-[1.8rem]' />
          </div>
          <h1 className='text-primary text-[2rem] font-bold'>
            {formatToIndianNumber(userData.wallet)}
          </h1>
        </div>
        <div className='w-[50%]'></div>
      </div>
      <div>
        <div className='flex gap-[2.5rem] text-[1.2rem] border-b-2 border-[#989898]'>
          <NavLink to={`/admin/users/view/${id}`} end>
            {({ isActive }) => (
              <h2 className={` ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                Transactions
              </h2>
            )}
          </NavLink>
          <NavLink to={`/admin/users/view/${id}/winnings`}>
            {({ isActive }) => (
              <h2 className={` ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                Winnings
              </h2>
            )}
          </NavLink>
          <NavLink to={`/admin/users/view/${id}/wallet-history`}>
            {({ isActive }) => (
              <h2 className={` ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                Wallet History
              </h2>
            )}
          </NavLink>
        </div>
        {/* <Outlet context={[userData._id, setViewRefetch, userData.Currency, transactions, transactionsLoading]} /> */}
        <Outlet context={[userData._id, setViewRefetch, userData.Currency]} />
      </div>
    </div>
  )
}
