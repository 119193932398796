import React, { useState, useRef } from "react";
import DataTable from "react-data-table-component";
import { Icon } from "@iconify/react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
// import 'flatpickr/dist/flatpickr.min.css';
import {
   IconButton,
   Button,
   Menu,
   MenuHandler,
   MenuItem,
   MenuList,
} from "@material-tailwind/react";
import CustomPagination from "../../../helpers/CustomPagination";
import CustomCheckbox from "../../../helpers/CustomCheckbox";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import PrivateLotteryModal from "./PrivateLotteryModal";


export default function ViewPrivateLotteriesUsers() {
   const [page, setPage] = useState(1);
   const [searchKey, setSearchKey] = useState("");
   const [startDate, setStartDate] = useState(null);
   const [endDate, setEndDate] = useState(null);
   const flatpickrRef = useRef();
   const { id } = useParams();

   const customStyles = {
      headRow: {
         style: {
            fontWeight: 600,
            padding: "10px 10px",
            color: "#212529",
         },
      },
      head: {
         style: {
            // borderRadius: "6px 6px 0px 0px",
            borderTop: "0.824px solid #E9E9E9",
            background: "#FFF",
            fontSize: "15px",
         },
      },

      rows: {
         style: {
            // borderRadius: "6px 6px 0px 0px",
            // borderTop: "0.824px solid #E9E9E9",
            background: "#FFF",
            color: "#565656",
            padding: "10px 10px",
            fontSize: "14px",
            fontWeight: 400,
            width: "auto",
         },
      },
      pagination: {
         style: {
            boxShadow: "10px 5px 5px #ddd",
            marginBottom: "5px",
         },
      },
      table: {
         style: {
            overflow: "visible",
            minWidth: "1100px",
         },
      },
      tableWrapper: {
         style: {
            overflow: "visible",
         },
      },
      responsiveWrapper: {
         style: {
            overflowX: "auto", // table scroll on x axis
         },
      },
   };
   const data = [
      {
         leadId: "12345",
         clientName: "Manikanta Putta",
         contactInformation: "manikantaputta@gmail.com 9876543210",
         leadDate: "2022-09-09 14:07:55",
         technology: "Email",
      },
      {
         leadId: "12345",
         clientName: "Vishwanath",
         contactInformation: "Viswanath@gmail.com 9876543210",
         leadDate: "2022-09-09 14:07:55",
         technology: "Cell",
      },
      {
         leadId: "12345",
         clientName: "Madhu",
         contactInformation: "madhu@gmail.com 9876543210",
         leadDate: "2022-09-09 14:07:55",
         technology: "Website",
      },
      {
         leadId: "12345",
         clientName: "Sudheer",
         contactInformation: "sudheer@gmail.com 9876543210",
         leadDate: "2022-09-09 14:07:55",
         technology: "Email",
      },
      {
         leadId: "12345",
         clientName: "Manikanta Putta",
         contactInformation: "manikantaputta@gmail.com 9876543210",
         leadDate: "2022-09-09 14:07:55",
         technology: "Email",
      },
      {
         leadId: "12345",
         clientName: "Manikanta Putta",
         contactInformation: "manikantaputta@gmail.com 9876543210",
         leadDate: "2022-09-09 14:07:55",
         technology: "Email",
      },
      {
         leadId: "12345",
         clientName: "Manikanta Putta",
         contactInformation: "manikantaputta@gmail.com 9876543210",
         leadDate: "2022-09-09 14:07:55",
         technology: "Email",
      },
      {
         leadId: "12345",
         clientName: "Manikanta Putta",
         contactInformation: "manikantaputta@gmail.com 9876543210",
         leadDate: "2022-09-09 14:07:55",
         technology: "Email",
      },
      {
         leadId: "12345",
         clientName: "Manikanta Putta",
         contactInformation: "manikantaputta@gmail.com 9876543210",
         leadDate: "2022-09-09 14:07:55",
         technology: "Email",
      },
      {
         leadId: "12345",
         clientName: "Manikanta Putta",
         contactInformation: "manikantaputta@gmail.com 9876543210",
         leadDate: "2022-09-09 14:07:55",
         technology: "Email",
      },
   ];
   const columns = [
      {
         name: "SL",
         selector: (row, index) => (page - 1) * 5 + index + 1,
         center: true,
         maxWidth: "0.5rem",
      },
      {
         name: "Customer Name",
         id: "customerName",
         selector: (row) => row.clientName,
         center: true,
         grow: 2,
      },
      {
         name: "Date of Purchase",
         id: "dateOfPurchase",
         selector: (row) => moment("2023-09-09 14:07:55").format("DD MMMM YYYY"),
         center: true,
         grow: 2,
      },
      {
         name: "Price",
         id: "price",
         selector: (row) => row.ticketPrice,
         center: true,
         grow: 2,
      },
      {
         name: "Payment Status",
         id: "paymentStatus",
         selector: (row) => (
            <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold w-[8rem] py-[0.6rem] ${row.status === "PENDING" ? "text-[#FF0023] bg-[#FDCAD1]" : "text-[#25BF17] bg-[#D1F0CF]"}`}>
               <span className={`${row.status === "PENDING" ? "bg-[#FF0023]" : "bg-[#25BF17]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
               <h2>{row.status}</h2>
            </div>
         ),
         center: true,
         grow: 2,
      },
      {
         name: "Actions",
         id: "action",
         cell: (row) => (
            <div className="flex space-x-2">
               <Link>
                  <IconButton
                     className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656]"
                     style={{
                        borderRadius: "5.55px",
                        border: "0.925px solid #D9D9D9",
                        background: "#FFF",
                     }}
                  >
                     <Icon icon="icon-park-outline:down" className="text-[1.5rem]" />
                  </IconButton>
               </Link>
            </div>
         ),
         center: true,
         grow: 2,
      },
   ];


   const handleDateChange = (selectedDates) => {
      console.log(selectedDates.length);

      if (selectedDates.length === 1) {
         setStartDate(selectedDates[0]);
      }
      if (selectedDates.length === 2) {
         setEndDate(selectedDates[1]);
      }
   };

   const dateOptions = {
      mode: "range",
      dateFormat: "d-m-Y",
   };

   const clearDatesHandler = () => {
      setStartDate(null);
      setEndDate(null);
      flatpickrRef.current.flatpickr.clear();  // only clear's the date from input field
   }


   return (
      <div className="py-[1rem] w-auto">
         {/* visible till Medium screen */}
         <div
            className="md:hidden flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[full] my-[1rem]"
            style={{
               border: "0.533975px solid #DFDFDF",
               borderRadius: "6px",
            }}
         >
            <div className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md ">
               <Icon
                  icon="iconamoon:search-bold"
                  className="text-[#272727] max-sm:text-[0.9rem]"
               />
            </div>
            <input
               type="text"
               className="text-[0.8rem] sm:text-[1rem] flex-1 bg-transparent placeholder-[#848484] outline-none "
               placeholder="Search here"
               onChange={(e) => {
                  setSearchKey(e.target.value);
               }}
            />
         </div>
         <div className="flex flex-col max-xs:items-start xs:flex-row gap-[0.5rem] sm:gap-[1rem] justify-center sm:justify-between items-center pb-[1rem] xl:mx-[1rem] ">
            <h1 className="text-[1.1rem] sm:text-[1.4rem] font-semibold">
               Users List
            </h1>
            {/* Visible from Medium Screen */}
            <div className="flex items-center gap-[1rem]">
               <div
                  className="max-md:hidden flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[300px] lg:w-[350px]"
                  style={{
                     border: "0.6px solid #DFDFDF",
                     borderRadius: "6px",
                  }}
               >
                  <input
                     type="text"
                     className="flex-1 bg-transparent  placeholder-[#848484] outline-none "
                     placeholder="Search by Lottery Name"
                     value={searchKey}
                     onChange={(e) => {
                        setSearchKey(e.target.value);
                     }}
                  />
                  <button onClick={() => setSearchKey("")}
                     className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
                     <Icon icon="akar-icons:cross" className="text-white" />
                  </button>
               </div>
               <div className="max-md:w-full flex items-center gap-[0.5rem] py-[0.5rem] px-[1rem]"
                  style={{
                     border: "0.6px solid #DFDFDF",
                     borderRadius: "6px",
                  }}
               >
                  <Flatpickr
                     ref={flatpickrRef}
                     className="text-[0.8rem] sm:text-[1rem] bg-transparent placeholder-[#848484] outline-none"
                     placeholder="Select Date"
                     options={dateOptions}
                     onChange={(selectedDate) => handleDateChange(selectedDate)}
                  />
                  <button onClick={clearDatesHandler}
                     className="flex items-center cursor-pointer bg-primary py-[0.25rem] px-[0.2rem] rounded-md">
                     <Icon icon="akar-icons:cross" className="text-white" />
                  </button>
               </div>
               <div className="">
                  <PrivateLotteryModal inviteId={id}/>
               </div>
               <div className="flex flex-row gap-[0.5rem] xs:gap-[1rem] items-center justify-center py-[0.2rem]">
                  <div className="">
                     <Menu>
                        <MenuHandler>
                           <Button className="text-[0.8rem] sm:text-[1rem] flex w-full flex-row justify-center whitespace-nowrap capitalize text-white bg-primary px-[0.4rem] xs:px-[1rem] py-[0.5rem] rounded-md items-center gap-[0.5rem] sm:gap-[1rem] hover:shadow-white shadow-white outline-none">
                              <Icon
                                 icon="material-symbols:download"
                                 className="text-[1rem] w-[1.2rem] sm:w-[1.5rem] h-auto"
                              />
                              <span className="font-[500]">Export</span>
                           </Button>
                        </MenuHandler>
                        <MenuList>
                           <MenuItem
                              //  onClick={downloadPdf}
                              className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                           >
                              {" "}
                              <Icon
                                 icon="bxs:file-pdf"
                                 className="text-[#BE0A23] w-[1.6rem] h-auto"
                              />
                              Download as PDF
                           </MenuItem>
                           <hr className="my-[0.2rem] sm:my-[0.5rem]" />
                           <MenuItem
                              //  onClick={downloadCSV}
                              className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.9rem]"
                           >
                              <Icon
                                 icon="fa-solid:file-csv"
                                 className="text-[#29672E] w-[1.1rem] h-auto"
                              />
                              Download as CSV
                           </MenuItem>
                        </MenuList>
                     </Menu>
                  </div>
               </div>
            </div>
         </div>
         <div className="bg-white">
            <DataTable
               columns={columns}
               data={data}
               customStyles={customStyles}
               pagination
               // progressPending={"isLoading"}
               selectableRows
               selectableRowsComponent={CustomCheckbox}
            />
         </div>
      </div>
   );
}

