import React from 'react'
import WalletAgentStepperController from '../../../../helpers/WalletAgentStepperController'


export default function EditKYCDetails({ currentStep, handleClick, steps }) {
    return (
        <div>
            <h1>
                KYCDetails
            </h1>
            <div className='px-[2rem]'>
                <WalletAgentStepperController currentStep={currentStep} handleClick={handleClick} steps={steps} />
            </div>
        </div>
    )
}
