import React, { useState, useEffect } from 'react'
import { Icon } from '@iconify/react';
import { Select, Option, Checkbox } from '@material-tailwind/react';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';
import { APIurls } from '../../../api/apiConstant';
import { useAuth } from '../../../store/AuthContext';
import { useNavigate, useOutletContext } from 'react-router-dom';

export default function CreateBanner() {
  const [setUpdateBannerList] = useOutletContext();
  const [bannerType, setBannerType] = useState("");
  const [selectImg, setSelectImg] = useState(null);
  const [loaderBtn, setLoaderBtn] = useState(false);
  const [showError, setShowError] = useState(false);
  const [externalLink, setExternalLink] = useState("");
  const [addBanners, setAddBanners] = useState({
    agents: false,
    customers: false,
  })
  const [isBannerAdded, setIsBannerAdded] = useState(false);
  const [lotteryData, setLotteryData] = useState();
  const [lotteryId, setLotteryId] = useState("");

  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
  const { getAccessToken, userRole } = useAuth();

  const fetchLotteryData = async () => {
    const token = await getAccessToken()
    try {
      const response = await fetch(APIurls.fetchLottary, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        toast.error("Failed to Lottery Data in Banners");
        throw new Error("Failed to Fetch Lottery Data in Banners");
      }
      const result = await response.json();
      console.log("Lottary Data in Banners", result?.response?.Lottary);
      setLotteryData(result?.response?.Lottary);
    } catch (error) {
      console.log("Failed to Fetch Lottery Data in Banners")
    }
  };
  useEffect(() => {
    fetchLotteryData();
  }, [])

  const onSubmit = async (data) => {
    if (!(bannerType && selectImg && isBannerAdded &&
      (bannerType !== "External Link" || externalLink !== "") &&
      (bannerType !== "Lottery" || lotteryId !== ""))) {
      setShowError(true);
      return;
    } else {
      setShowError(false);
    }
    // console.log("SUBMITTED DATA", data, bannerType, addBanners);
    const { title } = data;

    const formData = new FormData();
    formData.append("title", title);
    formData.append("bannerType", bannerType);
    formData.append("External_Link", bannerType === "External Link" ? externalLink : bannerType === "Lottery" ? `https://api.strikexgaming.com/refer/?code=${lotteryId}` : "");
    formData.append("addTo.agents", addBanners.agents);
    formData.append("addTo.customers", addBanners.customers);
    formData.append("BannerImg", selectImg);
    const token = await getAccessToken()
    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      }
    };

    try {
      setLoaderBtn(true)
      const response = await fetch(APIurls.createBanner, requestOptions);

      if (!response.ok) {
        setLoaderBtn(false)
        if (response.status === 400) {
          const result = await response.json();
          console.error("Error creating Banner:", result.message);
          console.log(result?.message)
          toast.error(result?.message)
        } else {
          console.log(response.status)
          throw new Error("Error creating Banner");
        }
      } else {
        setLoaderBtn(false)
        const result = await response.json();
        toast.success("Successfully Created Banner!");
        setUpdateBannerList(true);
        console.log(result);
        reset();
        setBannerType("");
        setLotteryId("");
        setSelectImg("");
        setAddBanners((prevState) => {
          return {
            ...prevState,
            agents: false,
            customers: false,
          }
        })
      }
    } catch (error) {
      setLoaderBtn(false)
      toast.error("Error creating Banner")
      console.warn(error);
    }
  }

  const onChangeCheckHandler = (e, key) => {
    if (e.target.checked) {
      setAddBanners((prevState) => {
        const updatedObj = {
          ...prevState,
          [key]: true,
        }
        // To avoid batching of useState hook
        setIsBannerAdded(Object.values(updatedObj).some(value => value === true));
        return updatedObj;
      })
    } else {
      setAddBanners((prevState) => {
        const updatedObj = {
          ...prevState,
          [key]: false,
        }
        setIsBannerAdded(Object.values(updatedObj).some(value => value === true));
        return updatedObj;
      })
    }
  }
  // console.log("isBannerAdded", isBannerAdded);
  // console.log("ADD BANNERS", addBanners);

  return (
    <div className="flex flex-col gap-[1rem] font-[500]">
      <form onSubmit={handleSubmit(onSubmit)}
        className="bg-white flex flex-col gap-[1rem] px-[2rem] rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[2rem] gap-y-[1rem]">
          <div className='flex flex-col gap-[1.2rem]'>
            <div className="flex flex-col gap-[0.5rem]">
              <label htmlFor="title" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                Title
              </label>
              <textarea rows={4}
                className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none resize-none"
                type="text"
                placeholder="Description goes here..."
                {...register("title", {
                  required: "*This field is required.",
                })}
              />
              {errors.title && (
                <p className="text-sm text-[#E92215]">
                  {errors.title.message}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-[0.5rem]">
              <label htmlFor="bannerType" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                Banner Type
              </label>
              <Select className='text-black text-[1rem] font-[500]'
                label='Select'
                size='lg'
                color='blue-gray'
                variant='outlined'
                value={bannerType}
                onChange={(value) => setBannerType(value)}
              >
                <Option value='Offers'>Offers</Option>
                <Option value='Lottery'>Lottery</Option>
                <Option value='External Link'>External Link</Option>
              </Select>
              {showError && !bannerType && (
                <p className="text-sm text-[#E92215]">
                  *This field is required.
                </p>
              )}
            </div>
            {
              bannerType === "External Link" && (
                <div className="flex flex-col gap-[0.5rem]">
                  <label htmlFor="title" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                    External Link
                  </label>
                  <input
                    className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none resize-none"
                    type="text"
                    placeholder="Paste Link here..."
                    value={externalLink}
                    onChange={(e) => setExternalLink(e.target.value)}
                  />
                  {showError && !externalLink && (
                    <div className="text-sm text-[#E92215]">
                      *This Field is Required
                    </div>
                  )}
                </div>
              )
            }
            {
              bannerType === "Lottery" && (
                <div className="flex flex-col gap-[0.5rem]">
                  {
                    lotteryData?.length > 0 ? (
                      <>
                        <Select label='Select Lottery' color='blue-gray' size='lg' variant='outlined'
                          className='text-black text-[1rem] font-[500]'
                          value={lotteryId}
                          onChange={(value) => setLotteryId(value)}
                        >
                          {
                            lotteryData.map((lottery) => {
                              return (
                                <Option key={lottery.LottarySerial} value={lottery.LottarySerial}>
                                  {lottery.Name}
                                </Option>
                              )
                            })
                          }
                        </Select>
                      </>
                    ) : (
                      <Select label='Select Lottery'>
                        <Option value=" ">" "</Option>
                      </Select>
                    )
                  }
                  {showError && !lotteryId && (
                    <div className="text-sm text-[#E92215]">
                      *This Field is Required
                    </div>
                  )}
                </div>
              )
            }
            <div className='flex flex-col gap-[0.5rem]'>
              <label htmlFor="title" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                Select to add Banner
              </label>
              <div className="flex gap-[1rem]">
                <div
                  className="border border-[#D9D9D9] text-[#858585] pr-[2rem] rounded-md">
                  <Checkbox color="green" label="Agents"
                    // checked={"agents"}
                    onChange={(e) => onChangeCheckHandler(e, "agents")}
                  />
                </div>
                <div className="border border-[#D9D9D9] text-[#858585] pr-[2rem] rounded-md">
                  <Checkbox color="green" label="Customers"
                    onChange={(e) => onChangeCheckHandler(e, "customers")}
                  />
                </div>
              </div>
              {showError && !addBanners.agents && !addBanners.customers && (
                <p className="text-sm text-[#E92215]">
                  *This field is required.
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="profilePicture" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Upload Image
            </label>
            <div className="flex flex-col items-center py-[2rem] px-[1rem] border-2 border-dashed
                         border-[#D9D9D9] rounded-lg">
              {selectImg?.name ? (
                <>
                  <h2 className="text-[1.1rem] font-semibold text-green-600 my-[1rem]">
                    Uploaded document
                  </h2>
                  <div className="flex gap-[1rem] items-center">
                    <h2 className="text-[1.1rem] text-black font-[500]">
                      {selectImg?.name.length > 30
                        ? selectImg.name.substring(0, 30) + "..."
                        : selectImg.name}
                    </h2>
                    <Icon
                      onClick={() => setSelectImg(null)}
                      icon="akar-icons:cross"
                      className="text-[1.2rem] cursor-pointer hover:bg-[#3D4A5C]/[0.3]"
                    />
                  </div>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() =>
                      document.querySelector(".input-field").click()
                    }
                  >
                    <Icon
                      icon="material-symbols:cloud-upload"
                      className="text-[#D9D9D9] text-[4rem] mb-[0.5rem]"
                    />
                  </button>
                  <input
                    type="file"
                    // accept="image/*"
                    value=""
                    className="input-field"
                    hidden
                    onChange={({ target: { files } }) => {
                      if (files[0]) {
                        setSelectImg(files[0]);
                      }
                    }}
                  />
                  <p className="text-[0.9rem] text-[#D9D9D9]">
                    Upload jpg, png, pdf. Size up to 20MB
                  </p>
                </>
              )}
            </div>
            {showError && !selectImg && (
              <p className="text-sm text-[#E92215]">
                *This field is required.
              </p>
            )}
          </div>
        </div>
        <div className="flex items-center justify-end mt-[1rem]">
          {(userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("CREATE_BANNERS")) && (
            loaderBtn ? (
            <ThreeDots
              height="50"
              width="50"
              radius="9"
              color="#9FB947"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) :
            (<button
              className="text-[1.2rem] font-semibold text-white bg-primary py-[0.5rem] w-[12rem] rounded-[8px]"
              type="submit"
            >
              Create Banner
            </button>)
          )}
        </div>
      </form>
      <ToastContainer />
    </div>
  )
}
