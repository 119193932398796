import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/headers/Header";
import { Icon } from "@iconify/react";
import { motion, AnimatePresence } from "framer-motion"
import AdminSideBar from "../components/sidebars/AdminSideBar";
import MobileHeader from "../components/headers/MobileHeader";
import { useDispatch, useSelector } from "react-redux";
import { hideNotificationModal } from "../store/notificationSlice";


const AdminLayout = () => {
  const [isAdminSidebarOpen, setIsAdminSidebarOpen] = useState(false);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { showNotificationModal } = useSelector((store) => store.notification)

  const hideNotificationModalHandler = () => {
    if (showNotificationModal) {  // action dispatch only when nofication modal is showing
      dispatch(hideNotificationModal());
    }
  }

  useEffect(() => {
    document.getElementById('scrollable-div').scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [pathname])

  return (
    <div>

      <Header isAdminSidebarOpen={isAdminSidebarOpen}
        setIsAdminSidebarOpen={setIsAdminSidebarOpen} />
      <MobileHeader isAdminSidebarOpen={isAdminSidebarOpen}
        setIsAdminSidebarOpen={setIsAdminSidebarOpen} />

      <div className="flex overflow-y-hidden bg-white"
        onClick={hideNotificationModalHandler}
      >
        <div className="hidden lg:inline-block lg:flex-1">
          <AdminSideBar
            isAdminSidebarOpen={isAdminSidebarOpen}
            setIsAdminSidebarOpen={setIsAdminSidebarOpen}
          />
        </div>

        <AnimatePresence mode='wait'>
          {isAdminSidebarOpen && <motion.div className="absolute top-0 left-0 z-40 lg:hidden flex-1 "
            initial={{ x: -100 }} animate={{ x: 0 }} transition={{ type: "linear", duration: 0.5, }} exit={{ x: -400, transition: "linear", duration: 1.5, }} >
            <AdminSideBar
              isAdminSidebarOpen={isAdminSidebarOpen}
              setIsAdminSidebarOpen={setIsAdminSidebarOpen}
            />
          </motion.div>}
        </AnimatePresence>

        <div id="scrollable-div" className="lg:flex-[5] w-full h-screen overflow-y-auto overflow-x-hidden lg:pt-[4.5rem]">
          {/* <div
            className={`h-full  m-0  lg:my-0 lg:mx-[0.2rem] overflow-x-hidden relative flex flex-col `}
          > */}
          <Outlet />
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;

