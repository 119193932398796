import React, { useEffect, useRef, useState } from 'react'
import { APIurls } from '../../api/apiConstant';
import { useQuery } from 'react-query';
import { useAuth } from '../../store/AuthContext';
import { toast } from 'react-toastify';
import { RotatingLines, ThreeDots } from 'react-loader-spinner';
import { Icon } from "@iconify/react";
import moment from 'moment';
import { Link } from 'react-router-dom';
import { IconButton, Menu, MenuHandler, MenuItem, MenuList, Option, Select } from '@material-tailwind/react';
// import { PDFDownloadLink } from '@react-pdf/renderer';
import { Button } from 'react-scroll';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import DataTable from 'react-data-table-component';
import CustomCheckbox from '../../helpers/CustomCheckbox';
// import DownloadPDF from '../../exportData/Export PDF';

export default function ArchiveLotteries() {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [status, setStatus] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredLotteries, setFilteredLotteries] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});
  const flatpickrRef = useRef();
  const { getAccessToken, userRole } = useAuth();

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        padding: "10px 10px",
        color: "#212529",
      },
    },
    head: {
      style: {
        borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        fontSize: "15px",
      },
    },

    rows: {
      style: {
        background: "#FFF",
        color: "#565656",
        padding: "10px 10px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    pagination: {
      style: {
        boxShadow: "10px 5px 5px #ddd",
        marginBottom: "5px",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1700px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto",
      },
    },
  };
  const columns = [
    {
      name: "SL",
      selector: (row, index) => (currentPage - 1) * rowsPerPage + index + 1,
      center: true,
      maxWidth: "0.5rem",
    },
    {
      name: "Ticket Number",
      id: "ticketNumber",
      selector: (row) => row?.archiveData.LottaryNumber,
      center: true,
      grow: 3,
    },
    {
      name: "Lottery Name",
      id: "lotteryName",
      selector: (row) => row?.archiveData.Name,
      center: true,
      grow: 3,
    },
    {
      name: "Winning Amount",
      id: "winningAmount",
      selector: (row) => row?.archiveData.winneramount.toString(),
      center: true,
      grow: 2.5,
    },
    {
      name: "Deleted Date & Time",
      id: "deletedDate",
      selector: (row) => moment(row.updatedAt).format("DD MMMM YYYY - HH:mm A"),
      center: true,
      grow: 4,
    },
    {
      name: "Created At",
      id: "createdAt",
      selector: (row) => moment(row?.archiveData.createdAt).format("DD MMMM YYYY"),
      center: true,
      grow: 3,
    },
    {
      name: "Expected Date",
      id: "expectedDate",
      selector: (row) => moment(row?.archiveData.expieryDate).format("DD MMMM YYYY"),
      center: true,
      grow: 3,
    },
    {
      name: "Image",
      id: "image",
      selector: (row) => (
        <div className="rounded-lg">
          <img src={row?.archiveData.lottaryImage} alt="" className="h-[50px] w-[120px] bg-center" />
        </div>
      ),
      center: true,
      grow: 2,
    },
    {
      name: "Status",
      id: "status",
      selector: (row) => (
        <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold w-[8rem] 
        py-[0.6rem] ${row?.archiveData.status === "PENDING" ? "text-[#FF0023] bg-[#FDCAD1]" : "text-[#25BF17] bg-[#D1F0CF]"}`}>
          <span className={`${row?.archiveData.status === "PENDING" ? "bg-[#FF0023]" : "bg-[#25BF17]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
          <h2>{row?.archiveData.status}</h2>
        </div>
      ),
      center: true,
      grow: 2.5,
    },
    {
      name: "Actions",
      id: "action",
      cell: (row) => (
        <div>
          {(userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("RETRIEVE_PUBLIC_LOTTERIES")) && (
            loadingStates[row.archivedID] ? (
              <ThreeDots
                height="50"
                width="50"
                radius="9"
                color="#9FB947"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <IconButton onClick={() => retrieveArchivedLotteryHandler(row.archivedID)}
                className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656]"
                style={{
                  borderRadius: "5.55px",
                  border: "0.925px solid #D9D9D9",
                  background: "#FFF",
                }}
              >
                <Icon
                  icon="material-symbols:restore-page"
                  className="text-[1.5rem]"
                />
              </IconButton>
            )
          )}
          {/* </Link> */}
        </div>
      ),
      center: true,
      grow: 1,
    },
  ];
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleRowsPerPageChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage);
    setCurrentPage(page);
  };

  const retrieveArchivedLotteryHandler = async (archivedID) => {
    // eslint-disable-next-line no-restricted-globals
    let isConfirm = confirm("Are you sure you want to Retrieve this Lottery?");
    if (isConfirm) {
      const token = await getAccessToken();
      try {
        setLoadingStates((prevState) => ({
          ...prevState,
          [archivedID]: true,
        }));
        const response = await fetch(APIurls.retrieveArchivedLottery, {
          method: 'POST',
          body: JSON.stringify({
            "ArchiveID": archivedID,
          }),
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        })
        const result = await response.json();
        if (!response.ok) {
          toast.error(result.message || "Failed to retrieve Lottery");
          throw new Error("Failed to retrieve Lottery");
        }
        console.log(result);
        toast.success(result.message || "Lottery retrieved Successfully");
        refetch();
      } catch (error) {
        console.warn("Failed to retrieve Lottery")
        // toast.error("Failed to retrieve Lottery")
      }
      setLoadingStates((prevState) => ({
        ...prevState,
        [archivedID]: false,
      }));
    }
  }

  const fetchArchiveLotteriesData = async () => {
    const token = await getAccessToken()
    const response = await fetch(APIurls.fetchArchiveLotteries, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    const result = await response.json();
    if (!response.ok) {
      throw new Error(result?.message || "Failed to Fetch Archive Lotteries Data");
    }
    console.log("Archive Lotteries Data...", result?.response?.archiveLottery);
    return result?.response?.archiveLottery?.reverse();
  };
  const { data: archiveLotteries, isLoading, isError, error, refetch } = useQuery(['archiveLotteriesData'], fetchArchiveLotteriesData);

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 1) {
      setStartDate(selectedDates[0]);
    }
    if (selectedDates.length === 2) {
      setEndDate(selectedDates[1]);
    }
  };
  const dateOptions = {
    mode: "range",
    dateFormat: "d-m-Y",
  };
  const clearDatesHandler = () => {
    setStartDate(null);
    setEndDate(null);
    flatpickrRef.current.flatpickr.clear();
  }

  useEffect(() => {
    let mapData = archiveLotteries?.map((item) => {
      return { ...item };
    });
    if (searchKey !== "") {
      mapData = mapData?.filter((item) => {
        return item?.archiveData?.Name?.toLowerCase().includes(searchKey.toLowerCase());
      });
    }
    if (startDate && endDate) {
      const dStart = new Date(startDate);
      dStart.setUTCHours(0, 0, 0, 0);
      const dEnd = new Date(endDate);
      dEnd.setUTCHours(23, 59, 59, 999);
      mapData = mapData?.filter((item) => {
        const itemDate = new Date(item?.archiveData?.createdAt);
        return (
          itemDate.getTime() >= dStart.getTime() && itemDate.getTime() <= dEnd.getTime()
        );
      });
    }
    if (status !== "") {
      if (status === "ALL") {
        setFilteredLotteries(mapData);
      } else {
        mapData = mapData?.filter((item) => item?.archiveData?.status === status);
      }
    }
    setFilteredLotteries(mapData);
  }, [searchKey, startDate, endDate, archiveLotteries, status]);

  if (isError) {
    return (
      <div>
        {toast.error(error.message)}
        <h1 className='text-[2rem] font-bold flex items-center justify-center h-screen'>
          Failed to Fetch Archive Lotteries Data...!
        </h1>
      </div>
    )
  }
  if (isLoading) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <RotatingLines
          strokeColor="green"
          strokeWidth="5"
          animationDuration="0.75"
          width="100"
          visible={true}
        />
      </div>
    )
  }

  return (
    <div className="px-4 py-8 w-auto">
      <h1 className="text-[1.2rem] sm:text-[1.5rem] font-semibold whitespace-nowrap mb-4">
        Archive Lotteries
      </h1>
      <div className="flex flex-col max-xs:items-start xs:flex-row gap-[0.5rem] sm:gap-[1rem] justify-between items-center py-4">
        {/* Visible from Medium Screen */}
        <h1 className="text-[1.4rem] font-semibold">
          Public Lotteries
        </h1>
        <div className="flex gap-[1rem] items-center">
          <div
            className="max-md:hidden flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[300px] lg:w-[350px]"
            style={{
              border: "0.6px solid #DFDFDF",
              borderRadius: "6px",
            }}
          >
            <input
              type="text"
              className="flex-1 bg-transparent  placeholder-[#848484] outline-none "
              placeholder="Search by Lottery Name"
              value={searchKey}
              onChange={(e) => {
                setSearchKey(e.target.value);
              }}
            />
            {
              !searchKey ? (
                <button type="button"
                  className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:search" className="text-white" />
                </button>
              ) : (
                <button onClick={() => setSearchKey("")}
                  className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:cross" className="text-white" />
                </button>
              )
            }
          </div>
          <div className="max-md:w-full flex items-center gap-[0.5rem] py-[0.5rem] px-[1rem]"
            style={{
              border: "0.6px solid #DFDFDF",
              borderRadius: "6px",
            }}
          >
            <Flatpickr
              ref={flatpickrRef}
              className="text-[0.8rem] sm:text-[1rem] bg-transparent placeholder-[#848484] outline-none"
              placeholder="Select Date"
              options={dateOptions}
              onChange={(selectedDate) => handleDateChange(selectedDate)}
            />
            {
              !startDate || !endDate ? (
                <button type="button"
                  className="flex items-center cursor-pointer bg-primary py-[0.25rem] px-[0.2rem] rounded-md">
                  <Icon icon="solar:calendar-outline" className="text-white" />
                </button>
              ) : (
                <button onClick={clearDatesHandler}
                  className="flex items-center cursor-pointer bg-primary py-[0.25rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:cross" className="text-white" />
                </button>
              )
            }
          </div>
          <div className="">
            <Select label="Select Status"
              className="text-[0.8rem] sm:text-[1rem] bg-transparent"
              value={status}
              onChange={(value) => setStatus(value)}
            >
              <Option value="COMPLETED">COMPLETED</Option>
              <Option value="PENDING">PENDING</Option>
              <Option value="ALL">ALL</Option>
            </Select>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <DataTable
          columns={columns}
          data={filteredLotteries}
          customStyles={customStyles}
          pagination
          progressPending={isLoading}
          selectableRows
          selectableRowsComponent={CustomCheckbox}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
        />
      </div>
    </div>
  )
}
