import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import authBgImage from '../../assets/auth-bg-img.svg'
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import strikeLogo from "../../assets/strike-logo.svg"
import { Icon } from '@iconify/react';

export default function OTPVerfication() {
  const [loaderBtn, setLoaderBtn] = useState(false);
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, control, reset } = useForm();

  const onSubmit = async (data) => {
    console.log(data);
    if (data.otp === "123456") {
      navigate("/success");
    } else {
      navigate("/failure");
    }
  }

  return (
    <div
      className="w-full min-h-screen max-xs:px-[0.5rem] bg-cover bg-[#F1F6FA] bg-center bg-repeat flex justify-center items-center"
      style={{ backgroundImage: `url(${authBgImage})` }}
    >
      <div className="bg-white flex flex-col min-w-[20rem] xs:min-w-[28.75rem] justify-center  px-[1rem]  xs:px-[4rem] py-[1rem] xs:py-[3rem] gap-[1rem] xs:gap-[1rem] rounded-[10px]  ">
        <Link to="/">
          <div>
            <img
              src={strikeLogo}
              alt=""
              className=""
            />
          </div>
        </Link>
        <h1 className="text-[#1A3032] font-bold text-[1.5rem] xs:text-[1.8rem]">
          OTP Verification
        </h1>
        <p className='text-[0.8rem] text-[#8F9193]'>
          We have sent you the OTP to your E-mail
        </p>
        <div className='flex items-center gap-[0.5rem]'>
          <span className='text-primary text-[1rem] font-[600]'>
            manikantaputta@gmail.com
          </span>
          <Icon icon="fe:edit" className='text-[1.5rem] text-[#7F7F7F] cursor-pointer' />
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full flex flex-col gap-[1rem]"
        >
          <div className="group flex flex-col gap-[0.5rem]">
            <label htmlFor="otp" className="text-[1rem] font-[600]">
              Enter OTP
            </label>
            <div className={`flex flex-row text-[0.9rem] font-[600] group-focus-within:bg-white  group-focus-within:shadow-md border  ${!errors.email ? "border-[#AFBACA]" : "border-red-600 "
              } items-center rounded-md px-[0.5rem] xs:px-[1rem] py-[0.5rem]`}>
              <input
                type="number"
                step="any"
                name="otp"
                placeholder="123456"
                className="ml-[1rem] outline-none w-1 border-none flex-grow placeholder:text-[#858585]"
                {...register("otp", {
                  required: "*This field is required.",
                  minLength: 6,
                })}
              />
            </div>
            {errors.otp?.type === "required" && (
              <p className="text-red-600 text-sm">{errors.otp.message}</p>
            )}
            {errors.otp?.type === "minLength" && (
              <p className="text-red-600 text-sm">*OTP Length: 6</p>
            )}
          </div>
          <div className="flex flex-row justify-center">
            {loaderBtn ? (
              <ThreeDots
                height="50"
                width="50"
                radius="9"
                color="#9FB947"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) :
              (<button
                type="submit"
                className="w-full text-white text-[1.1rem] rounded-md px-[1.5rem] py-[0.8rem] bg-primary font-bold"
              >
                Submit
              </button>)}
          </div>
          <Link to={"/login"}>
            <button type='button'
              className='flex items-center gap-[0.5rem] text-[0.9rem] text-[#8F9193]'>
              <Icon icon="akar-icons:arrow-back-thick-fill" />
              <span>Back to Login</span>
            </button>
          </Link>
        </form>
        <ToastContainer />
      </div>
    </div>
  )
}
