import React from 'react'
import StepperController from '../../../../helpers/StepperController'
import WalletAgentStepperController from '../../../../helpers/WalletAgentStepperController'


export default function KYCDetails({ currentStep, handleClick, steps }) {
  return (
    <div>
      <h1>
        KYCDetails
      </h1>
      <div className='px-[2rem]'>
        <WalletAgentStepperController currentStep={currentStep} handleClick={handleClick} steps={steps} />
      </div>
    </div>
  )
}
