import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { Icon } from "@iconify/react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
// import 'flatpickr/dist/flatpickr.min.css';
import {
  IconButton,
  Option,
  Select,
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Checkbox,
} from "@material-tailwind/react";
import CustomCheckbox from "../../helpers/CustomCheckbox";
import moment from "moment";
import { Link } from "react-router-dom";
import { useQuery } from 'react-query';
import { APIurls } from "../../api/apiConstant";
import { useAuth } from "../../store/AuthContext";
import { toast } from "react-toastify";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DownloadPDF from "../../exportData/Export PDF";
import { RotatingLines, ThreeDots } from "react-loader-spinner";
import UserSpecificNotificationsModal from "./UserSpecificNotificationsModal";

export default function UsersList() {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [status, setStatus] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const flatpickrRef = useRef();
  const [loadingStates, setLoadingStates] = useState({});
  const [noBookings, setNoBookings] = useState(false);
  const [userIds, setUserIds] = useState([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { getAccessToken, userRole } = useAuth()

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        padding: "10px 10px",
        color: "#212529",
      },
    },
    head: {
      style: {
        borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        fontSize: "15px",
      },
    },

    rows: {
      style: {
        background: "#FFF",
        color: "#565656",
        padding: "10px 10px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    pagination: {
      style: {
        boxShadow: "10px 5px 5px #ddd",
        marginBottom: "5px",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1500px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };
  const columns = [
    {
      name: "SL",
      selector: (row, index) => (currentPage - 1) * rowsPerPage + index + 1,
      center: true,
      maxWidth: "0.5rem",
    },
    // {
    //    name: "Image",
    //    id: "image",
    //    selector: (row) => (
    //       <div className="rounded-md">
    //          <img src={row.imageUrl || noImage1} alt="" className="h-[50px] w-[80px] bg-center bg-cover" />
    //       </div>
    //    ),
    //    center: true,
    //    grow: 2,
    // },
    {
      name: "User Name",
      id: "userName",
      selector: (row) => row.FirstName + " " + row.LastName,
      center: true,
      grow: 2,
    },
    {
      name: "Country & Currency",
      id: "countryCurrency",
      selector: (row) => row.location.country
      //+ " | " + row.location.currency
      ,
      center: true,
      grow: 2,
    },
    {
      name: "Registration Date",
      id: "registrationDate",
      selector: (row) => moment(row.createdAt).format("DD MMMM YYYY"),
      center: true,
      grow: 2,
    },
    {
      name: "Balance",
      id: "balance",
      selector: (row) => row.wallet,
      center: true,
      grow: 1.5,
    },
    {
      name: "Ticket Bookings",
      id: "ticketBookings",
      selector: (row) => row?.purchaseReport?.length,
      center: true,
      grow: 1.7,
    },
    {
      name: "Status",
      id: "status",
      selector: (row) => (
        <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
        py-[0.6rem] w-[9rem] ${row.status === "ACTIVE" ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"}`}>
          <span className={`${row.status === "ACTIVE" ? "bg-[#25BF17]" : "bg-[#FF0023]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
          <h2>{row.status}</h2>
        </div>
      ),
      center: true,
      grow: 2.5,
    },
    {
      name: "Actions",
      id: "action",
      cell: (row) => (
        <div className="flex space-x-2">
          <Link to={`view/${row.customerId}`}>
            <IconButton
              className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656]"
              style={{
                borderRadius: "5.55px",
                border: "0.925px solid #D9D9D9",
                background: "#FFF",
              }}
            >
              <Icon icon="mdi:eye" className="text-[1.5rem]" />
            </IconButton>
          </Link>
          {(userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("UPDATE_USER_STATUS")) && (
            loadingStates[row.customerId] ? (
              <ThreeDots
                height="50"
                width="50"
                radius="9"
                color="#25BF17"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <IconButton onClick={() => disableUserHandler(row.customerId)}
                className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656]"
                style={{
                  borderRadius: "5.55px",
                  border: "0.925px solid #D9D9D9",
                  background: "#FFF",
                }}
              >
                <Icon
                  icon="mdi:user-block"
                  className="text-[1.5rem]"
                />
              </IconButton>
            )
          )}
        </div>
      ),
      center: true,
      grow: 1.5,
    },
  ];
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleRowsPerPageChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage);
    setCurrentPage(page);
  };

  const disableUserHandler = async (customerId) => {
    setLoadingStates((prevState) => ({
      ...prevState,
      [customerId]: true,
    }))
    const token = await getAccessToken()
    try {
      const response = await fetch(`${APIurls.disableUser}/${customerId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      if (!response.ok) {
        throw new Error("Failed to Update User Status");
      }
      const result = await response.json();
      console.log(result);
      toast.success("User Status Updated Successfully");
      refetch();  // to trigger lottery data for updated data
    } catch (error) {
      console.warn("Failed to Update User Status");
      toast.error("Failed to Update User Status");
    }
    setLoadingStates((prevState) => ({
      ...prevState,
      [customerId]: false,
    }))
  }

  const fetchUsersData = async () => {

    const token = await getAccessToken()

    /// console.log("Lottary",token)
    const response = await fetch(APIurls.fetchUsers, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (!response.ok) {
      throw new Error("Failed to Fetch Users Data");
    }
    const result = await response.json();
    console.log("USERS DATA", result?.response?.Users);
    return result?.response?.Users?.reverse();
  };
  const { data: usersData, isLoading, refetch, isError, error } = useQuery('users', fetchUsersData,
    {
      enabled: (userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("VIEW_USERS")),
    }
  );

  let activeUsers = usersData?.filter((lottery) => lottery?.status?.toLowerCase() === "active");
  let inactiveUsers = usersData?.length - activeUsers?.length;

  useEffect(() => {
    let mapData = usersData?.map((item, index) => {
      return { ...item };
    });
    if (searchKey !== "") {
      mapData = mapData.filter((item) => {
        let Name = item.FirstName + " " + item.LastName;
        return Name?.toLowerCase().includes(searchKey.toLowerCase());
      });
    }
    if (startDate && endDate) {
      console.log("DATE...........DATE...........DATE");
      const dStart = new Date(startDate);
      dStart.setUTCHours(0, 0, 0, 0);
      const dEnd = new Date(endDate);
      dEnd.setUTCHours(23, 59, 59, 999);
      mapData = mapData.filter((item) => {
        const itemDate = new Date(item.createdAt);
        return (
          itemDate.getTime() >= dStart.getTime() && itemDate.getTime() <= dEnd.getTime()
        );
      });
    }
    if (status !== "") {
      if (status === "ALL") {
        setFilteredItems(mapData);
      } else {
        mapData = mapData.filter((item) => item.status === status);
      }
    }
    if (noBookings) {
      mapData = mapData.filter((item) => item?.purchaseReport?.length === 0)
    }
    setFilteredItems(mapData);
  }, [searchKey, startDate, endDate, usersData, status, noBookings]);

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 1) {
      setStartDate(selectedDates[0]);
    }
    if (selectedDates.length === 2) {
      setEndDate(selectedDates[1]);
    }
  };

  const dateOptions = {
    mode: "range",
    dateFormat: "d-m-Y",
  };

  const clearDatesHandler = () => {
    setStartDate(null);
    setEndDate(null);
    flatpickrRef.current.flatpickr.clear();
  }

  const handleSelectedRows = ({ selectedRows }) => {
    console.log("Selected Rows", selectedRows);
    console.log("User ID's", selectedRows.map((user) => user._id));
    setUserIds(selectedRows.map((user) => user._id));
  }
  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  }

  if (isError) {
    return (
      <div>
        {toast.error(error.message)}
        <h1 className='text-[2rem] font-bold flex items-center justify-center h-screen'>
          Failed to Fetch Lotteries Data List...!
        </h1>
      </div>
    )
  }
  if (isLoading) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <RotatingLines
          strokeColor="green"
          strokeWidth="5"
          animationDuration="0.75"
          width="100"
          visible={true}
        />
      </div>
    )
  }

  return (
    <div className="px-[1rem] py-[1rem] w-auto">
      <div className="flex items-center justify-between">
        <h1 className="text-[1.2rem] sm:text-[1.5rem] font-semibold">
          Users
        </h1>
        {
          (userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("SEND_USER_NOTIFICATIONS")) && (
            <button onClick={() => setShowModal(true)}
              className="text-[1.1rem] font-medium text-white bg-primary py-[0.45rem] px-[1rem] rounded-[8px]"
              disabled={userIds.length === 0}
            >
              Send Notification
            </button>
          )
        }
      </div>
      {/* visible till Medium screen */}
      <div
        className="md:hidden flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[full] my-[1rem]"
        style={{
          border: "0.533975px solid #DFDFDF",
          borderRadius: "6px",
        }}
      >
        <div className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md ">
          <Icon
            icon="iconamoon:search-bold"
            className="text-[#272727] max-sm:text-[0.9rem]"
          />
        </div>
        <input
          type="text"
          className="text-[0.8rem] sm:text-[1rem] flex-1 bg-transparent placeholder-[#848484] outline-none "
          placeholder="Search here"
          onChange={(e) => {
            setSearchKey(e.target.value);
          }}
        />
      </div>
      <div className="grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-6 my-7">
        {/* badge -1: Total Agents  */}
        <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <div>
            <header className='flex justify-between'>
              <h3 className='text-[14px] text-[#858585]'>Total Users</h3>
              <Icon className='text-[#25BF17] text-[1.9rem]' icon="heroicons-solid:user-group" />
            </header>
            <h3 className='text-[#25BF17] text-[26px] font-bold'>
              {usersData?.length || "NA"}
            </h3>
          </div>
          <div>
            <div className='mb-3'><hr /></div>
            <footer className='flex justify-between items-center gap-1'>
              <div className='flex items-center gap-1'>
                <Icon className='text-[#198754] w-3 h-3' icon="mdi:arrow-up-thin" />
                <small className='text-[#198754] text-[10px]'>3.05%</small>
                <small className='text-[9px] text-[#8898AA]'>Since last month</small>
              </div>
            </footer>
          </div>
        </div>
        {/* badge -2: Active Agents   */}
        <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <div>
            <header className='flex justify-between'>
              <h3 className='text-[14px] text-[#858585]'>Active</h3>
              <Icon icon="ooui:user-active" className="text-primary text-[1.8rem]" />
            </header>
            <h3 className='text-[#25BF17] text-[26px] font-bold'>
              {activeUsers?.length !== "undefined" ? activeUsers?.length : "NA"}
            </h3>
          </div>
          <div>
            <div className='mb-3'><hr /></div>
            <footer className='flex justify-between items-center gap-1'>
              <div className='flex items-center gap-1'>
                <Icon className='text-[#198754] w-3 h-3' icon="mdi:arrow-up-thin" />
                <small className='text-[#198754] text-[10px]'>3.05%</small>
                <small className='text-[9px] text-[#8898AA]'>Since last month</small>
              </div>
            </footer>
          </div>
        </div>
        {/* badge -3: Inactive Agents  */}
        <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <div>
            <header className='flex justify-between'>
              <h3 className='text-[14px] text-[#858585]'>Suspended</h3>
              <Icon className='text-[#25BF17] text-[1.7rem]' icon="icon-park-solid:close-one" />
            </header>
            <h3 className='text-[#25BF17] text-[26px] font-bold mb-5'>
              {inactiveUsers !== "undefined" ? inactiveUsers : "NA"}
            </h3>
          </div>
          <div>
            <div className='mb-3'><hr /></div>
            <footer className='flex justify-between items-center gap-1'>
              <div className='flex items-center gap-1'>
                <Icon className='text-[#198754] w-3 h-3' icon="mdi:arrow-up-thin" />
                <small className='text-[#198754] text-[10px]'>3.05%</small>
                <small className='text-[9px] text-[#8898AA]'>Since last month</small>
              </div>
            </footer>
          </div>
        </div>
        <div></div>

      </div>
      <div className="flex flex-col max-xs:items-start xs:flex-row gap-[0.5rem] sm:gap-[1rem] justify-center sm:justify-end items-center pb-[1rem] xl:mx-[1rem] flex-wrap">
        {/* Visible from Medium Screen */}
        <div className="flex items-center pl-2 text-[#858585] max-h-[41px]"
          style={{
            border: "0.6px solid #DFDFDF",
            borderRadius: "6px",
          }}
        >
          <label htmlFor="noBookings" className="cursor-pointer">No Bookings List</label>
          <Checkbox
            className="h-5 w-5"
            id="noBookings"
            color="green"
            value={noBookings}
            onChange={(e) => setNoBookings(e.target.checked)}
          />
        </div>
        <div
          className="max-md:hidden flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[300px] lg:w-[350px]"
          style={{
            border: "0.6px solid #DFDFDF",
            borderRadius: "6px",
          }}
        >
          <input
            type="text"
            className="flex-1 bg-transparent  placeholder-[#848484] outline-none "
            placeholder="Search by User Name"
            value={searchKey}
            onChange={(e) => {
              setSearchKey(e.target.value);
            }}
          />
          {
            !searchKey ? (
              <button type="button"
                className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
                <Icon icon="akar-icons:search" className="text-white" />
              </button>
            ) : (
              <button onClick={() => setSearchKey("")}
                className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
                <Icon icon="akar-icons:cross" className="text-white" />
              </button>
            )
          }
        </div>
        <div className="max-md:w-full flex items-center gap-[0.5rem] py-[0.5rem] px-[1rem]"
          style={{
            border: "0.6px solid #DFDFDF",
            borderRadius: "6px",
          }}
        >
          <Flatpickr
            ref={flatpickrRef}
            className="text-[0.8rem] sm:text-[1rem] bg-transparent placeholder-[#848484] outline-none"
            placeholder="Select Date"
            options={dateOptions}
            onChange={(selectedDate) => handleDateChange(selectedDate)}
          />
          {
            !startDate || !endDate ? (
              <button type="button"
                className="flex items-center cursor-pointer bg-primary py-[0.25rem] px-[0.2rem] rounded-md">
                <Icon icon="solar:calendar-outline" className="text-white" />
              </button>
            ) : (
              <button onClick={clearDatesHandler}
                className="flex items-center cursor-pointer bg-primary py-[0.25rem] px-[0.2rem] rounded-md">
                <Icon icon="akar-icons:cross" className="text-white" />
              </button>
            )
          }
        </div>
        <div className="">
          <Select label="Select Status"
            className="text-[0.8rem] sm:text-[1rem] bg-transparent"
            value={status}
            onChange={(value) => setStatus(value)}
          >
            <Option value="ACTIVE">ACTIVE</Option>
            <Option value="SUSPENDED">SUSPENDED</Option>
            <Option value="ALL">ALL</Option>
          </Select>
        </div>
        <div className="flex flex-row gap-[0.5rem] xs:gap-[1rem] items-center justify-center py-[0.2rem]">
          <div className="">
            <Menu>
              <MenuHandler>
                <Button className="text-[0.8rem] sm:text-[1rem] flex w-full flex-row justify-center whitespace-nowrap capitalize text-white bg-primary px-[0.4rem] xs:px-[1rem] py-[0.5rem] rounded-md items-center gap-[0.5rem] sm:gap-[1rem] hover:shadow-white shadow-white outline-none">
                  <Icon
                    icon="material-symbols:download"
                    className="text-[1rem] w-[1.2rem] sm:w-[1.5rem] h-auto"
                  />
                  <span className="font-[500]">Export</span>
                </Button>
              </MenuHandler>
              <MenuList>
                <PDFDownloadLink document={<DownloadPDF data={filteredItems} filteredUsersList={true} />}>
                  <MenuItem
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                  >
                    {" "}
                    <Icon
                      icon="bxs:file-pdf"
                      className="text-[#BE0A23] w-[1.6rem] h-auto"
                    />
                    Download as PDF
                  </MenuItem>
                </PDFDownloadLink>
                <hr className="my-[0.2rem] sm:my-[0.5rem]" />
                <MenuItem
                  //  onClick={downloadCSV}
                  className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.9rem]"
                >
                  <Icon
                    icon="fa-solid:file-csv"
                    className="text-[#29672E] w-[1.1rem] h-auto"
                  />
                  Download as CSV
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <DataTable
          columns={columns}
          data={filteredItems}
          customStyles={customStyles}
          pagination
          progressPending={isLoading}
          selectableRows
          selectableRowsComponent={CustomCheckbox}
          onSelectedRowsChange={handleSelectedRows}
          clearSelectedRows={toggledClearRows}
          paginationDefaultPage={currentPage}
          paginationPerPage={rowsPerPage}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
        />
      </div>
      {
        showModal && <UserSpecificNotificationsModal setShowModal={setShowModal} userIds={userIds} handleClearRows={handleClearRows} />
      }
    </div>
  );
}
