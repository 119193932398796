import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";
import { ThreeDots } from 'react-loader-spinner';
import { Option, Select } from "@material-tailwind/react";
import { generateLotteryNumber } from "../../../utils/lottaryGen";

export default function CreatePrivateLotteries() {
  const [selectImg, setSelectImg] = useState();
  const navigate = useNavigate();
  const [selectDate, setSelectDate] = useState(null);
  const [loaderBtn, setLoaderBtn] = useState(false);
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [lottarynumber, setLottarynumber] = useState("")
  const [agentsData, setAgentsData] = useState([]);
  const [agentId, setAgentId] = useState("");

  const [winnerSlotsdata, setWinnerslot] = useState(0)
  const { getAccessToken } = useAuth()

  // Initialize state to store input values
  const [inputValues, setInputValues] = useState(Array(winnerSlotsdata).fill(''));

  // Function to handle input changes and update state
  const handleInputChange = (index, value) => {

    const struct = { slot: index + 1, amount: value }
    const newInputValues = [...inputValues];
    newInputValues[index] = struct;
    setInputValues(newInputValues);

    console.log(inputValues)
  };

  const submitWinnerSlot = (num) => {
    if (num < 0) {
      return setWinnerslot(0)
    }
    if (num > 0) {
      const data = {
        winnerNum: 0
      }
      setWinnerslot(num)
    }

  }

  const fetchAgentsData = async () => {
    const token = await getAccessToken()

    try {
      const response = await fetch(APIurls.fetchAgent, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        throw new Error("Failed to Fetch Agents Data");
      }
      const result = await response.json();
      setAgentsData(result?.response?.Agents)
      console.log("Agents data Priv", result?.response?.Agents);
    } catch (error) {
      console.warn("Failed to Fetch Lottery Data");
      toast.error("Failed to Fetch Agents Data");
    }
  }
  // console.log(agentId);

  useEffect(() => {
    setLottarynumber(generateLotteryNumber())
    fetchAgentsData();
  }, [])


  const onSubmit = async (data, event) => {
    event.preventDefault();
    const { name, totalTickets, pricePerTicket, termsAndConditions, totalWinningAmount } = data;

    // if (winnerSlotsdata === 0 || winnerSlotsdata < 0) {

    //   return toast.warn("Please select atlest one winner slot")
    // }

    // if (_.isEmpty(inputValues)) {

    //   return toast.warn("Please select atlest one winner slot")
    // }

    const formData = new FormData();
    formData.append("agentId", agentId);
    formData.append("lottaryName", name);
    formData.append("LottaryNumber", lottarynumber);
    formData.append("totalTickets", totalTickets);
    formData.append("ticketPrice", pricePerTicket);
    formData.append("price", pricePerTicket);
    formData.append("winneramount", "50000");
    formData.append("winningAmount", totalWinningAmount);
    formData.append("winnerSlot", "2");
    formData.append("winnerNumber", 1);
    formData.append("Currency", "IND");
    formData.append("expierydate", selectDate);
    formData.append("termsAndConditions", termsAndConditions);
    formData.append("lottaryImage", selectImg);

    // formData.append('winnerSlotArray', JSON.stringify(inputValues));
    formData.append('winnerSlotArray', JSON.stringify([{
      "slot":1,
      "amount": totalWinningAmount,
   }]));

    const token = await getAccessToken()

    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      }
    };

    try {
      setLoaderBtn(true)
      const response = await fetch(APIurls.createPrivateLottary, requestOptions);

      if (!response.ok) {
        setLoaderBtn(false)
        if (response.status === 400) {
          // Handle 400 Bad Request error
          const result = await response.json();
          console.error("Error creating Private Lottery:", result.error);
          // Additional handling for 400 status code, if needed
          // Example: setValidationError(result.validationError);
          console.log(result?.message)
          toast.error(result?.message)
        } else {
          console.log(response.status)
          // Handle other non-OK responses
          throw new Error("Error creating Private Lottery");
        }
      } else {
        setLoaderBtn(false)
        // Handle successful response (status code 200 or 201)
        const result = await response.json();
        setSelectDate(null);
        setSelectImg("");

        toast.success("Successfully created Private Lottery !");
        navigate('/admin/private-lotteries')
        console.log(result);
        reset();
      }
    } catch (error) {
      setLoaderBtn(false)
      // Handle network errors or unexpected errors
      // toast.error("Error creating Lottery");
      toast.error("Error creating Lottery")
      console.warn(error);
    }
  }

  const handleDateChange = (selectedDate) => {
    setSelectDate(selectedDate[0]);
    // console.log(selectedDates[0]);
  };
  const dateOptions = {
    mode: "single",  // for single date
    dateFormat: "d M Y",
  };

  return (
    <div className="flex flex-col gap-[1rem] px-0 sm:px-[1rem] py-[1.5rem] font-[500]">
      <div className="text-primary flex items-center gap-[1rem] px-[1rem]">
        <Icon icon="game-icons:ticket" className="text-[2rem]" />
        <h1 className="text-[1.2rem] sm:text-[1.7rem] font-semibold">
          Create a Private Lottery
        </h1>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}
        className="bg-white flex flex-col gap-[2rem] px-[2rem] py-[1rem] rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[2rem] gap-y-[1rem]">
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="nameOfLottery" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Name of Private Lottery<span className="text-[#E92215]">*</span>
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="text"
              placeholder="Enter Name"
              {...register("name", {
                required: "*This field is required.",
              })}
            />
            {errors.name && (
              <div className="text-sm text-[#E92215]">
                {errors.name.message}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="uniqueNumber" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Unique Number<span className="text-[#E92215]">*</span>
              {/* <span className="text-[#E92215]">*</span> */}
            </label>

            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="text"
              placeholder="Enter Unique Number"
              value={lottarynumber}
              disabled={true}
            />
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="agentId" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Agent ID & User Name<span className="text-[#E92215]">*</span>
              {/* <span className="text-[#E92215]">*</span> */}
            </label>
            <Select label="select"
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] placeholder:text-[#D9D9D9] rounded-md"
              value={''}
              // defaultValue={""}
              onChange={(value) => setAgentId(value)}
            >
              {
                agentsData.map((data, index) => {
                  return (
                    <Option key={index} value={data.agentId}>
                      {data.agentId} - {data.FirstName} {data.LastName}
                    </Option>
                  )
                })
              }
            </Select>
            {/* <Controller
                     name="userId"
                     control={control}
                     defaultValue=""
                     rules={{ required: "This field is required" }}
                     render={({ field, fieldState }) => {
                        if (!field) {
                           return null;
                        }
                        return (
                           <Select
                              {...field}
                              label="select"
                              // value={agentId}
                              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] placeholder:text-[#D9D9D9] rounded-md"
                           >
                              {
                                 agentsData.map((data, index) => {
                                    return (
                                       <Option key={index} value={data.agentId}>
                                          {data.agentId} - {data.FirstName} {data.LastName}
                                       </Option>
                                    )
                                 })
                              }
                           </Select>
                        );
                     }}
                  /> */}
            {errors.userId && (
              <div className="text-sm text-[#E92215]">
                {errors.userId.message}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="totalTickets" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Total Number Of Tickets<span className="text-[#E92215]">*</span>
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="number"
              placeholder="Eg: 500"
              {...register("totalTickets", {
                required: "*This field is required.",
              })}
            />
            {errors.totalTickets && (
              <div className="text-sm text-[#E92215]">
                {errors.totalTickets.message}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="pricePerTicket" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Price Per Each Ticket<span className="text-[#E92215]">*</span>
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="number"
              placeholder="Eg: 300"
              {...register("pricePerTicket", {
                required: "*This field is required.",
              })}
            />
            {errors.pricePerTicket && (
              <div className="text-sm text-[#E92215]">
                {errors.pricePerTicket.message}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="totalWinningAmount" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Total Winning Amount<span className="text-[#E92215]">*</span>
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="text"
              placeholder="Eg: 50,000"
              {...register("totalWinningAmount", {
                required: "*This field is required.",
              })}
            />
            {errors.totalWinningAmount && (
              <div className="text-sm text-[#E92215]">
                {errors.totalWinningAmount.message}
              </div>
            )}
          </div>
          {/* <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="numOfWinners" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Number Of Winners<span className="text-[#E92215]">*</span>
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="number"
              placeholder="Eg: 2"
              onChange={(e) => {
                submitWinnerSlot(e.target.value)
              }}
            // value={"2"}
            // readOnly
            />
          </div> */}

          {/* {
            Array.from({ length: winnerSlotsdata }).map((data, index) => (

              <div className="flex flex-col gap-[0.5rem]">
                <label htmlFor="winner1" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                  Winner {index + 1}<span className="text-[#E92215]">*</span>
                </label>
                <input
                  className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                  type="text"
                  placeholder="Eg: 30,000"
                  value={inputValues[index]?.amount}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                />
              </div>
            ))} */}


          {/* <div className="flex flex-col gap-[0.5rem]">
                        <label htmlFor="winner2" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                            Winner 2
                        </label>
                        <input
                            className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                            type="text"
                            placeholder="Eg: 20,000"
                            // value={"20,000"}
                            // readOnly
                        />
                    </div> */}
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="expectedDateOfDraw" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Expected Date Of Draw<span className="text-[#E92215]">*</span>
            </label>
            <Flatpickr
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              placeholder="Select Expected Date"
              options={dateOptions}
              onChange={(selectedDate) => handleDateChange(selectedDate)}
            />
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="termsAndConditions " className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Terms & Conditions<span className="text-[#E92215]">*</span>
            </label>
            <textarea
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="text"
              rows={4}
              placeholder="Description goes here"
              {...register("termsAndConditions", {
                required: "*This field is required.",
              })}
            />
            {errors.termsAndConditions && (
              <div className="text-sm text-[#E92215]">
                {errors.termsAndConditions.message}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="profilePicture" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Profile Picture
            </label>
            <div className="flex flex-col items-center py-[2rem] px-[1rem] border-2 border-dashed
                         border-[#D9D9D9] rounded-lg">
              {selectImg?.name ? (
                <>
                  <h2 className="text-[1.1rem] font-semibold text-green-600 my-[1rem]">
                    Uploaded document
                  </h2>
                  <div className="flex gap-[1rem] items-center">
                    <h2 className="text-[1.1rem] text-black font-[500]">
                      {selectImg?.name.length > 30
                        ? selectImg.name.substring(0, 30) + "..."
                        : selectImg.name}
                    </h2>
                    <Icon
                      onClick={() => setSelectImg(null)}
                      icon="akar-icons:cross"
                      className="text-[1.2rem] cursor-pointer hover:bg-[#3D4A5C]/[0.3]"
                    />
                  </div>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() =>
                      document.querySelector(".input-field").click()
                    }
                  >
                    <Icon
                      icon="material-symbols:cloud-upload"
                      className="text-[#D9D9D9] text-[4rem] mb-[0.5rem]"
                    />
                  </button>
                  <input
                    type="file"
                    // accept="image/*"
                    value=""
                    className="input-field"
                    hidden
                    onChange={({ target: { files } }) => {
                      if (files[0]) {
                        setSelectImg(files[0]);
                      }
                    }}
                  />
                  <p className="text-[0.9rem] text-[#D9D9D9]">
                    Upload jpg, png, pdf. Size up to 20MB
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex gap-[2rem] items-center justify-end">
          <button onClick={() => navigate(-1)}
            className="text-[0.9rem] sm:text-[1.2rem] font-semibold text-primary bg-white 
                        border border-primary py-[0.4rem] w-[10rem] sm:w-[12rem] rounded-[8px]"
            type="button"
          >
            Back
          </button>

          {loaderBtn ? (
            <ThreeDots
              height="50"
              width="50"
              radius="9"
              color="#9FB947"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) :
            (<button
              className="text-[0.9rem] sm:text-[1.2rem] font-semibold text-white bg-primary py-[0.5rem] px-[1rem] rounded-[8px]"
              type="submit"
            >
              Create Private Lottery
            </button>)}

        </div>
      </form>
      <ToastContainer />
    </div>
  )
}
