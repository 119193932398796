import { Icon } from "@iconify/react";
import Lotteries from "../adminModule/Reports/Lotteries";
import LotterySummary from "../adminModule/Reports/LotterySummary/LotterySummary";
import PrivateLotteries from "../adminModule/Reports/PrivateLotteries";
import OrderList from "../adminModule/Reports/OrderList";
import Users from "../adminModule/Reports/Users/Users";
import CustomerReports from "../adminModule/Reports/CustomerReports/CustomerReports";
import { useQuery } from "react-query";
import { APIurls } from "../../api/apiConstant";
import { useAuth } from "../../store/AuthContext";
import { RotatingLines } from "react-loader-spinner";

export default function AdminPanelReports() {

  const { getAccessToken, userRole } = useAuth();

  const fetchLotteryData = async () => {
    const token = await getAccessToken()
    const response = await fetch(APIurls.fetchLottary, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (!response.ok) {
      throw new Error("Failed to Fetch Lottery Data");
    }
    const result = await response.json();
    console.log("Lottary Data Dashboard...", result?.response)
    return result?.response;
  };
  const { data: lotteryData, isLoading } = useQuery('reportLotteries', fetchLotteryData);

  if (isLoading) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <RotatingLines
          strokeColor="green"
          strokeWidth="5"
          animationDuration="0.75"
          width="100"
          visible={true}
        />
      </div>
    )
  }

  return (
    <div className='lg:m-5 md:mt-30 sm:mt-20 m-3'>
      <h1 className="flex items-center gap-[1rem] text-primary text-[1.2rem] sm:text-[1.7rem] font-semibold 
    mb-[30px]">
        <Icon icon="mdi:report-arc" className="text-[2rem]" />
        Reports
      </h1>
      <Lotteries data={lotteryData} />
      <LotterySummary data={lotteryData} />
      {/* <PrivateLotteries></PrivateLotteries> */}
      <OrderList></OrderList>
      <Users></Users>
      {/* <CustomerReports></CustomerReports> */}
    </div>
  )
}
