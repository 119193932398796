import React, { useEffect, useState } from 'react'
import HeaderStepper from '../../../../helpers/HeaderStepper';
import { Icon } from '@iconify/react';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../../store/AuthContext';
import { APIurls } from "../../../../api/apiConstant";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import EditPersonalDetails from './EditPersonalDetails';
import EditBankDetails from './EditBankDetails';
import EditKYCDetails from './EditKYCDetails';
import { updateEditBankDetails, updateEditLoaderBtn, updateEditPersonalDetails, updateIsEditPage } from '../../../../store/editWalletAgentSlice';
import _ from 'lodash';

export default function EditPrivateAgent() {
  const [currentStep, setCurrentStep] = useState(1);
  const [steps, setSteps] = useState(["Personal Details", "Bank Details", "KYC Details"]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { getAccessToken } = useAuth();

  const { pathname } = useLocation();

  useEffect(() => {
    const newPath = pathname.split("/");
    if (_.includes(newPath, "edit")) {
      dispatch(updateIsEditPage(true));
    } else {
      dispatch(updateIsEditPage(false));
    }
  }, [pathname]);

  const {
    firstName,
    lastName,
    email,
    mobileNumber,
    whatsAppNumber,
    telegramNumber,
    nationality,
    passportNumber,
    address,
    country,
    state,
    city,
    pincode,
  } = useSelector((store) => store.editWalletAgent.editPersonalDetails)
  const {
    accountNumber,
    ifscCode,
    accountHolderName,
    bankName,
    bankCity,
    branch,
  } = useSelector((store) => store.editWalletAgent.editBankDetails);

  const fetchWalletAgentDataById = async () => {
    const token = await getAccessToken()
    try {
      const response = await fetch(`${APIurls.fetchWalletAgentById}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        throw new Error("Error Fetching Wallet Agent Data");
      }
      const result = await response.json();
      console.log("Edit Wallet Agent", result?.response?.agentDetails);
      const {
        FirstName, LastName, email, location: { address, city, country, pincode, state }, mobileNumber, whatsApp, bankDetails: { acc_branch, acc_city, acc_ifsc, acc_name, acc_number }
      } = result?.response?.agentDetails;
      dispatch(updateEditPersonalDetails({
        firstName: FirstName,
        lastName: LastName,
        email: email,
        mobileNumber: mobileNumber,
        whatsAppNumber: whatsApp,
        address: address,
        city: city,
        state: state,
        pincode: pincode,
        country: country,
      }))
      dispatch(updateEditBankDetails({
        accountNumber: acc_number,
        ifscCode: acc_ifsc,
        accountHolderName: acc_name,
        // bankName: "",
        bankCity: acc_city,
        branch: acc_branch,
        // status: "",
      }))
    } catch (error) {
      console.warn("Error Fetching Wallet Agent Data");
      toast.error("Error Fetching Wallet Agent Data");
    }
  };

  useEffect(() => {
    fetchWalletAgentDataById();
  }, [])

  const displayStep = () => {
    switch (currentStep) {
      case 1: return <EditPersonalDetails currentStep={currentStep} handleClick={handleClick} steps={steps} />;
      case 2: return <EditBankDetails currentStep={currentStep} handleClick={handleClick} steps={steps} />;
      case 3: return <EditKYCDetails currentStep={currentStep} handleClick={handleClick} steps={steps} />;
      default: break;
    }
  }

  const handleClick = async (direction) => {
    let newStep = currentStep;
    if (direction === "next") {
      newStep++;
    } else if (direction === "back") {
      newStep--;
    } else if (direction === "create") {
      editWalletAgentHandler();
    }

    if (newStep > 0 && newStep <= steps.length) {
      setCurrentStep(newStep);
    }
  }

  async function editWalletAgentHandler() {
    const token = await getAccessToken()
    try {
      dispatch(updateEditLoaderBtn(true));
      const response = await fetch(`${APIurls.editWalletAgent}/${id}`, {
        method: "PUT",
        body: JSON.stringify({
          "email": email,
          "firstName": firstName,
          "lastName": lastName,
          "mobileNumber": mobileNumber,
          "whatsApp": whatsAppNumber,
          "dob": "",
          "location": {
            "country": country,
            "state": state,
            "pincode": pincode,
            "city": city,
            "address": address,
          },
          "bankDetails": {
            "acc_name": accountHolderName,
            "acc_number": accountNumber,
            "acc_ifsc": ifscCode,
            "acc_city": bankCity,
            "acc_branch": branch,
          },
          "kyc_details": {
            "kyc_details": false,
            "aadharDetails": true,
            "panDetails": false,
          },

        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      })
      if (!response.ok) {
        dispatch(updateEditLoaderBtn(false));
        if (response.status === 400) {
          // Handle 400 status code error
          const errorData = await response.json();
          console.log("Error Data...", errorData);
          // You can perform specific actions for a 400 status code here
          // For example, display a specific error message to the user
          toast.error(errorData.message);
          throw new Error(errorData.message);
        } else {
          throw new Error("Failed to Edit Wallet Agent Details");
        }
      }
      const result = await response.json();
      console.log("Edit Agent Result...", result);
      toast.success("Wallet Agent Details Updated Successfully!")
      dispatch(updateEditLoaderBtn(false));
      // clear the input field data from redux store.
      dispatch(updateEditPersonalDetails({
        firstName: "",
        lastName: "",
        email: "",
        mobileNumber: "",
        whatsAppNumber: "",
        telegramNumber: "",
        nationality: "",
        passportNumber: "",
        country: "",
        state: "",
        city: "",
        pincode: "",
        address: "",
      }))
      dispatch(updateEditBankDetails({
        accountNumber: "",
        ifscCode: "",
        accountHolderName: "",
        bankName: "",
        bankCity: "",
        branch: "",
        status: "",
      }))
      navigate("/admin/wallet-agents")
    } catch (error) {
      console.log(error.response);
      toast.error("Failed to Edit Agent Details");
    }
  }

  return (
    <div className='flex flex-col gap-[2rem] mt-[1rem] mb-[3rem]'>
      <div className="text-primary flex items-center gap-[1rem] px-[2rem]">
        <Icon icon="heroicons-solid:user-group" className="text-[2rem]" />
        <h1 className="text-[1.2rem] sm:text-[1.8rem] font-semibold">
          Edit Agent
        </h1>
      </div>
      <div>
        <HeaderStepper steps={steps} currentStep={currentStep} />
      </div>
      <div>
        {displayStep()}
      </div>
      <ToastContainer />
    </div>
  )
}

