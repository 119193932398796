import React from 'react'
import { Icon } from '@iconify/react';
import winnerLogo from '../../assets/lottery-winner-logo.svg'
import winnerTrophy from '../../assets/winner_trophy.svg'
import { formatToIndianNumber } from '../../helpers/numberConversion';
import { Link, Navigate } from 'react-router-dom';

export default function LotteryViewWinner({ data }) {
  console.log("LIst of winners =========> ", data)
  return (
    <div className="py-[1rem] w-auto"
    // style={{ backgroundImage: `url(${winnerBg})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: "60%", backgroundPositionY: "50px"}}
    >
      <div className='flex justify-between items-center'>
        <h1 className="text-[1.1rem] sm:text-[1.4rem] font-semibold">
          Winners
        </h1>
        <div className='flex items-center gap-[1rem]'>
          <button
            className="flex items-center gap-[0.5rem] text-[0.8rem] sm:text-[1rem] font-[500] text-white bg-primary py-[0.4rem] px-[1rem] rounded-md"
          >
            <Icon icon="ic:round-notifications-active" className='text-[1.3rem]' />
            Send Notifications
          </button>
          <button
            className="flex items-center gap-[0.5rem] text-[0.8rem] sm:text-[1rem] font-[500] text-white bg-primary py-[0.4rem] px-[1rem] rounded-md"
          >
            <Icon icon="tabler:download" className='text-[1.3rem]' />
            Export
          </button>
        </div>
      </div>
      <div className='flex flex-col items-center justify-center gap-[0.5rem]'>
        <div className='p-[1rem] bg-[#E0E0E0] w-auto rounded-full'>
          <img src={winnerLogo} alt="" />
        </div>
        <h1 className='text-[1.5rem] font-bold mt-[1rem]'>Winners</h1>
        <p className='text-[#858585]'>
          Here is the <strong>{data?.winnerSlot}</strong> winner list of this lottery.
        </p>
        <div className='flex gap-14 mt-[2rem] justify-center flex-wrap'>
          {
            data?.winnerSlotObject?.map((winnerData, i) => {
              return (
                <div key={i} className='text-white w-[410px] h-[240px] rounded-[14px] px-6 py-3 flex flex-col gap-4'
                  style={{ background: "linear-gradient(180deg, #A967FF 0%, #5500C3 100%)" }}
                >
                  <div className='flex items-center justify-between border-b border-[#F0F4F9]'>
                    <div className='flex gap-6 items-center'>
                      <label htmlFor="winner"
                        className='h-10 w-10 rounded-full font-medium flex items-center justify-center'
                        style={{ background: "linear-gradient(180deg, #A967FF 0%, #5500C3 100%)" }}
                      >
                        #{i + 1}
                      </label>
                      <h2 className='text-[20px] font-semibold'>
                        {i === 0 ? "First Winner" : i === 1 ? "Second Winner" : i === 2 ? "Third Winner" : i === 3 ? "Fourth Winner" : "Fifth Winner"}
                      </h2>
                    </div>
                    <img src={winnerTrophy} alt="trophy" />
                  </div>
                  <div className='flex items-start justify-between'>
                    <div className='flex items-center gap-4'>
                      <img src={winnerData?.UserData?.imageUrl} alt="pic" className='h-[56px] w-[56px] rounded-full' />
                      <div className='flex flex-col'>
                        <h2 className='font-semibold'>
                          {winnerData?.UserData?.FirstName + " " + winnerData?.UserData?.LastName}
                        </h2>
                        <h5 className='text-[13px] font-normal'>
                          {winnerData?.UserData?.email}
                        </h5>
                      </div>
                    </div>
                    <Link to={`/admin/users/view/${winnerData?.usersId?.customerId}`}
                      className='text-[11px] font-medium px-2 py-[2px] rounded-md mt-2'
                      style={{ background: "rgba(255, 255, 255, 0.10)" }}
                    >
                      View Profile
                    </Link>
                  </div>
                  <h1 className='text-[28px] font-[900] text-center'>
                    <i>{data.Currency + " " + formatToIndianNumber(winnerData.Cashprice)}</i>
                  </h1>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}
