import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";

const data = [
  {name:'Users', value: 920},
  {name:'Sold', value: 755},
  {name:'Pending', value: 550},
];


const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ( { cx, cy, midAngle, innerRadius, outerRadius, percent, payload }) => {

  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" className="text-[10px]">
      {/* {payload.name === "Users" ? `Users ${(percent * 100).toFixed(0)}%` : ''} */}
      {payload.name === "Users" ? (
        <>
          Users 
          <tspan x={x} dy="1.2em" className="text-[14px]">
          {(percent * 100).toFixed(0)}%
          </tspan>
        </>
      ) : ''}
    </text>
  );
};

const renderLegend = (props) => {
  const { payload } = props;
  return (
    <ul className="flex items-center justify-center gap-12 mt-2">
      {
        payload.map((entry, index) => (
          <div key={index} className="flex items-center gap-2">
            <p style={{width:'10px', height:'10px', background:`${entry.color}`, borderRadius:'50%'}}></p>
            <li className="text-[14px] text-[#464748]" key={`item-${index}`}>{entry.value}</li>
          </div>
        ))
      }
    </ul>
  );
}

const COLORS = ['#25BF17', '#E0170B', '#FFCE05'];
export default function PieChartReport() {
  return (
    <div className='rounded-lg border-gray-300 border-[1px] lg:w-2/6 w-full h-[450px]'>
      <header className='flex justify-between items-center mb-8 p-4'>
      <h3 className='text-[1rem] font-semibold m-0'>Reports</h3>
      <div>
        <button className='bg-[#25BF17] text-white px-2 py-[2px] text-[14px]'>Day</button>
        <button className='px-2 py-[2px] text-[14px]'>Week</button>
        <button className='px-2 py-[2px] text-[14px]'>Month</button>
      </div>
      </header>


      {/* Chart  */}
      <ResponsiveContainer className="mt-[-40px]" width="100%" height="70%">
        <PieChart width={300} height={300}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Legend verticalAlign="bottom" height={36} iconType='circle' content={renderLegend}></Legend>
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}

