import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import {
  Button,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import moment from "moment";
import CustomCheckbox from "../../../helpers/CustomCheckbox";


// Expandable Component 
const ExpandableComponent = ({data})=>{
  console.log('Dynamic data for expandable component===> ', data)
  return (<div
     style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
     className="grid grid-cols-3 gap-5 rounded-[12px] border-[1px] border-gray-300 p-5 shadow-lg m-1"
   >
     <div>
       <h3 className="text-[#8B8D97] text-[14px]">Total Number Of Tickets</h3>
       <p className="font-medium">500</p>
     </div>
     <div>
       <h3 className="text-[#8B8D97] text-[14px]">Price Per Each Ticket</h3>
       <p className="font-medium">300</p>
     </div>
     <div>
       <h3 className="text-[#8B8D97] text-[14px]">Total Winning Amount</h3>
       <p className="font-medium">50,000</p>
     </div>
     <div>
       <h3 className="text-[#8B8D97] text-[14px]">Number Of Winners</h3>
       <p className="font-medium">200</p>
     </div>
     <div>
       <h3 className="text-[#8B8D97] text-[14px]">Winner 1</h3>
       <p className="font-medium">25,000</p>
     </div>
     <div>
       <h3 className="text-[#8B8D97] text-[14px]">Winner 2</h3>
       <p className="font-medium">25,000</p>
     </div>
   </div>)
}

// Custom cell component
const CustomToggleCell = ({ row, onClick }) => {
  const [expand, setExpand] = useState(false)
  return (<div 
     className="flex space-x-2"
     data-tag="allowRowEvents"
     onClick={()=>{
        onClick();
        setExpand(!expand)
     }}
     >
       <IconButton
         className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656]"
         style={{
           borderRadius: "5.55px",
           border: "0.925px solid #D9D9D9",
           background: "#FFF",
         }}
       >
         {/* <Icon icon="icon-park-outline:down" className="text-[1.5rem]" /> */}
         {expand ? <Icon className="text-[1.5rem]" icon="solar:alt-arrow-up-outline" /> : <Icon className="text-[1.5rem]" icon="solar:alt-arrow-down-outline" />}
       </IconButton>
     </div>)
};



const customStyles = {
  headRow: {
    style: {
      fontWeight: 600,
      padding: "10px 10px",
      color: "#212529",
    },
  },
  head: {
    style: {
      // borderRadius: "6px 6px 0px 0px",
      borderTop: "0.824px solid #E9E9E9",
      background: "#FFF",
      fontSize: "15px",
    },
  },

  rows: {
    style: {
      // borderRadius: "6px 6px 0px 0px",
      // borderTop: "0.824px solid #E9E9E9",
      background: "#FFF",
      color: "#565656",
      padding: "10px 10px",
      fontSize: "14px",
      fontWeight: 400,
      width: "auto",
    },
  },
  pagination: {
    style: {
      boxShadow: "10px 5px 5px #ddd",
      marginBottom: "5px",
    },
  },
  table: {
    style: {
      overflow: "visible",
      minWidth: "1300px",
    },
  },
  tableWrapper: {
    style: {
      overflow: "visible",
    },
  },
  responsiveWrapper: {
    style: {
      overflowX: "auto", // table scroll on x axis
    },
  },
};

const data = [
  {
    id:"1",
    uniqueNumber: "Billal hossain",
    numberGameType: "01/09/2024",
    totalWinners: 5,
    expectedDate: "2024-10-15",
    status: "Completed",
  },
  {
    id:"2",
    uniqueNumber: "Billal hossain",
    numberGameType: "01/09/2024",
    totalWinners: 12,
    expectedDate: "2024-09-30",
    status: "Ongoing",
  },
  {
    id:"3",
    uniqueNumber: "Billal hossain",
    numberGameType: "01/09/2024",
    totalWinners: 3,
    expectedDate: "2024-11-01",
    status: "Completed",
  },
];

const columns = [
  {
    name: "SL",
    selector: (row, index) => index + 1,
    maxWidth: "0.5rem",
  },
  {
    name: "Customer Name",
    id: "customerName",
    selector: (row) => row.uniqueNumber,
    sortable: true,
    grow: 1,
  },
  {
    name: "Date Of Purchase",
    id: "purchaseDate",
    selector: (row) => row.numberGameType,
    sortable: true,
    grow: 1,
  },
  {
    name: "Price",
    id: "price",
    selector: (row) => row.totalWinners,
    sortable: true,
    grow: 1,
  },
  {
    name: "Payment Status",
    id: "paymentStatus",
    cell: (row) => (
      <div
        className={`flex items-center gap-3 rounded-[10px] px-4 py-2 ${
          row.status.toLowerCase() == "completed"
            ? "bg-[#25BF1733] text-primary"
            : "bg-[#FFCE0633] text-[#FFCE06]"
        }`}
      >
        <p
          className={`w-[8px] h-[8px] rounded-full ${
            row.status.toLowerCase() == "completed"
              ? "bg-primary"
              : "bg-[#FFCE06]"
          }`}
        ></p>
        <p>{row.status}</p>
      </div>
    ),
    sortable: true,
    grow: 1,
  },
  {
    name: "Actions",
    id: "action",
    cell: (row) => (<CustomToggleCell onClick={() => row.handleClick()}/>),
    center: true,
    grow: 1,
  }
];

export default function NumberGameUsersList() {
  const [searchKey, setSearchKey] = useState("");
  const [expandedRows, setExpandedRows] = useState([]);
  //Event Handlers
  const handleRowExpand = (row) => {
    setExpandedRows(prev =>{
      return prev.includes(row.leadId) ? prev.filter(id => id != row.leadId) : [...prev, row.leadId]
    });
  };
  return (
    <section>
      <header className="flex flex-col max-xs:items-start xs:flex-row gap-[0.5rem] sm:gap-[1rem] justify-between items-center pb-[1rem]">
        {/* Visible from Medium Screen */}
        <h1 className="text-[1.2rem] font-semibold">Customer Orders</h1>
        <div className="flex gap-[1rem] items-center">
          <div
            className="max-md:hidden flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[300px] lg:w-[350px]"
            style={{
              border: "0.6px solid #DFDFDF",
              borderRadius: "6px",
            }}
          >
            <input
              type="text"
              className="flex-1 bg-transparent  placeholder-[#848484] outline-none "
              placeholder="Search here..."
              value={searchKey}
              onChange={(e) => {
                setSearchKey(e.target.value);
              }}
            />
            {!searchKey ? (
              <button
                type="button"
                className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md"
              >
                <Icon icon="akar-icons:search" className="text-white" />
              </button>
            ) : (
              <button
                onClick={() => setSearchKey("")}
                className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md"
              >
                <Icon icon="akar-icons:cross" className="text-white" />
              </button>
            )}
          </div>

          <div className="text-gray-500">Filter</div>

          <div className="flex flex-row gap-[0.5rem] xs:gap-[1rem] items-center justify-center py-[0.2rem]">
            <div className="">
              <Menu>
                <MenuHandler>
                  <Button className="text-[0.8rem] sm:text-[1rem] flex w-full flex-row justify-center whitespace-nowrap capitalize text-white bg-primary px-[0.4rem] xs:px-[1rem] py-[0.5rem] rounded-md items-center gap-[0.5rem] sm:gap-[1rem] hover:shadow-white shadow-white outline-none">
                    <Icon
                      icon="material-symbols:download"
                      className="text-[1rem] w-[1.2rem] sm:w-[1.5rem] h-auto"
                    />
                    <span className="font-[500]">Export</span>
                  </Button>
                </MenuHandler>
                <MenuList>
                  {/* <PDFDownloadLink document={<DownloadPDF data={data} filteredLotteries={true} />}> */}
                  <MenuItem className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]">
                    {" "}
                    <Icon
                      icon="fa-solid:file-pdf"
                      className="text-[#BE0A23] text-[1.3rem]"
                    />
                    Download as PDF
                  </MenuItem>
                  {/* </PDFDownloadLink> */}
                  <hr className="my-[0.1rem]" />
                  <MenuItem
                    //  onClick={downloadCSV}
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                  >
                    <Icon
                      icon="fa-solid:file-csv"
                      className="text-[#29672E] text-[1.3rem]"
                    />
                    Download as CSV
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      </header>

      <div className="bg-white">
        {/* <DataTable
          columns={columns}
          data={data}
          customStyles={customStyles}
          pagination
          progressPending={false}
          selectableRows
          selectableRowsComponent={CustomCheckbox}
        /> */}
        <DataTable
          columns={columns}
         data={data.map(item => ({
            ...item,
            handleClick: () => handleRowExpand(item), // Attach expand function
          }))}
          customStyles={customStyles}
          pagination
          // progressPending={"isLoading"}
          selectableRows
          selectableRowsComponent={CustomCheckbox}

          expandableRowsHideExpander
          expandableRowExpanded={row => expandedRows.includes(row.leadId)}
          expandableRows={true}
          expandableRowsComponent={({ data }) => <ExpandableComponent data={data}/>}
        />
      </div>
    </section>
  );
}

