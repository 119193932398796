import { Icon } from "@iconify/react/dist/iconify.js";
import numberGameViewImage from "../../../assets/numberGameViewPng.png";

export default function NumberGameViewCards() {
  return (
    <section className="grid md:grid-cols-7 col-span-1 gap-5 my-[3rem]">
      {/* item-1 */}
      <div className="col-span-1">
        <img
          className="h-full w-full"
          src={numberGameViewImage}
          alt="Number Game View"
        />
      </div>
      {/* item-2 */}
      <div
        style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        className="p-[1rem] md:col-span-3 rounded-[12px]"
      >
        <div className="flex flex-wrap md:items-center justify-between md:gap-0 gap-2">
          <div className="flex items-center gap-3">
            <div className="text-[1.5rem] text-primary bg-[#25BF171A] w-[2.5rem] h-[2.5rem] rounded-[7px] flex justify-center items-center">
              <Icon icon="game-icons:ticket" />
            </div>

            <div>
              <h3 className="font-semibold text-[1.25rem]">
                Easy5(A123GD5HGN)
              </h3>
              <p className="text-[14px]">
                <span className="text-[#8B8D97]">Created on</span>
                <span> 12 Sept 2023</span>
              </p>
            </div>
          </div>
          <div className="bg-[#25BF171A] w-[120px] text-primary rounded-[7px] px-5 py-2 flex justify-between items-center gap-5">
            <p className="bg-primary h-[8px] w-[8px] rounded-full"></p>
            <p>Active</p>
          </div>
        </div>
        <div className="text-[14px] mt-[1.5rem]">
          <p className="text-[#8B8D97]">Date Of Draw</p>
          <div className="flex items-center gap-1 font-medium">
            <Icon
              className="text-[1.5rem]"
              icon="material-symbols:date-range-outline"
            />
            <p>03 December 2023</p>
          </div>
        </div>
      </div>
      {/* item-3 */}
      <div
        style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        className="flex items-center flex-wrap gap-8 p-[1rem] md:col-span-3 rounded-[12px]"
      >
        <div className="text-[14px]">
          <p className="text-[#8B8D97]">Price Per Each Ticket</p>
          <div className="flex items-center gap-2 font-medium">
            <Icon className="text-[1rem]" icon="twemoji:flag-india" />
            <p>INR</p>
            <Icon className="text-gray-500" icon="ri:arrow-down-s-line" />
            <p>300</p>
          </div>
        </div>

        <div>
          <p className="text-[#8B8D97] text-[14px]">Number Of Winners</p>
          <p className="text-[1rem] font-medium">3</p>
        </div>

        <div>
          <p className="text-[#8B8D97] text-[14px]">Match 5</p>
          <p className="text-[1rem] font-medium">50,000</p>
        </div>
        <div>
          <p className="text-[#8B8D97] text-[14px]">Match 4</p>
          <p className="text-[1rem] font-medium">50,000</p>
        </div>
        <div>
          <p className="text-[#8B8D97] text-[14px]">Match 3</p>
          <p className="text-[1rem] font-medium">50,000</p>
        </div>
      </div>
    </section>
  );
}
