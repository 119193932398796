import React, { PureComponent, useEffect, useState } from 'react';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Icon } from '@iconify/react';
import { APIurls } from '../../../../api/apiConstant';
import { useAuth } from '../../../../store/AuthContext';
import { toast } from 'react-toastify';
const data = [
  { month: 'January', usersJoined: 50 },
  { month: 'February', usersJoined: 65 },
  { month: 'March', usersJoined: 80 },
  { month: 'April', usersJoined: 45 },
  { month: 'May', usersJoined: 90 },
  { month: 'June', usersJoined: 75 },
  { month: 'July', usersJoined: 60 },
  { month: 'August', usersJoined: 85 },
  { month: 'September', usersJoined: 70 },
  { month: 'October', usersJoined: 55 },
  { month: 'November', usersJoined: 100 },
  { month: 'December', usersJoined: 120 },
];


const renderTooltip = ({ active, label, payload }) => {
  if (active && payload?.length) {
    return (
      <div className='bg-[#000019] rounded-lg px-2 py-1 font-bold text-[10px] w-[68px] h-[35px] text-white'>
        <p>{label}</p>
        <div className='flex items-center gap-1'>
          <Icon icon="material-symbols-light:circle" className="text-[#25BF17] w-2 h-2" />
          <p className='text-[10px]'>{payload[0]?.value}</p>
        </div>
      </div>
    )
  }

  return null;
}

export default function JoinedUsers() {
  const [dateType, setDateType] = useState("MONTH");
  const [loaderBtn, setLoaderBtn] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const { getAccessToken,userRole } = useAuth();

  const fetchUserCountHandler = async () => {
    setLoaderBtn(true);
    const token = await getAccessToken()
    try {
      const response = await fetch(`${APIurls.fetchUsersCount}/${dateType}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        throw new Error("Error fetching Users Count");
      }
      const result = await response.json();
      console.log("User Count", result?.response?.usersCount);
      setUsersData(result?.response?.usersCount);
    } catch (error) {
      console.log("Error fetching Users Count");
      toast.error("Error fetching Users Count");
    }
    setLoaderBtn(false);
  }

  useEffect(() => {
      fetchUserCountHandler();
  }, [dateType])

  return (
    <div className='p-4 rounded-lg border-gray-300 border w-full h-[550px]'>
      <header className='flex justify-between items-center pb-10'>
        <h3 className='text-[24px] font-medium'>Users Joined</h3>
        <div>
          <button onClick={() => setDateType("WEEK")}
            className={`${dateType === "WEEK" && "bg-[#25BF17] text-white font-medium"} px-3 py-[2px]`}>
            Week
          </button>
          <button onClick={() => setDateType("MONTH")}
            className={`${dateType === "MONTH" && "bg-[#25BF17] text-white font-medium"} px-3 py-[2px]`}>
            Month
          </button>
          <button onClick={() => setDateType("YEAR")}
            className={`${dateType === "YEAR" && "bg-[#25BF17] text-white font-medium"} px-3 py-[2px]`}>
            Year
          </button>
        </div>
      </header>

      {/* chart  */}
      <ResponsiveContainer className="pb-20" width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={usersData}
        >
          <XAxis dataKey={dateType === "WEEK" ? "day" : dateType === "MONTH" ? "month" : "year"} tick={{ fontSize: 11, color: 'black', fontWeight: '600' }} interval={0} />
          <YAxis tick={{ fontSize: 14, color: 'black', fontWeight: '600' }} />
          <Bar
            dataKey="count"
            fill='#D3F2D1'
            barSize={20}
            activeBar={<Rectangle fill='#25BF17' />}
          />
          <Tooltip cursor={{ fill: 'transparent' }} content={renderTooltip} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}
