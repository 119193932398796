import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editPersonalDetails: {
        firstName: "",
        lastName: "",
        email: "",
        mobileNumber: "",
        whatsAppNumber: "",
        telegramNumber: "",
        nationality: "",
        passportNumber: "",
        country: "",
        state: "",
        city: "",
        pincode: "",
    },
    editBankDetails: {
        accountNumber: "",
        ifscCode: "",
        accountHolderName: "",
        bankName: "",
        bankCity: "",
        branch: "",
        status: "",
    },
    editLoaderBtn: false,
    isAgentEditPage: false,
}

const editWalletAgentSlice = createSlice({
    name: "editWalletAgent",
    initialState: initialState,
    reducers: {
        updateEditPersonalDetails(state, action) {
            state.editPersonalDetails = { ...state.editPersonalDetails, ...action.payload }
        },
        updateEditBankDetails(state, action) {
            state.editBankDetails = { ...state.editBankDetails, ...action.payload }
        },
        updateEditLoaderBtn(state, action) {
            state.editLoaderBtn = action.payload;
        },
        updateIsEditPage(state, action) {
            state.isAgentEditPage = action.payload;
        }
    }
})

export const {
    updateEditPersonalDetails, updateEditBankDetails, updateEditLoaderBtn, updateIsEditPage
} = editWalletAgentSlice.actions;

export default editWalletAgentSlice.reducer;