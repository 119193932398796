import React from "react";
import ArchiveLotteries from "./ArchiveLotteries";
import ArchivePrivateLotteries from "./ArchivePrivateLotteries";

export default function AdminArchiveLotteries() {
  return (
    <div>
      <ArchiveLotteries />
      <ArchivePrivateLotteries />
    </div>
  );
}
