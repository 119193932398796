import React from "react";
import DataTable from "react-data-table-component";
import { useAuth } from "../../../../store/AuthContext";
import { APIurls } from "../../../../api/apiConstant";
import { useQuery } from "react-query";
import { Icon } from "@iconify/react";

export default function TopUsers() {
  const { getAccessToken, userRole } = useAuth();

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        padding: "10px 10px",
        color: "#212529",
      },
    },
    head: {
      style: {
        borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        fontSize: "15px",
      },
    },

    rows: {
      style: {
        background: "#FFF",
        color: "#565656",
        padding: "10px 10px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    pagination: {
      style: {
        // boxShadow: "10px 5px 5px #ddd",
        marginBottom: "5px",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1000px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto",
      },
    },
  };
  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      minWidth: "150px",
      maxWidth: "150px"
    },
    {
      name: "User Name",
      id: "userName",
      selector: (row) => row.FirstName + " " + row.LastName,
      center: true,
      grow: 1,
      icon: "solar:sort-outline",
    },
    {
      name: "Total No.of Tickets",
      id: "lotteryName",
      selector: (row) => row.ticketPurchase?.length,
      center: true,
      grow: 1,
      icon: "solar:sort-outline",
    },
  ];

  const fetchUsersData = async () => {
    const token = await getAccessToken()
    const response = await fetch(APIurls.fetchUsers, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (!response.ok) {
      throw new Error("Failed to Fetch Users Data");
    }
    const result = await response.json();
    console.log("DASHBOARD USER'S DATA", result?.response?.Users);
    return result?.response?.Users.sort((a, b) => b.ticketPurchase.length - a.ticketPurchase.length);
  };

  const { data: usersData, isLoading } = useQuery('dashboardUsers', fetchUsersData);

  return (
    <div className='rounded-lg border-gray-300 border w-full'>
      <header className='flex justify-between items-center mb-6 p-4'>
        <div>
          <h3 className='text-[24px] font-medium m-0'>Top Users</h3>
          <p className='m-0 text-[12px]'>(Tickets)</p>
        </div>
        <div>
          <button className='bg-[#25BF17] text-white px-2 py-[2px]'>Day</button>
          <button className='px-2 py-[2px]'>Week</button>
          <button className='px-2 py-[2px]'>Month</button>
        </div>
      </header>

      <div className="bg-white">
        <DataTable
          columns={columns.map((column) => ({
            ...column,
            name: (
              <div className="flex items-center justify-between">
                <span>{column.name}</span>
                {column.icon && <Icon icon={column.icon} className="text-gray-600 ml-1" />}
              </div>
            ),
          }))}
          data={usersData}
          customStyles={customStyles}
          pagination
          progressPending={isLoading}
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10, 15, 20]}
        />
      </div>
    </div>
  )
}
