import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react';
import { Option, Select } from '@material-tailwind/react';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';
import { useAuth } from '../../store/AuthContext';
import { APIurls } from '../../api/apiConstant';
import { IconButton } from 'rsuite';

export default function UserSpecificNotificationsModal({ setShowModal, userIds, handleClearRows }) {
  const [selectImg, setSelectImg] = useState(null);
  const [loaderBtn, setLoaderBtn] = useState(false);
  const [showError, setShowError] = useState(false);
  const [notificationType, setNotificationType] = useState("");
  const [customLink, setCustomLink] = useState("");
  const [lotteryData, setLotteryData] = useState([]);
  const [lotteryId, setLotteryId] = useState("");
  const [offersData, setOffersData] = useState([]);
  const [offerId, setOfferId] = useState("");
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const { getAccessToken } = useAuth();

  const fetchLotteryData = async () => {
    const token = await getAccessToken()
    try {
      const response = await fetch(APIurls.fetchLottary, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        toast.error("Failed to Lottery Data");
        throw new Error("Failed to Fetch Lottery Data");
      }
      const result = await response.json();
      console.log("Lottary Data", result?.response?.Lottary);
      setLotteryData(result?.response?.Lottary);
    } catch (error) {
      console.log("Failed to Fetch Lottery Data")
    }
  };
  const fetchOffersData = async () => {
    const token = await getAccessToken()
    try {
      const response = await fetch(APIurls.fetchOffers, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        toast.error("Failed to Fetch Offers Data");
        throw new Error("Failed to Fetch Offers Data");
      }
      const result = await response.json();
      console.log("Offers Data", result?.response?.PrivLottary);
      setOffersData(result?.response?.PrivLottary);
    } catch (error) {
      console.log("Failed to Fetch Offers Data")
    }
  };
  useEffect(() => {
    fetchLotteryData();
    fetchOffersData();
  }, [])

  const onSubmit = async (data) => {
    if (!(notificationType &&
      (notificationType !== "Custom Link" || customLink !== "") &&
      (notificationType !== "Lottery" || lotteryId !== "") &&
      (notificationType !== "Offers" || offerId !== ""))) {
      setShowError(true);
      return;
    } else {
      setShowError(false);
    }
    const { title, description } = data;

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("userIds", JSON.stringify(userIds));
    formData.append("NotificationImg", selectImg);
    formData.append("notificationType", notificationType);
    formData.append("customLink", notificationType === "Custom Link" ? customLink : notificationType === "Lottery" ? lotteryId : offerId);

    const token = await getAccessToken();
    const requestOptions = {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      }
    };

    try {
      setLoaderBtn(true)
      const response = await fetch(APIurls.sendNotificationToSpecificUsers, requestOptions);

      if (!response.ok) {
        setLoaderBtn(false)
        if (response.status === 400) {
          // Handle 400 Bad Request error
          const result = await response.json();
          console.error("Error Sending Notification:", result.message);
          console.log(result);
          toast.error(result?.message)
        } else {
          console.log(response.status)
          throw new Error("Error Sending Notification");
        }
      } else {
        setLoaderBtn(false)
        const result = await response.json();
        toast.success("Successfully Sent Notification!");
        console.log(result);
        reset();
        setSelectImg("");
        setNotificationType("");
        setLotteryId("");
        setOfferId("");
        handleClearRows();
        setShowModal(false);
      }
    } catch (error) {
      setLoaderBtn(false)
      toast.error("Error creating Notification");
      console.warn(error);
    }
  }

  return (
    <div className="flex items-center justify-center absolute bg-[#000000E6] h-full w-full inset-0 z-10">
      <div className="flex flex-col gap-[30px] bg-white p-6 min-w-[60%] rounded-2xl">
        <div className="flex flex-col gap-6 font-[500]">
          <div className="flex gap-24 items-center justify-between">
            <h1 className="text-[#212529] text-[24px] font-bold">
              Send Notifications to Specific Users
            </h1>
            <IconButton
              onClick={() => setShowModal(false)}
              className="shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] rounded-md"
            >
              <Icon icon="material-symbols:close" className="text-[1.6rem]" />
            </IconButton>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}
            className="bg-white flex flex-col gap-[1rem] px-[2rem] rounded-lg">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[2rem] gap-y-[1rem]">
              <div className='flex flex-col gap-[1.2rem]'>
                <div className="flex flex-col gap-[0.5rem]">
                  <label htmlFor="title" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                    Title
                  </label>
                  <input
                    className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                    type="text"
                    placeholder="Enter Title"
                    {...register("title", {
                      required: "*This field is required.",
                    })}
                  />
                  {errors.title && (
                    <div className="text-sm text-[#E92215]">
                      {errors.title.message}
                    </div>
                  )}
                </div>
                <div className="flex flex-col gap-[0.5rem]">
                  <label htmlFor="description" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                    Description
                  </label>
                  <textarea rows={4}
                    className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
                    type="text"
                    placeholder="Description goes here..."
                    {...register("description", {
                      required: "*This field is required.",
                    })}
                  />
                  {errors.description && (
                    <div className="text-sm text-[#E92215]">
                      {errors.description.message}
                    </div>
                  )}
                </div>
                <div className="flex flex-col gap-[0.5rem]">
                  <label htmlFor="notificationType" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                    Notification Type
                  </label>
                  <Select className='text-black text-[1rem] font-[500]'
                    label='Select'
                    size='lg'
                    color='blue-gray'
                    variant='outlined'
                    value={notificationType}
                    onChange={(value) => setNotificationType(value)}
                  >
                    <Option value='Offers'>Offers</Option>
                    <Option value='Lottery'>Lottery</Option>
                    <Option value='Custom Link'>Custom Link</Option>
                  </Select>
                  {showError && !notificationType && (
                    <p className="text-sm text-[#E92215]">
                      *This field is required.
                    </p>
                  )}
                </div>
                {
                  notificationType === "Custom Link" && (
                    <div className="flex flex-col gap-[0.5rem]">
                      <label htmlFor="title" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                        Custom Link
                      </label>
                      <input
                        className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none resize-none"
                        type="text"
                        placeholder="Paste Link here..."
                        value={customLink}
                        onChange={(e) => setCustomLink(e.target.value)}
                      />
                      {showError && !customLink && (
                        <div className="text-sm text-[#E92215]">
                          *This Field is Required
                        </div>
                      )}
                    </div>
                  )
                }
                {
                  notificationType === "Lottery" && (
                    <div className="flex flex-col gap-[0.5rem]">
                      {
                        lotteryData?.length > 0 ? (
                          <>
                            <Select label='Select Lottery' color='blue-gray' size='lg' variant='outlined'
                              className='text-black text-[1rem] font-[500]'
                              value={lotteryId}
                              onChange={(value) => setLotteryId(value)}
                            >
                              {
                                lotteryData.map((lottery) => {
                                  return (
                                    <Option key={lottery._id} value={lottery._id}>
                                      {lottery.Name}
                                    </Option>
                                  )
                                })
                              }
                            </Select>
                          </>
                        ) : (
                          <Select label='Select Lottery'>
                            <Option value=" ">" "</Option>
                          </Select>
                        )
                      }
                      {showError && !lotteryId && (
                        <div className="text-sm text-[#E92215]">
                          *This Field is Required
                        </div>
                      )}
                    </div>
                  )
                }
                {
                  notificationType === "Offers" && (
                    <div className="flex flex-col gap-[0.5rem]">
                      {
                        offersData?.length > 0 ? (
                          <>
                            <Select label='Select Offer' color='blue-gray' size='lg' variant='outlined'
                              className='text-black text-[1rem] font-[500]'
                              value={offerId}
                              onChange={(value) => setOfferId(value)}
                            >
                              {
                                offersData.map((offer) => {
                                  return (
                                    <Option key={offer._id} value={offer._id}>
                                      {offer.title}
                                    </Option>
                                  )
                                })
                              }
                            </Select>
                          </>
                        ) : (
                          <Select label='Select Offer'>
                            <Option value=" ">" "</Option>
                          </Select>
                        )
                      }
                      {showError && !offerId && (
                        <div className="text-sm text-[#E92215]">
                          *This Field is Required
                        </div>
                      )}
                    </div>
                  )
                }
              </div>
              <div className="flex flex-col gap-[0.5rem]">
                <label htmlFor="profilePicture" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
                  Upload Image
                </label>
                <div className="flex flex-col items-center py-[2rem] px-[1rem] border-2 border-dashed
                         border-[#D9D9D9] rounded-lg">
                  {selectImg?.name ? (
                    <>
                      <h2 className="text-[1.1rem] font-semibold text-green-600 my-[1rem]">
                        Uploaded document
                      </h2>
                      <div className="flex gap-[1rem] items-center">
                        <h2 className="text-[1.1rem] text-black font-[500]">
                          {selectImg?.name.length > 30
                            ? selectImg.name.substring(0, 30) + "..."
                            : selectImg.name}
                        </h2>
                        <Icon
                          onClick={() => setSelectImg(null)}
                          icon="akar-icons:cross"
                          className="text-[1.2rem] cursor-pointer hover:bg-[#3D4A5C]/[0.3]"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        onClick={() =>
                          document.querySelector(".input-field").click()
                        }
                      >
                        <Icon
                          icon="material-symbols:cloud-upload"
                          className="text-[#D9D9D9] text-[4rem] mb-[0.5rem]"
                        />
                      </button>
                      <input
                        type="file"
                        // accept="image/*"
                        value=""
                        className="input-field"
                        hidden
                        onChange={({ target: { files } }) => {
                          if (files[0]) {
                            setSelectImg(files[0]);
                          }
                        }}
                      />
                      <p className="text-[0.9rem] text-[#D9D9D9]">
                        Upload jpg, png, pdf. Size up to 20MB
                      </p>
                    </>
                  )}
                </div>
                {showError && !selectImg && (
                  <p className="text-sm text-[#E92215]">
                    *This field is required.
                  </p>
                )}
              </div>
            </div>
            <div className="flex items-center justify-end mt-[1rem]">
              {loaderBtn ? (
                <ThreeDots
                  height="50"
                  width="50"
                  radius="9"
                  color="#9FB947"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) :
                (<button
                  className="text-[1.2rem] font-semibold text-white bg-primary py-[0.5rem] px-[1rem] rounded-[8px]"
                  type="submit"
                >
                  Send Notification
                </button>)}
            </div>
          </form>
          <ToastContainer />
        </div>
      </div>
    </div>
  )
}

