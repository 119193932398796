import React from 'react'
import { useOutletContext } from 'react-router-dom'
import LotteryViewWinner from './LotteryViewWinner';
import LotteryViewWinnerAnnounce from './LotteryViewWinnerAnnounce';

export default function LotteryViewWinnerContainer() {
  const [mongoId, lotteryData, setIsRefetch] = useOutletContext();
  console.log("View Winners of lottery =============================> ", lotteryData)

  return (
    <div>
      {
        lotteryData?.winnerAnnounceStatus ? (
          <LotteryViewWinner data={lotteryData}/>
        ) : (
          <LotteryViewWinnerAnnounce mongoId={mongoId} setIsRefetch={setIsRefetch}/>
        )
      }
    </div>
  )
}
