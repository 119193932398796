import { configureStore } from "@reduxjs/toolkit";
import createAgentSlice from "./createAgentSlice";
import notificationSlice from "./notificationSlice";
import editAgentSlice from "./editAgentSlice";
import UserDataSlice from "./UserDataSlice";
import createWalletAgentSlice from "./createWalletAgentSlice";
import editWalletAgentSlice from "./editWalletAgentSlice";


const store = configureStore({
    reducer: {
        createAgent: createAgentSlice,
        notification: notificationSlice,
        editAgent: editAgentSlice,
        createWalletAgent: createWalletAgentSlice,
        editWalletAgent: editWalletAgentSlice,
        userData: UserDataSlice,
    }
})

export default store;