import React, { useState, useRef, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Icon } from "@iconify/react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
// import 'flatpickr/dist/flatpickr.min.css';
import {
  IconButton,
  Option,
  Select,
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import CustomCheckbox from "../../../helpers/CustomCheckbox";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { useQuery } from 'react-query';
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";
import { toast } from "react-toastify";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DownloadPDF from "../../../exportData/Export PDF";
import { RotatingLines, ThreeDots } from "react-loader-spinner";

export default function AgentsList() {
  const [status, setStatus] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});
  const [showSubActions, setShowSubActions] = useState({
    show: false,
    uniqueId: "",
  });
  const flatpickrRef = useRef();

  const { getAccessToken, userRole } = useAuth();

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        padding: "10px 10px",
        color: "#212529",
      },
    },
    head: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        fontSize: "15px",
      },
    },

    rows: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        color: "#565656",
        padding: "10px 10px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    pagination: {
      style: {
        boxShadow: "10px 5px 5px #ddd",
        marginBottom: "5px",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1400px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };
  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      maxWidth: "0.5rem",
    },
    {
      name: "Agent Name",
      id: "agentName",
      selector: (row) => row.FirstName + " " + row.LastName,
      center: true,
      grow: 2,
    },
    {
      name: "Country",
      id: "country",
      selector: (row) => row?.location?.country,
      center: true,
      grow: 2,
    },
    {
      name: "Currency",
      id: "currency",
      selector: (row) => "INR",
      center: true,
      grow: 2,
    },
    {
      name: "Balance",
      id: "balance",
      selector: (row) => row.wallet,
      center: true,
      grow: 2,
    },
    {
      name: "Registered Date",
      id: "expectedDate",
      selector: (row) => moment(row.createdAt).format("DD MMMM YYYY"),
      center: true,
      grow: 3,
    },
    {
      name: "Account Status",
      id: "accountStatus",
      selector: (row) => (
        <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
        py-[0.5rem] w-[9rem] ${row.status === "ACTIVE" ? "text-[#25BF17] bg-[#D1F0CF]" : row.status === "SUSPENDED" ? "text-[#FF0023] bg-[#FDCAD1]" : "text-[#FFCE06] bg-[#FDF9EF]"}`}>
          <span className={`${row.status === "ACTIVE" ? "bg-[#25BF17]" : row.status === "SUSPENDED" ? "bg-[#FF0023]" : "bg-[#FFCE06]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
          <h2>{row.status}</h2>
        </div>
      ),
      center: true,
      grow: 3,
    },
    {
      name: "Actions",
      id: "action",
      cell: (row) => (
        <div className="flex space-x-2">
          <Link to={`view/${row.agentId}`}
          >
            <IconButton
              className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656]"
              style={{
                borderRadius: "5.55px",
                border: "0.925px solid #D9D9D9",
                background: "#FFF",
              }}
            >
              <Icon icon="mdi:eye" className="text-[1.5rem]" />
            </IconButton>
          </Link>
          {
            (userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("EDIT_LOTTERY_AGENT")) && (
              <Link to={`edit/${row.agentId}`}>
                <IconButton
                  className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656]"
                  style={{
                    borderRadius: "5.55px",
                    border: "0.925px solid #D9D9D9",
                    background: "#FFF",
                  }}
                >
                  <Icon
                    icon="material-symbols:edit-rounded"
                    className="text-[1.5rem]"
                  />
                </IconButton>
              </Link>
            )
          }
          {
            (userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("UPDATE_LOTTERY_AGENT_ACCOUNT_STATUS")) && (
              <div className="flex flex-col gap-1">
                <IconButton onClick={() => subActionsHandler(row.agentId)}
                  className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656]"
                  style={{
                    borderRadius: "5.55px",
                    border: "0.925px solid #D9D9D9",
                    background: "#FFF",
                  }}
                >
                  <Icon
                    icon="majesticons:creditcard-plus"
                    className="text-[1.5rem]"
                  />
                </IconButton>
                {
                  showSubActions[row.agentId] && (
                    <div className="flex items-center gap-2">
                      {
                        loadingStates[row.agentId] && loadingStates["active"] ? (
                          <ThreeDots
                            height="50"
                            width="50"
                            radius="9"
                            color="#25BF17"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        ) : (
                          <IconButton size="sm" onClick={() => updateLotteryAgentStatusHandler(row.agentId, "active")}
                            className={`${row.status === "ACTIVE" && "hidden"} shadow-none hover:shadow-none focus:shadow-none text-[#25BF17] bg-[#E8F7E6]`}
                            style={{
                              borderRadius: "6px",
                              border: "1px solid #25BF17",
                            }}
                          >
                            <Icon icon="mdi:tick" className="text-[1.5rem]" />
                          </IconButton>
                        )
                      }
                      {
                        loadingStates[row.agentId] && loadingStates["kyc_pending"] ? (
                          <ThreeDots
                            height="50"
                            width="50"
                            radius="9"
                            color="#25BF17"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        ) : (
                          <IconButton size="sm" onClick={() => updateLotteryAgentStatusHandler(row.agentId, "kyc_pending")}
                            className={`${row.status === "KYC_PENDING" && "hidden"} shadow-none hover:shadow-none focus:shadow-none text-[#FFCE06] bg-[#FDF9EF]`}
                            style={{
                              borderRadius: "6px",
                              border: "1px solid #FFCE06",
                            }}
                          >
                            <Icon icon="formkit:time"
                              className="text-[1.5rem]"
                            />
                          </IconButton>
                        )
                      }
                      {
                        loadingStates[row.agentId] && loadingStates["suspended"] ? (
                          <ThreeDots
                            height="50"
                            width="50"
                            radius="9"
                            color="#25BF17"
                            ariaLabel="three-dots-loading" f
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        ) : (
                          <IconButton size="sm" onClick={() => updateLotteryAgentStatusHandler(row.agentId, "suspended")}
                            className={`${row.status === "SUSPENDED" && "hidden"} shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8]`}
                            style={{
                              borderRadius: "6px",
                              border: "1px solid #FF0023",
                            }}
                          >
                            <Icon
                              icon="material-symbols:close"
                              className="text-[1.5rem]"
                            />
                          </IconButton>
                        )
                      }
                    </div>
                  )
                }
              </div>
            )
          }
        </div>
      ),
      // center: true,
      grow: 3,
    },
  ];

  const subActionsHandler = (agentId) => {
    setShowSubActions((prevState) => {
      if (prevState[agentId]) {
        return {
          ...prevState,
          [agentId]: false,
        }
      } else {
        return {
          ...prevState,
          [agentId]: true,
        }
      }
    });
  }

  const updateLotteryAgentStatusHandler = async (agentId, status) => {
    const token = await getAccessToken()
    try {
      setLoadingStates((prevState) => {
        return {
          ...prevState,
          [agentId]: true,
          [status]: true,
        }
      })
      const response = await fetch(`${APIurls.updateLotteryAgentStatus}/${agentId}`, {
        method: 'PUT',
        body: JSON.stringify({
          "status": status
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      })
      if (!response.ok) {
        throw new Error("Failed to Update Lottery Agent Status");
      }
      const result = await response.json();
      console.log(result);
      if (status === "active") {
        toast.success("Status changed to Active Successfully");
      } else if (status === "kyc_pending") {
        toast.success("Status changed to KYC Pending Successfully");
      } else {
        toast.success("Status changed to Suspended Successfully");
      }
      refetch();
      setShowSubActions((prevState) => {
        return {
          ...prevState,
          [agentId]: false,
        }
      })
    } catch (error) {
      console.warn("Failed to Update Lottery Agent Status");
      toast.error("Failed to Update Lottery Agent Status");
    }
    setLoadingStates((prevState) => {
      return {
        ...prevState,
        [agentId]: false,
        [status]: false,
      }
    })
  }

  const disableAgentHandler = async (agentId) => {
    setLoadingStates((prevState) => ({
      ...prevState,
      [agentId]: true,
    }))
    const token = await getAccessToken()
    try {
      const response = await fetch(`${APIurls.disableAgent}/${agentId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      if (!response.ok) {
        throw new Error("Failed to Update Agent Status");
      }
      const result = await response.json();
      console.log(result);
      toast.success("Agent Status Updated Successfully");
      refetch();  // to trigger lottery data for updated data
    } catch (error) {
      console.warn("Failed to Update Agent Status")
      toast.error("Failed to Update Agent Status")
    }
    setLoadingStates((prevState) => ({
      ...prevState,
      [agentId]: false,
    }))
  }

  const fetchAgentData = async () => {
    const token = await getAccessToken()

    const response = await fetch(APIurls.fetchAgent, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (!response.ok) {
      throw new Error("Failed to Fetch Agents Data");
    }
    const result = await response.json();

    console.log("AGENTS DATA LIST...", result?.response?.Agents);
    return result?.response?.Agents.reverse();
  };
  const { data: agentData, isLoading, refetch, isError, error } = useQuery('agents', fetchAgentData);

  let activeAgents = agentData?.filter((agent) => agent?.status.toLowerCase() === "active");
  let inactiveAgents = agentData?.length - activeAgents?.length;

  const handleDateChange = (selectedDates) => {
    console.log(selectedDates.length);

    if (selectedDates.length === 1) {
      setStartDate(selectedDates[0]);
      console.log("Actual Start Date", selectedDates[0]);
      console.log("Start Date", selectedDates[0].getTime())
    }
    if (selectedDates.length === 2) {
      setEndDate(selectedDates[1]);
      console.log("Actual End Date", selectedDates[1]);
      console.log("End Date", selectedDates[1].getTime());
    }
  };

  const dateOptions = {
    mode: "range",
    dateFormat: "d-m-Y",
  };
  const clearDatesHandler = () => {
    setStartDate(null);
    setEndDate(null);
    flatpickrRef.current.flatpickr.clear();  // only clear's the date from input field
  }

  useEffect(() => {
    let mapData = agentData?.map((item, index) => {
      return { ...item };
    });
    if (searchKey !== "") {
      mapData = mapData.filter((item) => {
        const agentName = item.FirstName + " " + item.LastName;
        return agentName?.toLowerCase().includes(searchKey.toLowerCase());
      });
    }
    if (startDate && endDate) {
      const dStart = new Date(startDate);
      dStart.setUTCHours(0, 0, 0, 0);
      const dEnd = new Date(endDate);
      dEnd.setUTCHours(23, 59, 59, 999);
      mapData = mapData.filter((item) => {
        const itemDate = new Date(item.createdAt);
        return (
          itemDate.getTime() >= dStart.getTime() && itemDate.getTime() < dEnd.getTime()
        );
      });
    }
    if (status !== "") {
      if (status === "ALL") {
        setFilteredItems(mapData);
      } else {
        mapData = mapData.filter((item) => item.status === status);
      }
    }
    setFilteredItems(mapData);
  }, [searchKey, startDate, endDate, agentData, status]);

  if (isError) {
    return (
      <div>
        {toast.error(error.message)}
        <h1 className='text-[2rem] font-bold flex items-center justify-center h-screen'>
          Failed to Fetch Lottery Agents Data List...!
        </h1>
      </div>
    )
  }
  if (isLoading) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <RotatingLines
          strokeColor="green"
          strokeWidth="5"
          animationDuration="0.75"
          width="100"
          visible={true}
        />
      </div>
    )
  }

  return (
    <div className="py-[1rem]">
      <div className="flex flex-col md:flex-row md:items-center justify-between my-[0.5rem] px-[1rem]">
        <h1 className="text-[1.2rem] sm:text-[1.4rem] font-semibold whitespace-nowrap">
          Lottery Agents
        </h1>
        <div className="">
          {
            (userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("CREATE_LOTTERY_AGENT")) && (
              <Link to={"create"}>
                <button className="text-[0.8rem] sm:text-[1rem] flex items-center justify-center max-xs:w-full  gap-[0.3rem] xs:gap-[0.5rem] bg-primary text-white py-[0.5rem] px-[0.4rem] xs:px-[1rem] rounded-md">
                  <Icon
                    icon="material-symbols:add"
                    className="text-[1.2rem] sm:text-[1.5rem]"
                  />
                  <span className="whitespace-nowrap">Create Lottery Agent</span>
                </button>
              </Link>
            )
          }
        </div>
      </div>
      {/* visible till Medium screen */}
      <div
        className="md:hidden flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[full] my-[1rem]"
        style={{
          border: "0.533975px solid #DFDFDF",
          borderRadius: "6px",
        }}
      >
        <div className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md ">
          <Icon
            icon="iconamoon:search-bold"
            className="text-[#272727] max-sm:text-[0.9rem]"
          />
        </div>
        <input
          type="text"
          className="text-[0.8rem] sm:text-[1rem] flex-1 bg-transparent placeholder-[#848484] outline-none "
          placeholder="Search here..."
          value={searchKey}
          onChange={(e) => {
            setSearchKey(e.target.value);
          }}
        />
      </div>
      <div className="grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-6 my-7 px-4">
        {/* badge -1: Total Agents  */}
        <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <div>
            <header className='flex justify-between'>
              <h3 className='text-[14px] text-[#858585]'>Total Agents</h3>
              <Icon className='text-[#25BF17] text-[1.9rem]' icon="heroicons-solid:user-group" />
            </header>
            <h3 className='text-[#25BF17] text-[26px] font-bold'>
              {agentData?.length !== "undefined" ? agentData?.length : "NA"}
            </h3>
          </div>
          <div>
            <div className='mb-3'><hr /></div>
            <footer className='flex justify-between items-center gap-1'>
              <div className='flex items-center gap-1'>
                <Icon className='text-[#198754] w-3 h-3' icon="mdi:arrow-up-thin" />
                <small className='text-[#198754] text-[10px]'>3.05%</small>
                <small className='text-[9px] text-[#8898AA]'>Since last month</small>
              </div>
            </footer>
          </div>
        </div>
        {/* badge -2: Active Agents   */}
        <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <div>
            <header className='flex justify-between'>
              <h3 className='text-[14px] text-[#858585]'>Active</h3>
              <Icon icon="ooui:user-active" className="text-primary text-[1.8rem]" />
            </header>
            <h3 className='text-[#25BF17] text-[26px] font-bold'>
              {activeAgents?.length !== "undefined" ? activeAgents?.length : "NA"}
            </h3>
          </div>
          <div>
            <div className='mb-3'><hr /></div>
            <footer className='flex justify-between items-center gap-1'>
              <div className='flex items-center gap-1'>
                <Icon className='text-[#198754] w-3 h-3' icon="mdi:arrow-up-thin" />
                <small className='text-[#198754] text-[10px]'>3.05%</small>
                <small className='text-[9px] text-[#8898AA]'>Since last month</small>
              </div>
            </footer>
          </div>
        </div>
        {/* badge -3: Inactive Agents  */}
        <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <div>
            <header className='flex justify-between'>
              <h3 className='text-[14px] text-[#858585]'>Inactive</h3>
              <Icon className='text-[#25BF17] text-[1.6rem]' icon="grommet-icons:in-progress" />
            </header>
            <h3 className='text-[#25BF17] text-[26px] font-bold mb-5'>
              {inactiveAgents !== "undefined" ? inactiveAgents : "NA"}
            </h3>
          </div>
          <div>
            <div className='mb-3'><hr /></div>
            <footer className='flex justify-between items-center gap-1'>
              <div className='flex items-center gap-1'>
                <Icon className='text-[#198754] w-3 h-3' icon="mdi:arrow-up-thin" />
                <small className='text-[#198754] text-[10px]'>3.05%</small>
                <small className='text-[9px] text-[#8898AA]'>Since last month</small>
              </div>
            </footer>
          </div>
        </div>
        {/* badge -4: Total Commission  */}
        <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <div>
            <header className='flex justify-between items-center'>
              <h3 className='text-[14px] text-[#858585] mb-2'>Total Commission</h3>
            </header>
            <div className="flex justify-between items-center">
              <h3 className='text-[#25BF17] text-[26px] font-bold'>₹30,000.00</h3>
              <div className="flex items-center gap-2">
                <Icon icon="twemoji:flag-india" />
                <span className="text-[11px]">INR</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                  <path d="M9.25 4.5L6.25 7.5L3.25 4.5" stroke="#858585" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </div>
          </div>
          <div>
            <div className='mb-3'><hr /></div>
            <footer className='flex justify-between items-center gap-2'>
              <div className='flex items-center gap-1'>
                <Icon className='text-[#198754] w-3 h-3' icon="mdi:arrow-up-thin" />
                <small className='text-[#198754] text-[10px]'>3.05%</small>
                <small className='text-[9px] text-[#8898AA]'>Since last month</small>
              </div>
              <button className='text-[10px] text-[#25BF17] font-medium'>View Report</button>
            </footer>
          </div>
        </div>

      </div>
      <div className="flex flex-col max-xs:items-start xs:flex-row gap-[0.5rem] sm:gap-[1rem] justify-between items-center my-[1rem] xl:mx-[1rem]">
        {/* Visible from Medium Screen */}
        <h1 className="text-[1.2rem] font-semibold">
          List
        </h1>
        <div className="flex gap-[1rem] items-center px-[1rem]">
          <div
            className="max-md:hidden flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[300px] lg:w-[350px]"
            style={{
              border: "0.533975px solid #DFDFDF",
              borderRadius: "6px",
            }}
          >
            <input
              type="text"
              className="flex-1 bg-transparent  placeholder-[#848484] outline-none "
              placeholder="Search here....."
              value={searchKey}
              onChange={(e) => {
                setSearchKey(e.target.value);
              }}
            />
            {
              !searchKey ? (
                <button type="button"
                  className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:search" className="text-white" />
                </button>
              ) : (
                <button onClick={() => setSearchKey("")}
                  className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:cross" className="text-white" />
                </button>
              )
            }
          </div>
          <div className="max-md:w-full flex items-center gap-[0.5rem] py-[0.5rem] px-[1rem]"
            style={{
              border: "0.6px solid #DFDFDF",
              borderRadius: "6px",
            }}
          >
            <Flatpickr
              ref={flatpickrRef}
              className="text-[0.8rem] sm:text-[1rem] bg-transparent placeholder-[#848484] outline-none"
              placeholder="Select Date"
              options={dateOptions}
              onChange={(selectedDate) => handleDateChange(selectedDate)}
            />
            {
              !startDate || !endDate ? (
                <button type="button"
                  className="flex items-center cursor-pointer bg-primary py-[0.25rem] px-[0.2rem] rounded-md">
                  <Icon icon="solar:calendar-outline" className="text-white" />
                </button>
              ) : (
                <button onClick={clearDatesHandler}
                  className="flex items-center cursor-pointer bg-primary py-[0.25rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:cross" className="text-white" />
                </button>
              )
            }
          </div>
          <div className="">
            <Select label="Select Status"
              className="text-[0.8rem] sm:text-[1rem] bg-transparent"
              value={status}
              onChange={(value) => setStatus(value)}
            >
              <Option value="ACTIVE">ACTIVE</Option>
              <Option value="SUSPENDED">SUSPENDED</Option>
              <Option value="KYC_PENDING">KYC_PENDING</Option>
              <Option value="ALL">ALL</Option>
            </Select>
          </div>
          <div className="flex flex-row gap-[0.5rem] xs:gap-[1rem] items-center justify-center py-[0.2rem]">
            <div className="">
              <Menu>
                <MenuHandler>
                  <Button className="text-[0.8rem] sm:text-[1rem] flex w-full flex-row justify-center whitespace-nowrap capitalize text-white bg-primary px-[0.4rem] xs:px-[1rem] py-[0.5rem] rounded-md items-center gap-[0.5rem] sm:gap-[1rem] hover:shadow-white shadow-white outline-none">
                    <Icon
                      icon="material-symbols:download"
                      className="text-[1rem] w-[1.2rem] sm:w-[1.5rem] h-auto"
                    />
                    <span className="font-[500]">Export</span>
                  </Button>
                </MenuHandler>
                <MenuList>
                  <PDFDownloadLink document={<DownloadPDF data={filteredItems} filteredLotAgentList={true} />}>
                    <MenuItem
                      className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                    >
                      {" "}
                      <Icon
                        icon="bxs:file-pdf"
                        className="text-[#BE0A23] w-[1.6rem] h-auto"
                      />
                      Download as PDF
                    </MenuItem>
                  </PDFDownloadLink>
                  <hr className="my-[0.2rem] sm:my-[0.5rem]" />
                  <MenuItem
                    //  onClick={downloadCSV}
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.9rem]"
                  >
                    <Icon
                      icon="fa-solid:file-csv"
                      className="text-[#29672E] w-[1.1rem] h-auto"
                    />
                    Download as CSV
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white px-[1rem]">
        <DataTable
          columns={columns}
          data={filteredItems}
          customStyles={customStyles}
          pagination
          progressPending={isLoading}
          selectableRows
          selectableRowsComponent={CustomCheckbox}
        />
      </div>
    </div>
  );
}

