import React, { useState, useRef, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Icon } from "@iconify/react";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import {
  IconButton,
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import CustomCheckbox from "../../../helpers/CustomCheckbox";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { APIurls } from "../../../api/apiConstant";
import { useQuery } from "react-query";
import { useAuth } from "../../../store/AuthContext";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";

export default function OffersList({ updateOffersList, setUpdateOffersList }) {
  const [searchKey, setSearchKey] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});
  const flatpickrRef = useRef();
  const { getAccessToken, userRole } = useAuth();
  const { id } = useParams();

  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        padding: "10px 10px",
        color: "#212529",
      },
    },
    head: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        borderTop: "0.824px solid #E9E9E9",
        // border: "0.824px solid #E9E9E9", 
        background: "#FFF",
        fontSize: "15px",
      },
    },

    rows: {
      style: {
        // borderRadius: "6px 6px 0px 0px",
        // borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        color: "#565656",
        padding: "10px 10px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    pagination: {
      style: {
        boxShadow: "10px 5px 5px #ddd",
        marginBottom: "5px",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1600px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto", // table scroll on x axis
      },
    },
  };
  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      minWidth: "60px",
      maxWidth: "60px",
    },
    {
      name: "Type of Offer",
      id: "typeOfOffer",
      selector: (row) => row.type_offer,
      center: true,
      grow: 2,
    },
    {
      name: "Offer Type",
      id: "offerType",
      selector: (row) => (<p>
        {row.offerType} - {row.offerType === "AMOUNT" ? row.discount_amount : row.percentage}
      </p>),
      center: true,
      grow: 2,
    },
    {
      name: "Coupon Code",
      id: "couponCode",
      selector: (row) => row.coupon_code,
      center: true,
      grow: 2,
    },
    {
      name: "Title",
      id: "title",
      selector: (row) => row.title,
      center: true,
      grow: 2.5,
    },
    {
      name: "Total Limit",
      id: "totalLimit",
      selector: (row) => (
        <h2>Limit:- {row.TotalUsage}</h2>
        // <h2>Limit:- 100 <br /> Used:- 10</h2>
      ),
      center: true,
      grow: 1.3,
    },
    {
      name: "User Limit",
      id: "userLimit",
      selector: (row) => row.userLimit,
      center: true,
      grow: 1.3,
    },
    {
      name: "Duration",
      id: "createdAt",
      selector: (row) => (
        <h2>{moment(row.StartDate).format("DD-MM-YYYY")} /<br /> {moment(row.ExpieryDate).format("DD-MM-YYYY")}</h2>
      ),
      center: true,
      grow: 2,
    },
    {
      name: "Status",
      id: "status",
      selector: (row) => (
        <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold w-[8rem] 
        py-[0.6rem] ${row.status === "ENABLED" ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FF0023] bg-[#FDCAD1]"}`}>
          <span className={`${row.status === "ENABLED" ? "bg-[#25BF17]" : "bg-[#FF0023]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
          <h2>{row.status}</h2>
        </div>
      ),
      center: true,
      grow: 2,
    },
    {
      name: "Actions",
      id: "action",
      cell: (row) => (
        <div className="flex space-x-2">
          {
            (userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("EDIT_OFFERS")) && (
              <Link to={`edit/${row._id}`}>
                <IconButton
                  className={`shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656] border border-[#D9D9D9] ${id === row._id && "text-blue-600 cursor-auto border-blue-200"}`}
                  style={{
                    borderRadius: "5.55px",
                    background: "#FFF",
                  }}
                >
                  <Icon
                    icon="material-symbols:edit-rounded"
                    className="text-[1.5rem]"
                  />
                </IconButton>
              </Link>
            )
          }
          {(userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("DELETE_OFFERS")) && (
            loadingStates[row._id] ? (
              <ThreeDots
                height="50"
                width="50"
                radius="9"
                color="#9FB947"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <IconButton onClick={() => deleteOfferHandler(row._id)}
                className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656]"
                style={{
                  borderRadius: "5.55px",
                  border: "0.925px solid #D9D9D9",
                  background: "#FFF",
                }}
              >
                <Icon
                  icon="material-symbols:delete-rounded"
                  className="text-[1.5rem]"
                />
              </IconButton>
            )
          )}
        </div>
      ),
      center: true,
      grow: 1,
    },
  ];

  const deleteOfferHandler = async (id) => {
    // eslint-disable-next-line no-restricted-globals
    let isConfirm = confirm("Are you sure you want to Delete this Offer?");
    if (isConfirm) {
      const token = await getAccessToken()
      try {
        setLoadingStates((prevState) => ({
          ...prevState,
          [id]: true,
        }));
        const response = await fetch(`${APIurls.deleteOffer}/${id}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          }
        })
        if (!response.ok) {
          throw new Error("Failed to Delete Offer");
        }
        const result = await response.json();
        console.log(result);
        toast.success("Offer Deleted Successfully");
        refetch();  // to trigger lottery data for updated data
      } catch (error) {
        console.warn("Failed to Delete Offer");
        toast.error("Failed to Delete Offer");
      }
      setLoadingStates((prevState) => ({
        ...prevState,
        [id]: false,
      }));
    }
  }

  const fetchOffersData = async () => {
    const token = await getAccessToken();

    const response = await fetch(APIurls.fetchOffers, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (!response.ok) {
      throw new Error("Failed to Fetch Banners Data");
    }
    const result = await response.json();

    console.log("Offers........", result?.response?.PrivLottary);
    return result?.response?.PrivLottary.reverse();
  };
  const { data: OffersData, isLoading, refetch } = useQuery('offers', fetchOffersData);

  const clearDatesHandler = () => {
    setStartDate(null);
    setEndDate(null);
    flatpickrRef.current.flatpickr.clear();  // only clear's the date from input field
  }

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 1) {
      setStartDate(selectedDates[0]);
    }
    if (selectedDates.length === 2) {
      setEndDate(selectedDates[1]);
    }
  };
  const dateOptions = {
    mode: "range",
    dateFormat: "d-m-Y",
  };

  if (updateOffersList) {
    refetch();
    setUpdateOffersList(false);
  }

  useEffect(() => {
    let mapData = OffersData?.map((item) => {
      return { ...item };
    });
    if (searchKey !== "") {
      mapData = mapData.filter((item) => {
        return item?.title?.toLowerCase().includes(searchKey.toLowerCase());
      });
    }
    if (startDate && endDate) {
      const dStart = new Date(startDate);
      dStart.setUTCHours(0, 0, 0, 0);
      const dEnd = new Date(endDate);
      dEnd.setUTCHours(23, 59, 59, 999);
      mapData = mapData.filter((item) => {
        const itemDate = new Date(item.StartDate);
        return (
          itemDate.getTime() >= dStart.getTime() && itemDate.getTime() <= dEnd.getTime()
        );
      });
    }
    setFilteredItems(mapData);
  }, [searchKey, startDate, endDate, OffersData]);

  return (
    <div className="px-[1rem] py-[1rem] w-auto">
      {/* visible till Medium screen */}
      <div
        className="md:hidden flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[full] my-[1rem]"
        style={{
          border: "0.6px solid #DFDFDF",
          borderRadius: "6px",
        }}
      >
        <div className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md ">
          <Icon
            icon="iconamoon:search-bold"
            className="text-[#272727] max-sm:text-[0.9rem]"
          />
        </div>
        <input
          type="text"
          className="text-[0.8rem] sm:text-[1rem] flex-1 bg-transparent placeholder-[#848484] outline-none "
          placeholder="Search here..."
          value={searchKey}
          onChange={(e) => {
            setSearchKey(e.target.value);
          }}
        />
      </div>
      <div className="flex flex-col max-xs:items-start xs:flex-row gap-[0.5rem] sm:gap-[1rem] justify-center sm:justify-between items-center px-[1rem] py-[0.5rem] border border-[#D9D9D9] rounded-md mb-[1rem]">
        {/* Visible from Medium Screen */}
        <h1 className="text-[1rem] sm:text-[1.1rem] font-semibold">
          Manage Offers
        </h1>
        <div className="flex gap-[2rem] items-center">
          <div
            className="max-md:hidden flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[300px] lg:w-[350px]"
            style={{
              border: "0.6px solid #DFDFDF",
              borderRadius: "6px",
            }}
          >
            <input
              type="text"
              className="flex-1 bg-transparent  placeholder-[#848484] outline-none "
              placeholder="Search by Title"
              value={searchKey}
              onChange={(e) => {
                setSearchKey(e.target.value);
              }}
            />
            {
              !searchKey ? (
                <button type="button"
                  className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:search" className="text-white" />
                </button>
              ) : (
                <button onClick={() => setSearchKey("")}
                  className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:cross" className="text-white" />
                </button>
              )
            }
          </div>
          <div className="max-md:w-full flex items-center gap-[0.5rem] py-[0.5rem] px-[1rem]"
            style={{
              border: "0.6px solid #DFDFDF",
              borderRadius: "6px",
            }}
          >
            <Flatpickr
              ref={flatpickrRef}
              className="text-[0.8rem] sm:text-[1rem] bg-transparent placeholder-[#848484] outline-none"
              placeholder="Select Date"
              options={dateOptions}
              onChange={(selectedDate) => handleDateChange(selectedDate)}
            />
            {
              !startDate || !endDate ? (
                <button type="button"
                  className="flex items-center cursor-pointer bg-primary py-[0.25rem] px-[0.2rem] rounded-md">
                  <Icon icon="solar:calendar-outline" className="text-white" />
                </button>
              ) : (
                <button onClick={clearDatesHandler}
                  className="flex items-center cursor-pointer bg-primary py-[0.25rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:cross" className="text-white" />
                </button>
              )
            }
          </div>
          <div className="flex flex-row gap-[0.5rem] xs:gap-[1rem] items-center justify-center py-[0.2rem]">
            <div className="">
              <Menu>
                <MenuHandler>
                  <Button className="text-[0.8rem] sm:text-[1rem] flex w-full flex-row justify-center whitespace-nowrap capitalize text-white bg-primary px-[0.4rem] xs:px-[1rem] py-[0.5rem] rounded-md items-center gap-[0.5rem] sm:gap-[1rem] hover:shadow-white shadow-white outline-none">
                    <Icon
                      icon="material-symbols:download"
                      className="text-[1rem] w-[1.2rem] sm:w-[1.5rem] h-auto"
                    />
                    <span className="font-[500]">Export</span>
                  </Button>
                </MenuHandler>
                <MenuList>
                  <MenuItem
                    //  onClick={downloadPdf}
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                  >
                    {" "}
                    <Icon
                      icon="bxs:file-pdf"
                      className="text-[#BE0A23] w-[1.6rem] h-auto"
                    />
                    Download as PDF
                  </MenuItem>
                  <hr className="my-[0.2rem] sm:my-[0.5rem]" />
                  <MenuItem
                    //  onClick={downloadCSV}
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.9rem]"
                  >
                    <Icon
                      icon="fa-solid:file-csv"
                      className="text-[#29672E] w-[1.1rem] h-auto"
                    />
                    Download as CSV
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <DataTable
          columns={columns}
          data={filteredItems}
          customStyles={customStyles}
          pagination
          progressPending={isLoading}
          selectableRows
          selectableRowsComponent={CustomCheckbox}
        />
      </div>
    </div>
  );
}

