import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import { Icon } from "@iconify/react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
// import 'flatpickr/dist/flatpickr.min.css';
import {
  IconButton,
  Option,
  Select,
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import CustomCheckbox from "../../../helpers/CustomCheckbox";
import moment from "moment";
import { useOutletContext } from "react-router-dom";
import WithdrawalViewModal from "./WithdrawalViewModal";

export default function AgentTotalWithdrawals() {
  const [userTotalWithdrawals, isLoading] = useOutletContext();
  const [status, setStatus] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isTextCopied, setIsTextCopied] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const flatpickrRef = useRef();
  const timeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);


  const customStyles = {
    headRow: {
      style: {
        fontWeight: 600,
        padding: "10px 10px",
        color: "#212529",
      },
    },
    head: {
      style: {
        borderTop: "0.824px solid #E9E9E9",
        background: "#FFF",
        fontSize: "15px",
      },
    },

    rows: {
      style: {
        background: "#FFF",
        color: "#565656",
        padding: "10px 10px",
        fontSize: "14px",
        fontWeight: 400,
        width: "auto",
      },
    },
    pagination: {
      style: {
        boxShadow: "10px 5px 5px #ddd",
        marginBottom: "5px",
      },
    },
    table: {
      style: {
        overflow: "visible",
        minWidth: "1400px",
      },
    },
    tableWrapper: {
      style: {
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {
        overflowX: "auto",
      },
    },
  };
  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      center: true,
      minWidth: "70px",
      maxWidth: "70px"
    },
    {
      name: "User Name",
      id: "userName",
      selector: (row) => row.fullName,
      center: true,
      grow: 1.6,
    },
    {
      name: "Crypto wallet address",
      id: "cryptoWalletAddress",
      selector: (row) => (
        <div className="flex gap-3 items-center">
          <span>{row.cryptoWalletAddress}</span>
          <button onClick={() => copyTextHandler(row.cryptoWalletAddress, row._id)}>
            {isTextCopied === row._id ? <span className="text-[#029CFD]">Copied!</span> :
              <Icon icon="material-symbols:content-copy-outline-rounded" className="text-[1.5rem]" />}
          </button>
        </div>
      ),
      // center: true,
      grow: 2.5,
    },
    {
      name: "Withdrawal Date",
      id: "withdrawalDate",
      selector: (row) => moment(row.createdAt).format("DD MMMM YYYY"),
      center: true,
      grow: 1.2,
    },
    {
      name: "Coins",
      id: "coins",
      selector: (row) => row.withdrawalAmt,
      center: true,
      grow: 1,
    },
    {
      name: "Crypto",
      id: "crypto",
      selector: (row) => (
        <div className="flex items-center gap-2">
          <span className="text-[1.5rem]">≈</span>
          <Icon icon="simple-icons:tether" className="text-[1.3rem] text-[#50AF95]" />
          <span className="font-medium">{row.cryptoValue.toFixed(3)}</span>
        </div>
      ),
      center: true,
      grow: 1,
    },
    {
      name: "Accepted Date",
      id: "acceptedDate",
      selector: (row) => moment(row.updatedAt).format("DD MMMM YYYY"),
      center: true,
      grow: 1.2,
    },
    {
      name: "Status",
      id: "status",
      selector: (row) => (
        <div className={`flex justify-center items-center gap-2 px-4 py-2 font-semibold rounded-md
             ${row.status?.toLowerCase() === "approved" ? "text-[#25BF17] bg-[#D1F0CF]" : row.status?.toLowerCase() === "rejected" ? "text-[#FF0023] bg-[#FDCAD1]" : row.status?.toLowerCase() === "hold" ? "text-[#1A202C] bg-[#CFD0D3]" : "text-[#FFCE06] bg-[#f1ece3]"}`}>
          <span className={`${row.status?.toLowerCase() === "approved" ? "bg-[#25BF17]" : row.status?.toLowerCase() === "rejected" ? "bg-[#FF0023]" : row.status?.toLowerCase() === "hold" ? "bg-[#1A202C]" : "bg-[#FFCE06]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
          <h2>{row.status}</h2>
        </div>
      ),
      center: true,
      grow: 1,
    },
  ];

  // const fetchUserTotalWithdrawalData = async () => {
  //   const token = await getAccessToken()

  //   const response = await fetch(APIurls.fetchTotalUserWithdrawals, {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     }
  //   });
  //   if (!response.ok) {
  //     throw new Error("Failed to Fetch User Total Withdrawals");
  //   }
  //   const result = await response.json();

  //   console.log("User Total Withdrawals...", result?.response?.withdrawList);
  //   return result?.response?.withdrawList?.reverse();
  // };
  // const { data: userTotalWithdrawals, isLoading, refetch } = useQuery('userTotalWithdrawalsData', fetchUserTotalWithdrawalData);

  const copyTextHandler = (text, rowId) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setIsTextCopied(rowId);
        timeoutRef.current = setTimeout(() => {
          setIsTextCopied(null);
        }, 1000);
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };

  const handleDateChange = (selectedDates) => {
    console.log(selectedDates[0]?.getTime(), selectedDates[1]?.getTime())
    if (selectedDates.length === 1) {
      setStartDate(selectedDates[0]);
    }
    if (selectedDates.length === 2) {
      setEndDate(selectedDates[1]);
    }
  };

  const dateOptions = {
    mode: "range",
    dateFormat: "d-m-Y",
  };

  useEffect(() => {
    let mapData = userTotalWithdrawals?.map((item) => {
      return { ...item };
    });
    if (searchKey !== "") {
      mapData = mapData.filter((item) => {
        return item?.fullName?.toLowerCase().includes(searchKey.toLowerCase());
      });
    }
    if (startDate && endDate) {
      const dStart = new Date(startDate);
      dStart.setUTCHours(0, 0, 0, 0);
      const dEnd = new Date(endDate);
      dEnd.setUTCHours(23, 59, 59, 999);
      mapData = mapData.filter((item) => {
        const itemDate = new Date(item.createdAt);
        return (
          itemDate.getTime() >= dStart.getTime() && itemDate.getTime() <= dEnd.getTime()
        );
      });
    }
    if (status !== "") {
      if (status === "ALL") {
        setFilteredItems(mapData);
      } else {
        mapData = mapData.filter((item) => item.status === status);
      }
    }
    setFilteredItems(mapData);
  }, [searchKey, startDate, endDate, userTotalWithdrawals, status]);

  const clearDatesHandler = () => {
    setStartDate(null);
    setEndDate(null);
    flatpickrRef.current.flatpickr.clear();
  }

  return (
    <div className="py-[1rem]">
      {/* visible till Medium screen */}
      <div
        className="md:hidden flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[full] my-[1rem]"
        style={{
          border: "0.533975px solid #DFDFDF",
          borderRadius: "6px",
        }}
      >
        <div className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md ">
          <Icon
            icon="iconamoon:search-bold"
            className="text-[#272727] max-sm:text-[0.9rem]"
          />
        </div>
        <input
          type="text"
          className="text-[0.8rem] sm:text-[1rem] flex-1 bg-transparent placeholder-[#848484] outline-none "
          placeholder="Search here..."
          value={searchKey}
          onChange={(e) => {
            setSearchKey(e.target.value);
          }}
        />
      </div>
      <div className="flex flex-col max-xs:items-start xs:flex-row gap-[0.5rem] sm:gap-[1rem] justify-between items-center my-[1rem] xl:mx-[1rem]">
        {/* Visible from Medium Screen */}
        <h1 className="text-[1.2rem] font-semibold">
          List
        </h1>
        <div className="flex gap-[1rem] items-center px-[1rem]">
          <div
            className="max-md:hidden flex flex-row gap-[0.5rem] py-[0.5rem] px-[1rem] w-[300px] lg:w-[350px]"
            style={{
              border: "0.533975px solid #DFDFDF",
              borderRadius: "6px",
            }}
          >
            <input
              type="text"
              className="flex-1 bg-transparent  placeholder-[#848484] outline-none "
              placeholder="Search here..."
              value={searchKey}
              onChange={(e) => {
                setSearchKey(e.target.value);
              }}
            />
            {
              !searchKey ? (
                <button type="button"
                  className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:search" className="text-white" />
                </button>
              ) : (
                <button onClick={() => setSearchKey("")}
                  className="flex items-center cursor-pointer bg-primary py-[0.15rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:cross" className="text-white" />
                </button>
              )
            }
          </div>
          <div className="max-md:w-full flex items-center gap-[0.5rem] py-[0.5rem] px-[1rem]"
            style={{
              border: "0.6px solid #DFDFDF",
              borderRadius: "6px",
            }}
          >
            <Flatpickr
              ref={flatpickrRef}
              className="text-[0.8rem] sm:text-[1rem] bg-transparent placeholder-[#848484] outline-none"
              placeholder="Select Date"
              options={dateOptions}
              onChange={(selectedDate) => handleDateChange(selectedDate)}
            />
            {
              !startDate || !endDate ? (
                <button type="button"
                  className="flex items-center cursor-pointer bg-primary py-[0.25rem] px-[0.2rem] rounded-md">
                  <Icon icon="solar:calendar-outline" className="text-white" />
                </button>
              ) : (
                <button onClick={clearDatesHandler}
                  className="flex items-center cursor-pointer bg-primary py-[0.25rem] px-[0.2rem] rounded-md">
                  <Icon icon="akar-icons:cross" className="text-white" />
                </button>
              )
            }
          </div>
          <div className="">
            <Select label="Select Country"
              className="text-[0.8rem] sm:text-[1rem] bg-transparent"
              value={status}
              onChange={(value) => setStatus(value)}
            >
              <Option value="APPROVED">APPROVED</Option>
              <Option value="REJECTED">REJECTED</Option>
              <Option value="HOLD">HOLD</Option>
              <Option value="PENDING">PENDING</Option>
              <Option value="ALL">ALL</Option>
            </Select>
          </div>
          <div className="flex flex-row gap-[0.5rem] xs:gap-[1rem] items-center justify-center py-[0.2rem]">
            <div className="">
              <Menu>
                <MenuHandler>
                  <Button className="text-[0.8rem] sm:text-[1rem] flex w-full flex-row justify-center whitespace-nowrap capitalize text-white bg-primary px-[0.4rem] xs:px-[1rem] py-[0.5rem] rounded-md items-center gap-[0.5rem] sm:gap-[1rem] hover:shadow-white shadow-white outline-none">
                    <Icon
                      icon="material-symbols:download"
                      className="text-[1rem] w-[1.2rem] sm:w-[1.5rem] h-auto"
                    />
                    <span className="font-[500]">Export</span>
                  </Button>
                </MenuHandler>
                <MenuList>
                  <MenuItem
                    //  onClick={downloadPdf}
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.5rem]"
                  >
                    {" "}
                    <Icon
                      icon="bxs:file-pdf"
                      className="text-[#BE0A23] w-[1.6rem] h-auto"
                    />
                    Download as PDF
                  </MenuItem>
                  <hr className="my-[0.2rem] sm:my-[0.5rem]" />
                  <MenuItem
                    //  onClick={downloadCSV}
                    className="text-[0.75rem] sm:text-[0.9rem] text-[#212529]/[0.6] font-bold flex flex-nowrap items-center gap-[0.9rem]"
                  >
                    <Icon
                      icon="fa-solid:file-csv"
                      className="text-[#29672E] w-[1.1rem] h-auto"
                    />
                    Download as CSV
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white px-[1rem]">
        <DataTable
          columns={columns}
          data={filteredItems}
          customStyles={customStyles}
          pagination
          progressPending={isLoading}
          selectableRows
          selectableRowsComponent={CustomCheckbox}
        />
      </div>
    </div>
  );
}

