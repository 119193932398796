import React, { useState } from 'react'
import { ThreeDots } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import PhoneInput, { isValidPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input'
import "react-phone-number-input/style.css";
import { APIurls } from '../../../api/apiConstant';
import { useAuth } from '../../../store/AuthContext';
import { Option, Select } from '@material-tailwind/react';
import { useOutletContext } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Icon } from '@iconify/react/dist/iconify.js';

const ROLES = {
  "Create Lottery": "CREATE_LOTTARY",
  "View Lottery": "VIEW_LOTTARY",
  "Edit Lottery": "EDIT_LOTTARY",
  "Delete Lottery": "DELETE_LOTTARY",
  "Create Private Lottery": "CREATE_PRIVATE_LOTTARY",
  "View Private Lottery": "CREATE_LOTTPRIV_VIEW",
  "Edit Private Lottery": "CREATE_LOTTPRIV_EDIT",
  "Delete Private Lottery": "CREATE_LOTTPRIV_DELETE",
  "Create Invite": "CREATE_INVITE",
  "View Users": "VIEW_USERS",
  "Create Lottery Agent": "CREATE_LOTTARY_AGENTS",
  "View Agents": "VIEW_AGENTS",
}

export default function CreateSubAdmin() {
  const [setUpdateSubadminList] = useOutletContext();
  const [mobileNumber, setMobileNumber] = useState(null);
  const [isMobileNumberValid, setIsMobileNumberValid] = useState(true);
  const [showError, setShowError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [loaderBtn, setLoaderBtn] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [designationStatus, setDesignationStatus] = useState("");
  const { register, handleSubmit, formState: { errors }, watch, reset } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const { getAccessToken, userRole } = useAuth();

  const fetchSubAdminRoles = async () => {
    const token = await getAccessToken()

    const response = await fetch(APIurls.getSubAdminRoles, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (!response.ok) {
      throw new Error("Failed to Fetch Roles");
    }
    const result = await response.json();

    console.log("SubAdmin Roles...", result)
    return result;
  };
  const { data: rolesData } = useQuery('roles', fetchSubAdminRoles);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    if (!(mobileNumber && designationStatus)) {
      setShowError(true);
      return;
    } else {
      setShowError(false);
    }
    const { name, email, password } = data;
    const token = await getAccessToken();
    try {
      setLoaderBtn(true)
      const response = await fetch(APIurls.crateSubAdmin, {
        method: 'POST',
        body: JSON.stringify({
          "name": name,
          "email": email,
          "mobile": mobileNumber,
          "password": password,
          "designation": designationStatus,
          "permissions": permissions,
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        // toast.error(response.statusText);
        setLoaderBtn(false)
        if (response.status === 400) {
          const result = await response.json();
          console.error("Error creating Sub-Admin:", result.message);
          toast.error(result?.message)
        } else if (response.status === 404) {
          const result = await response.json();
          console.error("Error creating Sub-Admin:", result.message);
          toast.error(result?.message)
        } else {
          console.log(response.status)
          // Handle other non-OK responses
          throw new Error("Error creating Sub-Admin");
        }
      } else {
        setLoaderBtn(false)
        const result = await response.json();
        toast.success("Sub-Admin created successfully!");
        setUpdateSubadminList(true);
        console.log(result);
        setMobileNumber(null);
        setDesignationStatus("");
        setPermissions([]);
        reset();
      }
    } catch (error) {
      setLoaderBtn(false)
      // Handle network errors or unexpected errors
      // toast.error("Error creating Lottery");
      toast.error("Error creating Sub-Admin")
      console.warn(error);
    }
  }

  const handleCheckboxChange = (role) => {
    // Check if the role is already in permissions
    if (permissions.includes(role)) {
      // If it is, remove it
      setPermissions((prevPermissions) =>
        prevPermissions.filter((permission) => permission !== role)
      );
    } else {
      // If it's not, add it
      setPermissions((prevPermissions) => [...prevPermissions, role]);
    }
  }
  console.log("PERMISSIONS", permissions);
  // console.log("Designation Status", designationStatus);

  const watchPassword = watch("password", "");
  const watchConfirmPassword = watch("confirmPassword", "");

  const formatKey = (key) => {
    return key.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
  };

  return (
    <div className="flex flex-col gap-[1rem] font-[500]">
      <form onSubmit={handleSubmit(onSubmit)}
        className="bg-white flex flex-col gap-[1rem] px-[2rem] rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[2rem] gap-y-[1rem]">
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="userName" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              User Name
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="text"
              placeholder="Enter User Name"
              {...register("name", {
                required: "*This field is required.",
              })}
            />
            {errors.name && (
              <div className="text-sm text-[#E92215]">
                {errors.name.message}
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="emailAddress" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Email Address
            </label>
            <input
              className="text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none"
              type="email"
              placeholder="Enter Email"
              {...register("email", {
                required: "*This field is required.",
                pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              })}
            />
            {errors.email?.type === "required" && (
              <p className="text-red-600 text-sm">{errors.email.message}</p>
            )}
            {errors.email?.type === "pattern" && (
              <p className="text-sm text-red-600">*Invalid Email</p>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="pricePerTicket" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Mobile Number
            </label>
            <PhoneInput className='text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] border border-[#D9D9D9] placeholder:text-[#D9D9D9] rounded-md outline-none'
              initialValueFormat="national"
              type="text"
              defaultCountry="IN"
              style={{
                border: "0.9px solid rgba(118, 125, 134, 0.40)",
                borderRadius: "5px",
              }}
              placeholder='Enter Your Mobile Number'
              value={formatPhoneNumberIntl(mobileNumber + '')}
              onChange={(value) => {
                if (isValidPhoneNumber(value + '')) {
                  setMobileNumber(value)
                  setIsMobileNumberValid(true)
                } else {
                  setIsMobileNumberValid(false);
                }
              }}
            />
            {((showError && !mobileNumber) || !isMobileNumberValid) && <p className="text-[#E92215] text-sm">{(!isMobileNumberValid && showError) ? "Invalid mobile number" : showError ? "*This field is required." : ""}</p>}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="password" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Password
            </label>
            <div className='flex justify-between items-center border border-[#D9D9D9] rounded-md px-[1rem] py-[0.5rem]'>
              <input
                className="text-[0.75rem] sm:text-[1rem] placeholder:text-[#D9D9D9] rounded-md outline-none w-full"
                type={showPassword ? "text" : "password"}
                placeholder="******"
                {...register("password", {
                  required: "*This field is required.",
                  minLength: 6,
                })}
              />
              <div className="text-[1.4rem] cursor-pointer ">
                {showPassword ? (
                  <Icon icon="fluent:eye-20-filled" onClick={() => setShowPassword(!showPassword)} />
                ) : (
                  <Icon icon="fluent:eye-off-20-filled" onClick={() => setShowPassword(!showPassword)} />
                )}
              </div>
            </div>
            {errors.password?.type === "required" && (
              <div className="text-sm text-[#E92215]">
                *This field is required
              </div>
            )}
            {errors.password?.type === "minLength" && (
              <div className="text-sm text-[#E92215]">
                Minimum Length: 6
              </div>
            )}
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <label htmlFor="confirmPassword" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Confirm Password
            </label>
            <div className='flex justify-between items-center border border-[#D9D9D9] rounded-md px-[1rem] py-[0.5rem]'>
              <input
                className="text-[0.75rem] sm:text-[1rem] placeholder:text-[#D9D9D9] outline-none w-full"
                type={showConfirmPassword ? "text" : "password"}
                placeholder="******"
                {...register("confirmPassword", {
                  required: "*This field is required.",
                })}
                onBlur={() => setShowPasswordError(true)}
              />
              <div className="text-[1.4rem] cursor-pointer ">
                {showConfirmPassword ? (
                  <Icon icon="fluent:eye-20-filled" onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                ) : (
                  <Icon icon="fluent:eye-off-20-filled" onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                )}
              </div>
            </div>
            {showPasswordError && watchPassword !== watchConfirmPassword && (
              <div className="text-sm text-[#E92215]">
                Password Doesn't match
              </div>
            )}
          </div>
          <div className='flex flex-col gap-[0.5rem]'>
            <label htmlFor="designation" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
              Designation
            </label>
            <Select className='text-black text-[0.75rem] sm:text-[1rem] py-[0.5rem] px-[1rem] placeholder:text-[#D9D9D9]'
              label='select'
              value={designationStatus}
              onChange={(value) => setDesignationStatus(value)}
            >
              <Option value='Manager'>Manager</Option>
              <Option value='Employee'>Employee</Option>
              <Option value='Sub Admin'>Sub Admin</Option>
            </Select>
            {showError && !designationStatus && (
              <p className="text-[#E92215] text-sm">
                *This field is required.
              </p>
            )}
          </div>
        </div>
        <div className='flex flex-col gap-[0.5rem]'>
          <label htmlFor="permissions" className="text-[0.8rem] sm:text-[1rem] text-[#858585]">
            Permissions
          </label>
          <div className='flex flex-wrap gap-[1rem]'>
            {Object?.keys(rolesData ? rolesData : {}).map((role) => (
              <div key={role} className='flex gap-[0.5rem] items-center border border-[#D9D9D9] px-[1rem] py-[0.5rem] rounded-md'>
                <input className='h-[1.2rem] w-[1.2rem] cursor-pointer'
                  type="checkbox"
                  id={role}
                  checked={permissions.includes(rolesData[role])}
                  onChange={() => handleCheckboxChange(rolesData[role])}
                />
                <label htmlFor={role} className='text-[#858585] cursor-pointer'>{formatKey(role)}</label>
              </div>
            ))}
          </div>
        </div>
        <div className="flex items-center justify-end mt-[1rem]">
          {(userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("CREATE_SUB_ADMIN")) && (
            loaderBtn ? (
              <ThreeDots
                height="50"
                width="50"
                radius="9"
                color="#9FB947"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) :
              (<button
                className="text-[1.2rem] font-semibold text-white bg-primary py-[0.5rem] w-[12rem] rounded-[8px]"
                type="submit"
              >
                Create Sub Admin
              </button>)
          )}
        </div>
      </form>
      <ToastContainer />
    </div>
  )
}
