import { createBrowserRouter } from "react-router-dom";
import AdminLogin from "../pages/login/AdminLogin";
import ForgotPassword from "../pages/login/ForgotPassword";
import AdminLayout from "../layouts/AdminLayout";
import ChangePassword from "../pages/login/ChangePassword";
import OTPVerfication from "../pages/login/OTPVerfication";
import Success from "../pages/login/Success";
import Failure from "../pages/login/Failure";
import Welcome from "../pages/Welcome";
import Dashboard from "../components/adminPanel/Dashboard";
import CreateLottery from "../components/adminPanel/CreateLottery";
import AdminPanelLottery from "../components/adminPanel/AdminPanelLottery";
import LotteryList from "../components/adminPanel/LotteryList";
import { useAuth } from "../store/AuthContext";
import { Navigate } from "react-router-dom";
import AdminPanelUsers from "../components/adminPanel/AdminPanelUsers";
import UsersList from "../components/adminPanel/UsersList";
import UserView from "../components/adminPanel/UserView";
import UserEdit from "../components/adminPanel/UserEdit";
import UserViewTranscations from "../components/adminPanel/UserViewTranscations";
import UserViewWinnings from "../components/adminPanel/UserViewWinnings";
import LotteryView from "../components/adminPanel/LotteryView";
import LotteryViewUsers from "../components/adminPanel/LotteryViewUsers";
import LotteryViewWishlist from "../components/adminPanel/LotteryViewWishlist";
import LotteryViewWinner from "../components/adminPanel/LotteryViewWinner";
import AdminPanelPrivateLotteries from "../components/adminPanel/AdminPanelPrivateLotteries";
import PrivateLotteriesList from "../components/adminModule/PrivateLotteries/PrivateLotteriesList";
import CreatePrivateLotteries from "../components/adminModule/PrivateLotteries/CreatePrivateLotteries";
import EditPrivateLotteries from "../components/adminModule/PrivateLotteries/EditPrivateLotteries";
import ViewPrivateLotteries from "../components/adminModule/PrivateLotteries/ViewPrivateLotteries";
import ViewPrivateLotteriesUsers from "../components/adminModule/PrivateLotteries/ViewPrivateLotteriesUsers";
import ViewPrivateLotteriesWishlist from "../components/adminModule/PrivateLotteries/ViewPrivateLotteriesWishlist";
import ViewPrivateLotteriesWinners from "../components/adminModule/PrivateLotteries/ViewPrivateLotteriesWinners";
import EditLottery from "../components/adminPanel/EditLottery";
import AdminPanelAgents from "../components/adminPanel/AdminPanelAgents";
import AgentsList from "../components/adminModule/Agents/AgentsList";
import AgentsRequestList from "../components/adminModule/Agents/AgentsRequestList";
import AgentsContainer from "../components/adminModule/Agents/AgentsContainer";
import CreateAgent from "../components/adminModule/Agents/CreateAgent";
import ViewAgentAll from "../components/adminModule/Agents/ViewAgentAll";
import ViewAgentRecharge from "../components/adminModule/Agents/ViewAgentRecharge";
import ViewAgentWithdrawal from "../components/adminModule/Agents/ViewAgentWithdrawal";
import ViewAgentPending from "../components/adminModule/Agents/ViewAgentPending";
import ViewAgent from "../components/adminModule/Agents/ViewAgent";
import AdminPanelPrivateAgents from "../components/adminPanel/AdminPanelPrivateAgents";
import PrivateAgentsList from "../components/adminModule/PrivateAgents/PrivateAgentsList";
import EditPrivateAgent from "../components/adminModule/PrivateAgents/EditWalletAgent/EditPrivateAgent";
import ViewPrivateAgent from "../components/adminModule/PrivateAgents/ViewPrivateAgent";
import ViewPrivateAgentInvited from "../components/adminModule/PrivateAgents/ViewPrivateAgentInvited";
import ViewPrivateAgentAll from "../components/adminModule/PrivateAgents/ViewPrivateAgentAll";
import ViewPrivateAgentRecharge from "../components/adminModule/PrivateAgents/ViewPrivateAgentRecharge";
import ViewPrivateAgentPending from "../components/adminModule/PrivateAgents/ViewPrivateAgentPending";
import ViewPrivateAgentWithdrawal from "../components/adminModule/PrivateAgents/ViewPrivateAgentWithdrawal";
import AdminPanelWithdrawals from "../components/adminPanel/AdminPanelWithdrawals";
import AdminPanelNumberGame from "../components/adminPanel/AdminPanelNumberGame";
import AdminPanelWheelGame from "../components/adminPanel/AdminPanelWheelGame";
import AdminPanelSubAdmin from "../components/adminPanel/AdminPanelSubAdmin";
import AdminPanelOffersBanners from "../components/adminPanel/AdminPanelOffersBanners";
import AdminPanelReports from "../components/adminPanel/AdminPanelReports";
import AdminPanelNotifications from "../components/adminPanel/AdminPanelNotifications";
import AdminPanelSettings from "../components/adminPanel/AdminPanelSettings";
import AdminPanelSupport from "../components/adminPanel/AdminPanelSupport";
import ContainerWithdrawals from "../components/adminModule/withdrawals/ContainerWithdrawals";
import AgentWithdrawals from "../components/adminModule/withdrawals/AgentWithdrawals";
import UserWithdrawals from "../components/adminModule/withdrawals/UserWithdrawals";
import AgentWithdrawalRequests from "../components/adminModule/withdrawals/AgentWithdrawalRequests";
import AgentTotalWithdrawals from "../components/adminModule/withdrawals/AgentTotalWithdrawals";
import UserWithdrawalRequests from "../components/adminModule/withdrawals/UserWithdrawalRequests";
import UserTotalWithdrawals from "../components/adminModule/withdrawals/UserTotalWithdrawals";
import CreateSubAdmin from "../components/adminModule/SubAdmin/CreateSubAdmin";
import EditSubAdmin from "../components/adminModule/SubAdmin/EditSubAdmin";
import OffersContainer from "../components/adminModule/OffersBanners/OffersContainer";
import CreateOffer from "../components/adminModule/OffersBanners/CreateOffer";
import EditOffer from "../components/adminModule/OffersBanners/EditOffer";
import CreateBanner from "../components/adminModule/OffersBanners/CreateBanner";
import EditBanner from "../components/adminModule/OffersBanners/EditBanner";
import BannersContainer from "../components/adminModule/OffersBanners/BannersContainer";
import CreateNotification from "../components/adminModule/Notifications/CreateNotification";
import EditNotification from "../components/adminModule/Notifications/EditNotification";
import ViewPrivateLotteriesAnalytics from "../components/adminModule/PrivateLotteries/ViewPrivateLotteriesAnalytics";
import _ from "lodash";
import AccessDenied from "../pages/AccessDenied";
import EditAgent from "../components/adminModule/Agents/EditAgent";
import UserViewWalletHistory from "../components/adminPanel/UserViewWalletHistory";
import LotteryViewWinnerContainer from "../components/adminPanel/LotteryViewWinnerContainer";
import PrivateAgentContainer from "../components/adminModule/PrivateAgents/PrivateAgentContainer";
import CreateWalletAgent from "../components/adminModule/PrivateAgents/CreateWalletAgent/CreateWalletAgent";
import AdminPanelWalletCoins from "../components/adminPanel/AdminPanelWalletCoins";
import WalletCoinsRequests from "../components/adminModule/WalletCoins/WalletCoinsRequests";
import TotalRequests from "../components/adminModule/WalletCoins/TotalRequests";
import Suspend from "../pages/Suspend";
import KYCPending from "../pages/KYCPending";
import PrivateAgentRequests from "../components/adminModule/PrivateAgents/PrivateAgentRequests";
import ViewPvtLotteryWinnerContainer from "../components/adminModule/PrivateLotteries/ViewPvtLotteryWinnerContainer";
import AdminArchiveLotteries from "../components/adminPanel/AdminArchiveLotteries";
import ArchiveLotteries from "../components/adminPanel/ArchiveLotteries";
import NumberGame from "../components/adminModule/NumberGame/NumberGame";
import CreateNumberGameForm from "../components/adminModule/NumberGame/CreateNumberGameForm";
import NumberGameViewPanel from "../components/adminModule/NumberGame/NumberGameViewPanel";
import NumberGameUserList from "../components/adminModule/NumberGame/NumberGameUserList";
import NumberGameWishlist from "../components/adminModule/NumberGame/NumberGameWishlist";
import NumberGameWinnersAnnounce from "../components/adminModule/NumberGame/NumberGameWinnersAnnounce";
import ViewNumberGameLotteryWinners from "../components/adminModule/NumberGame/ViewNumberGameLotteryWinners";

const ROLES = {
  // Dashboard
  DASHBOARD: "DASHBOARD",

  // Lottery
  LOTTERY: "LOTTERY",
  CREATE_LOTTERY: "CREATE_LOTTERY",
  VIEW_LOTTERY: "VIEW_LOTTERY",
  EDIT_LOTTERY: "EDIT_LOTTERY",
  DELETE_LOTTERY: "DELETE_LOTTERY",

  // Private Lottery
  PRIVATE_LOTTERY: "PRIVATE_LOTTERY",
  CREATE_PRIVATE_LOTTERY: "CREATE_PRIVATE_LOTTERY",
  VIEW_PRIVATE_LOTTERY: "VIEW_PRIVATE_LOTTERY",
  EDIT_PRIVATE_LOTTERY: "EDIT_PRIVATE_LOTTERY",
  DELETE_PRIVATE_LOTTERY: "DELETE_PRIVATE_LOTTERY",

  // Lottery Agent
  LOTTERY_AGENT: "LOTTERY_AGENT",
  VIEW_LOTTERY_AGENT: "VIEW_LOTTERY_AGENT",
  CREATE_LOTTERY_AGENT: "CREATE_LOTTERY_AGENT",
  EDIT_LOTTERY_AGENT: "EDIT_LOTTERY_AGENT",
  UPDATE_LOTTERY_AGENT_ACCOUNT_STATUS: "UPDATE_LOTTERY_AGENT_ACCOUNT_STATUS",
  APPROVE_LOTTERY_AGENT_REQUEST: "APPROVE_LOTTERY_AGENT_REQUEST",
  REJECT_LOTTERY_AGENT_REQUEST: "REJECT_LOTTERY_AGENT_REQUEST",

  // Wallet Agent
  WALLET_AGENT: "WALLET_AGENT",
  VIEW_WALLET_AGENT: "VIEW_WALLET_AGENT",
  WALLET_DISABLE_AGENTS: "WALLET_DISABLE_AGENTS",
  CREATE_WALLET_AGENT: "CREATE_WALLET_AGENT",
  EDIT_WALLET_AGENT: "EDIT_WALLET_AGENT",
  UPDATE_WALLET_AGENT_ACCOUNT_STATUS: "UPDATE_WALLET_AGENT_ACCOUNT_STATUS",
  UPDATE_WALLET_AGENT_REQUEST: "UPDATE_WALLET_AGENT_REQUEST",

  CREATE_INVITE: "CREATE_INVITE",

  //Wallet Coins
  WALLET_COINS: "WALLET_COINS",
  VIEW_WALLET_COINS: "VIEW_WALLET_COINS",
  UPDATE_WALLET_COINS_STATUS_REQUEST: "UPDATE_WALLET_COINS_STATUS_REQUEST",

  // Users
  USERS: "USERS",
  VIEW_USERS: "VIEW_USERS",
  UPDATE_USER_STATUS: "UPDATE_USER_STATUS",
  SEND_USER_NOTIFICATIONS: "SEND_USER_NOTIFICATIONS",
  ADD_COINS_TO_WALLET: "ADD_COINS_TO_WALLET",
  DISABLE_USERS: "DISABLE_USERS",

  // Withdrawals
  WITHDRAWALS: "WITHDRAWALS",
  VIEW_WITHDRAWALS: "VIEW_WITHDRAWALS",
  UPDATE_USER_WITHDRAWALS_REQUEST: "UPDATE_USER_WITHDRAWALS_REQUEST",

  // Sub-Admin
  SUB_ADMIN: "SUB_ADMIN",
  VIEW_SUB_ADMIN: "VIEW_SUB_ADMIN",
  CREATE_SUB_ADMIN: "CREATE_SUB_ADMIN",
  EDIT_SUB_ADMIN: "EDIT_SUB_ADMIN",
  DELETE_SUB_ADMIN: "DELETE_SUB_ADMIN",

  // Offers-Banners
  VIEW_OFFERS_BANNERS: "VIEW_OFFERS_BANNERS",
  // Offers
  OFFERS: "OFFERS",
  CREATE_OFFERS: "CREATE_OFFERS",
  EDIT_OFFERS: "EDIT_OFFERS",
  DELETE_OFFERS: "DELETE_OFFERS",

  // Banners
  BANNERS: "BANNERS",
  CREATE_BANNERS: "CREATE_BANNERS",
  EDIT_BANNERS: "EDIT_BANNERS",
  DELETE_BANNERS: "DELETE_BANNERS",

  // Reports
  REPORTS: "REPORTS",

  // Notifications
  NOTIFICATIONS: "NOTIFICATIONS",
  CREATE_NOTIFICATIONS: "CREATE_NOTIFICATIONS",
  VIEW_NOTIFICATIONS: "VIEW_NOTIFICATIONS",
  EDIT_NOTIFICATIONS: "EDIT_NOTIFICATIONS",
  DELETE_NOTIFICATIONS: "DELETE_NOTIFICATIONS",
  SHARE_NOTIFICATIONS: "SHARE_NOTIFICATIONS",

  // Support
  SUPPORT: "SUPPORT",

  // Settings
  SETTINGS: "SETTINGS",

  // Number Game
  NUMBER_GAME: "NUMBER_GAME",

  // Wheel Game
  WHEEL_GAME: "WHEEL_GAME",

  // Archive Lotteries
  VIEW_ARCHIVE_LOTTERIES: "VIEW_ARCHIVE_LOTTERIES",
  RETRIEVE_PUBLIC_LOTTERIES: "RETRIEVE_PUBLIC_LOTTERIES",
  RETRIEVE_PRIVATE_LOTTERIES: "RETRIEVE_PRIVATE_LOTTERIES",
};

const PrivateRoute = ({ component: Component, Permissions: permis }) => {
  const { currentUser, userRole } = useAuth();

  // console.log(userRole?.role?.subAdmin?.permissions)
  return (
    <>
      {(currentUser && userRole?.role?.admin) ||
      (currentUser && userRole?.role?.subAdmin?.type) ? (
        !_.isEmpty(permis) && userRole?.role?.subAdmin?.type ? (
          userRole?.role?.subAdmin?.permissions.includes(permis) ? (
            <Component />
          ) : (
            <AccessDenied />
          )
        ) : (
          <Component />
        )
      ) : (
        <Navigate to="/login" replace />
      )}
    </>
  );
};

const PublicRoute = ({ component: Component }) => {
  const { currentUser, userRole } = useAuth();

  console.log("Current" + currentUser, userRole);
  return (
    <>
      {(currentUser && userRole?.role?.admin) ||
      (currentUser && userRole?.role?.subAdmin?.type) ? (
        <Navigate to="/admin" replace />
      ) : (
        <Component />
      )}
    </>
  );
};

export const router = createBrowserRouter([
  {
    path: "/admin",
    element: <PrivateRoute component={AdminLayout} />,
    children: [
      {
        path: "",
        element: (
          <PrivateRoute component={Dashboard} Permissions={ROLES.DASHBOARD} />
        ),
      },
      {
        path: "lottery",
        element: (
          <PrivateRoute
            component={AdminPanelLottery}
            Permissions={ROLES.VIEW_LOTTERY}
          />
        ),
        children: [
          {
            path: "",
            element: (
              <PrivateRoute
                component={LotteryList}
                Permissions={ROLES.VIEW_LOTTERY}
              />
            ),
          },
          {
            path: "create",
            element: (
              <PrivateRoute
                component={CreateLottery}
                Permissions={ROLES.CREATE_LOTTERY}
              />
            ),
          },
          {
            path: "edit/:id",
            element: (
              <PrivateRoute
                component={EditLottery}
                Permissions={ROLES.EDIT_LOTTERY}
              />
            ),
          },
          {
            path: "view/:id",
            element: (
              <PrivateRoute
                component={LotteryView}
                Permissions={ROLES.VIEW_LOTTERY}
              />
            ),
            children: [
              {
                path: "",
                element: (
                  <PrivateRoute
                    component={LotteryViewUsers}
                    Permissions={ROLES.VIEW_LOTTERY}
                  />
                ),
              },
              {
                path: "wishlist",
                element: (
                  <PrivateRoute
                    component={LotteryViewWishlist}
                    Permissions={ROLES.VIEW_LOTTERY}
                  />
                ),
              },
              {
                path: "winners",
                element: (
                  <PrivateRoute
                    component={LotteryViewWinnerContainer}
                    Permissions={ROLES.VIEW_LOTTERY}
                  />
                ),
              },
            ],
          },
        ],
      },
      {
        path: "lottery-agents",
        element: (
          <PrivateRoute
            component={AdminPanelAgents}
            Permissions={ROLES.VIEW_LOTTERY_AGENT}
          />
        ),
        children: [
          {
            path: "",
            element: (
              <PrivateRoute
                component={AgentsContainer}
                Permissions={ROLES.VIEW_LOTTERY_AGENT}
              />
            ),
            children: [
              {
                path: "",
                element: (
                  <PrivateRoute
                    component={AgentsList}
                    Permissions={ROLES.VIEW_LOTTERY_AGENT}
                  />
                ),
              },
              {
                path: "request",
                element: (
                  <PrivateRoute
                    component={AgentsRequestList}
                    Permissions={ROLES.VIEW_LOTTERY_AGENT}
                  />
                ),
              },
            ],
          },
          {
            path: "create",
            element: (
              <PrivateRoute
                component={CreateAgent}
                Permissions={ROLES.CREATE_LOTTERY_AGENT}
              />
            ),
          },
          {
            path: "edit/:id",
            element: (
              <PrivateRoute
                component={EditAgent}
                Permissions={ROLES.EDIT_LOTTERY_AGENT}
              />
            ),
          },
          {
            path: "view/:id",
            element: (
              <PrivateRoute
                component={ViewAgent}
                Permissions={ROLES.VIEW_LOTTERY_AGENT}
              />
            ),
            children: [
              {
                path: "",
                element: (
                  <PrivateRoute
                    component={ViewAgentAll}
                    Permissions={ROLES.VIEW_LOTTERY_AGENT}
                  />
                ),
              },
              {
                path: "recharge",
                element: (
                  <PrivateRoute
                    component={ViewAgentRecharge}
                    Permissions={ROLES.VIEW_LOTTERY_AGENT}
                  />
                ),
              },
              {
                path: "withdrawal",
                element: (
                  <PrivateRoute
                    component={ViewAgentWithdrawal}
                    Permissions={ROLES.VIEW_LOTTERY_AGENT}
                  />
                ),
              },
              {
                path: "pending",
                element: (
                  <PrivateRoute
                    component={ViewAgentPending}
                    Permissions={ROLES.VIEW_LOTTERY_AGENT}
                  />
                ),
              },
            ],
          },
        ],
      },
      {
        path: "wallet-agents",
        element: (
          <PrivateRoute
            component={AdminPanelPrivateAgents}
            Permissions={ROLES.VIEW_WALLET_AGENT}
          />
        ),
        children: [
          {
            path: "",
            element: (
              <PrivateRoute
                component={PrivateAgentContainer}
                Permissions={ROLES.VIEW_WALLET_AGENT}
              />
            ),
            children: [
              {
                path: "",
                element: (
                  <PrivateRoute
                    component={PrivateAgentsList}
                    Permissions={ROLES.VIEW_WALLET_AGENT}
                  />
                ),
              },
              {
                path: "request",
                element: (
                  <PrivateRoute
                    component={PrivateAgentRequests}
                    Permissions={ROLES.VIEW_WALLET_AGENT}
                  />
                ),
              },
            ],
          },
          {
            path: "create",
            element: (
              <PrivateRoute
                component={CreateWalletAgent}
                Permissions={ROLES.CREATE_WALLET_AGENT}
              />
            ),
          },
          {
            path: "edit/:id",
            element: (
              <PrivateRoute
                component={EditPrivateAgent}
                Permissions={ROLES.EDIT_WALLET_AGENT}
              />
            ),
          },
          {
            path: "view/:id",
            element: (
              <PrivateRoute
                component={ViewPrivateAgent}
                Permissions={ROLES.VIEW_WALLET_AGENT}
              />
            ),
            children: [
              {
                path: "",
                element: (
                  <PrivateRoute
                    component={ViewPrivateAgentAll}
                    Permissions={ROLES.VIEW_WALLET_AGENT}
                  />
                ),
              },
              {
                path: "recharge",
                element: (
                  <PrivateRoute
                    component={ViewPrivateAgentRecharge}
                    Permissions={ROLES.VIEW_WALLET_AGENT}
                  />
                ),
              },
              {
                path: "withdrawal",
                element: (
                  <PrivateRoute
                    component={ViewPrivateAgentWithdrawal}
                    Permissions={ROLES.VIEW_WALLET_AGENT}
                  />
                ),
              },
              {
                path: "pending",
                element: (
                  <PrivateRoute
                    component={ViewPrivateAgentPending}
                    Permissions={ROLES.VIEW_WALLET_AGENT}
                  />
                ),
              },
              {
                path: "invited",
                element: (
                  <PrivateRoute
                    component={ViewPrivateAgentInvited}
                    Permissions={ROLES.VIEW_WALLET_AGENT}
                  />
                ),
              },
            ],
          },
        ],
      },
      {
        path: "wallet-coins",
        element: (
          <PrivateRoute
            component={AdminPanelWalletCoins}
            Permissions={ROLES.VIEW_WALLET_COINS}
          />
        ),
        children: [
          {
            path: "",
            element: (
              <PrivateRoute
                component={WalletCoinsRequests}
                Permissions={ROLES.VIEW_WALLET_COINS}
              />
            ),
          },
          {
            path: "request",
            element: (
              <PrivateRoute
                component={TotalRequests}
                Permissions={ROLES.VIEW_WALLET_COINS}
              />
            ),
          },
        ],
      },
      {
        path: "private-lotteries",
        element: (
          <PrivateRoute
            component={AdminPanelPrivateLotteries}
            Permissions={ROLES.VIEW_PRIVATE_LOTTERY}
          />
        ),
        children: [
          {
            path: "",
            element: (
              <PrivateRoute
                component={PrivateLotteriesList}
                Permissions={ROLES.VIEW_PRIVATE_LOTTERY}
              />
            ),
          },
          {
            path: "create",
            element: (
              <PrivateRoute
                component={CreatePrivateLotteries}
                Permissions={ROLES.CREATE_PRIVATE_LOTTERY}
              />
            ),
          },
          {
            path: "edit/:id",
            element: (
              <PrivateRoute
                component={EditPrivateLotteries}
                Permissions={ROLES.EDIT_PRIVATE_LOTTERY}
              />
            ),
          },
          {
            path: "view/:id",
            element: (
              <PrivateRoute
                component={ViewPrivateLotteries}
                Permissions={ROLES.VIEW_PRIVATE_LOTTERY}
              />
            ),
            children: [
              {
                path: "",
                element: (
                  <PrivateRoute
                    component={ViewPrivateLotteriesUsers}
                    Permissions={ROLES.VIEW_PRIVATE_LOTTERY}
                  />
                ),
              },
              {
                path: "wishlist",
                element: (
                  <PrivateRoute
                    component={ViewPrivateLotteriesWishlist}
                    Permissions={ROLES.VIEW_PRIVATE_LOTTERY}
                  />
                ),
              },
              {
                path: "analytics",
                element: (
                  <PrivateRoute
                    component={ViewPrivateLotteriesAnalytics}
                    Permissions={ROLES.VIEW_PRIVATE_LOTTERY}
                  />
                ),
              },
              {
                path: "invited",
                element: (
                  <PrivateRoute
                    component={ViewPrivateAgentInvited}
                    Permissions={ROLES.VIEW_PRIVATE_LOTTERY}
                  />
                ),
              },
              {
                path: "winners",
                element: (
                  <PrivateRoute
                    component={ViewPvtLotteryWinnerContainer}
                    Permissions={ROLES.VIEW_PRIVATE_LOTTERY}
                  />
                ),
              },
            ],
          },
        ],
      },
      {
        path: "users",
        element: (
          <PrivateRoute
            component={AdminPanelUsers}
            Permissions={ROLES.VIEW_USERS}
          />
        ),
        children: [
          {
            path: "",
            element: (
              <PrivateRoute
                component={UsersList}
                Permissions={ROLES.VIEW_USERS}
              />
            ),
          },
          {
            path: "view/:id",
            element: (
              <PrivateRoute
                component={UserView}
                Permissions={ROLES.VIEW_USERS}
              />
            ),
            children: [
              {
                path: "",
                element: (
                  <PrivateRoute
                    component={UserViewTranscations}
                    Permissions={ROLES.VIEW_USERS}
                  />
                ),
              },
              {
                path: "winnings",
                element: (
                  <PrivateRoute
                    component={UserViewWinnings}
                    Permissions={ROLES.VIEW_USERS}
                  />
                ),
              },
              {
                path: "wallet-history",
                element: (
                  <PrivateRoute
                    component={UserViewWalletHistory}
                    Permissions={ROLES.VIEW_USERS}
                  />
                ),
              },
            ],
          },
          {
            path: "edit/:id",
            element: <PrivateRoute component={UserEdit} />,
          },
        ],
      },
      {
        path: "withdrawals",
        element: (
          <PrivateRoute
            component={AdminPanelWithdrawals}
            Permissions={ROLES.VIEW_WITHDRAWALS}
          />
        ),
        children: [
          {
            path: "",
            element: (
              <PrivateRoute
                component={ContainerWithdrawals}
                Permissions={ROLES.VIEW_WITHDRAWALS}
              />
            ),
            children: [
              {
                path: "",
                element: (
                  <PrivateRoute
                    component={UserWithdrawals}
                    Permissions={ROLES.VIEW_WITHDRAWALS}
                  />
                ),
                children: [
                  {
                    path: "",
                    element: (
                      <PrivateRoute
                        component={UserWithdrawalRequests}
                        Permissions={ROLES.VIEW_WITHDRAWALS}
                      />
                    ),
                  },
                  {
                    path: "total",
                    element: (
                      <PrivateRoute
                        component={UserTotalWithdrawals}
                        Permissions={ROLES.VIEW_WITHDRAWALS}
                      />
                    ),
                  },
                ],
              },
              {
                path: "agents",
                element: (
                  <PrivateRoute
                    component={AgentWithdrawals}
                    Permissions={ROLES.VIEW_WITHDRAWALS}
                  />
                ),
                children: [
                  {
                    path: "",
                    element: (
                      <PrivateRoute
                        component={AgentWithdrawalRequests}
                        Permissions={ROLES.VIEW_WITHDRAWALS}
                      />
                    ),
                  },
                  {
                    path: "total",
                    element: (
                      <PrivateRoute
                        component={AgentTotalWithdrawals}
                        Permissions={ROLES.VIEW_WITHDRAWALS}
                      />
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "number-game",
        element: (
          <PrivateRoute
            component={AdminPanelNumberGame}
            Permissions={ROLES.NUMBER_GAME}
          />
        ),
        children: [
          {
            path: "",
            element: <NumberGame />,
          },
          {
            path: "create-number-game",
            element: <CreateNumberGameForm />,
          },
          {
            path: "view/:viewId",
            element: <NumberGameViewPanel />,
            children: [
              {
                path:"",
                element: <NumberGameUserList />,
              },
              {
                path: "winners",
                element: <NumberGameWinnersAnnounce />,
              },
              {
                path: "wishlist",
                element: <NumberGameWishlist />,
              },
            ],
          },
        ],
      },
      {
        path: "wheel-game",
        element: (
          <PrivateRoute
            component={AdminPanelWheelGame}
            Permissions={ROLES.WHEEL_GAME}
          />
        ),
      },
      {
        path: "sub-admin",
        element: (
          <PrivateRoute
            component={AdminPanelSubAdmin}
            Permissions={ROLES.VIEW_SUB_ADMIN}
          />
        ),
        children: [
          {
            path: "",
            element: (
              <PrivateRoute
                component={CreateSubAdmin}
                Permissions={ROLES.VIEW_SUB_ADMIN}
              />
            ),
          },
          {
            path: "edit/:id",
            element: (
              <PrivateRoute
                component={EditSubAdmin}
                Permissions={ROLES.VIEW_SUB_ADMIN}
              />
            ),
          },
        ],
      },
      {
        path: "offers-banners",
        element: (
          <PrivateRoute
            component={AdminPanelOffersBanners}
            Permissions={ROLES.VIEW_OFFERS_BANNERS}
          />
        ),
        children: [
          {
            path: "",
            element: (
              <PrivateRoute
                component={OffersContainer}
                Permissions={ROLES.VIEW_OFFERS_BANNERS}
              />
            ),
            children: [
              {
                path: "",
                element: (
                  <PrivateRoute
                    component={CreateOffer}
                    Permissions={ROLES.VIEW_OFFERS_BANNERS}
                  />
                ),
              },
              {
                path: "edit/:id",
                element: (
                  <PrivateRoute
                    component={EditOffer}
                    Permissions={ROLES.VIEW_OFFERS_BANNERS}
                  />
                ),
              },
            ],
          },
          {
            path: "banners",
            element: (
              <PrivateRoute
                component={BannersContainer}
                Permissions={ROLES.VIEW_OFFERS_BANNERS}
              />
            ),
            children: [
              {
                path: "",
                element: (
                  <PrivateRoute
                    component={CreateBanner}
                    Permissions={ROLES.VIEW_OFFERS_BANNERS}
                  />
                ),
              },
              {
                path: "edit/:id",
                element: (
                  <PrivateRoute
                    component={EditBanner}
                    Permissions={ROLES.VIEW_OFFERS_BANNERS}
                  />
                ),
              },
            ],
          },
        ],
      },
      {
        path: "reports",
        element: (
          <PrivateRoute
            component={AdminPanelReports}
            Permissions={ROLES.REPORTS}
          />
        ),
      },
      {
        path: "notifications",
        element: (
          <PrivateRoute
            component={AdminPanelNotifications}
            Permissions={ROLES.VIEW_NOTIFICATIONS}
          />
        ),
        children: [
          {
            path: "",
            element: (
              <PrivateRoute
                component={CreateNotification}
                Permissions={ROLES.VIEW_NOTIFICATIONS}
              />
            ),
          },
          {
            path: "edit/:id",
            element: (
              <PrivateRoute
                component={EditNotification}
                Permissions={ROLES.VIEW_NOTIFICATIONS}
              />
            ),
          },
        ],
      },
      {
        path: "settings",
        element: (
          <PrivateRoute
            component={AdminPanelSettings}
            Permissions={ROLES.SETTINGS}
          />
        ),
      },
      {
        path: "support",
        element: (
          <PrivateRoute
            component={AdminPanelSupport}
            Permissions={ROLES.SUPPORT}
          />
        ),
      },
      {
        path: "archive-lotteries",
        element: (
          <PrivateRoute
            component={AdminArchiveLotteries}
            Permissions={ROLES.VIEW_ARCHIVE_LOTTERIES}
          />
        ),
      },
    ],
  },
  {
    path: "/login",
    element: <PublicRoute component={AdminLogin} />,
  },
  // {
  //   path: "/login",
  //   element: <PublicRoute component={Suspend} />,
  // },
  // {
  //   path: "/login",
  //   element: <PublicRoute component={KYCPending} />,
  // },
  {
    path: "/forgotPassword",
    element: <PublicRoute component={ForgotPassword} />,
  },
  {
    path: "/changePassword",
    element: <PublicRoute component={ChangePassword} />,
  },
  {
    path: "/otp-verification",
    element: <PublicRoute component={OTPVerfication} />,
  },
  {
    path: "/success",
    element: <Success />,
  },
  {
    path: "/failure",
    element: <Failure />,
  },
  {
    path: "/",
    element: <Welcome />,
  },
]);
