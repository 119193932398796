import React from 'react'
import JoinedUsers from './JoinedUsers'
import TopUsers from './TopUsers'

export default function Users() {
  return (
    <div className='flex flex-col w-full gap-10'>
      <TopUsers></TopUsers>
      <JoinedUsers></JoinedUsers>
    </div>
  )
}
