import React, { useContext, useState, useEffect } from "react"
import { auth } from "../Firebase";
import { signInWithEmailAndPassword, sendPasswordResetEmail, signOut } from "firebase/auth";
import { APIurls } from "../api/apiConstant";


const AuthContext = React.createContext()

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState();
  const [details, setDetails] = useState();

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  function logout() {
    return signOut(auth)
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  }

  function getAccessToken() {
    return currentUser?.getIdToken(true)
  }
  function saveUserRole(value) {
    setUserRole(value)
  }
  const getUserRoleFunc = async (user) => {
    try {
      console.log(user?.accessToken)
      const result = await fetch(APIurls.getRoles, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
        }
      });
      const resultJson = await result.json();

      console.log(resultJson)
      if (!result.ok) {
        throw new Error("Failed to login");
      }
      setUserRole(resultJson?.response)
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchDetails(token) {
    // console.log("TOKEN",token)
    try {
      const response = await fetch(APIurls.fetchDetails, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (!response.ok) {
        throw new Error("Failed to fetch details")
      }
      const result = await response.json();
      setDetails(result?.response?.data);
      console.log("DETAILS", result?.response?.data);
    } catch (error) {
      console.warn("Failed to fetch details");
    }
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      const token = await getAccessToken();
      setCurrentUser(user)
      await getUserRoleFunc(user);
      await fetchDetails(token);
      setLoading(false)
    })
    console.log(unsubscribe);

    return unsubscribe
  }, [currentUser])

  const value = {
    currentUser,
    login,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    getAccessToken,
    saveUserRole,
    userRole,
    details
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}