import { Icon } from "@iconify/react/dist/iconify.js";
import winnerLogo from "../../../assets/lottery-winner-logo.svg";
import { NavLink } from "react-router-dom";
import ViewNumberGameLotteryWinners from "./ViewNumberGameLotteryWinners";
import { useState } from "react";

export default function NumberGameWinnersAnnounce() {
  const [isAnnounced, setIsAnnounced] = useState(false);
  return (
    <>
      {isAnnounced ? (
        <ViewNumberGameLotteryWinners />
      ) : (
        <div className="py-[0.5rem] w-auto">
          <div className="flex justify-between items-center">
            <h1 className="text-[1.1rem] sm:text-[1.4rem] font-semibold">
              Winners
            </h1>
            <div className="flex items-center gap-[1rem]">
              <button
                // onClick={() => navigate(-1)}
                className="flex items-center gap-[0.5rem] text-[0.8rem] sm:text-[1rem] font-[500] text-white bg-primary py-[0.4rem] px-[1rem] rounded-md"
              >
                <Icon
                  icon="ic:round-notifications-active"
                  className="text-[1.3rem]"
                />
                Send Notifications
              </button>
              <button
                // onClick={() => navigate(-1)}
                className="flex items-center gap-[0.5rem] text-[0.8rem] sm:text-[1rem] font-[500] text-white bg-primary py-[0.4rem] px-[1rem] rounded-md"
              >
                <Icon icon="tabler:download" className="text-[1.3rem]" />
                Export
              </button>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-[0.5rem]">
            <div className="p-[1rem] bg-[#E0E0E0] w-auto rounded-full">
              <img src={winnerLogo} alt="" />
            </div>
            <h1 className="text-[1.5rem] font-bold mt-[1rem]">Winners</h1>
            <p className="text-[#858585]">Yet to be Decided.</p>
            <button
              onClick={() => setIsAnnounced(true)}
              className="text-[1rem] font-[500] text-white bg-primary py-[0.5rem] px-[1.5rem] rounded-md mt-4 mb-8"
            >
              Announce Winner
            </button>
          </div>
        </div>
      )}
    </>
  );
}
