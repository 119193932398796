import React from 'react'
import accessDeniedImg from '../assets/access-denied.png'
import { Icon } from '@iconify/react';

export default function AccessDenied() {
    return (
        <div className='flex flex-col gap-[2rem] items-center justify-center text-center p-[5rem]'>
            <img src={accessDeniedImg} alt="Access Denied" className='w-[30%]' />
            <h2 className='font-[500] text-[1.2rem]'>
                It appears that you do not currently have access. To obtain access, kindly request it from your administrator using "Request Access" action provided below.
            </h2>
            <button className='flex items-center gap-[0.5rem] border-2 border-[#D9D9D9] py-[0.5rem] px-[1rem] rounded-lg'>
                <Icon icon="material-symbols:lock-outline" className='text-[1.5rem]'/>
                <span className='font-semibold text-[1.2rem]'>Request Access</span>
            </button>
        </div>
    )
}
