import React from 'react'
import JoinedUsers from './JoinedUsers'
import TopUsers from './TopUsers'

export default function Users() {
  return (
    // <div className='flex lg:flex-row flex-col justify-between items-center gap-6 mb-10'>
    <div className='flex flex-col w-full gap-10'>
      <TopUsers></TopUsers>
      <JoinedUsers></JoinedUsers>
    </div>
  )
}
