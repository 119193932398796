import React, { useEffect, useRef } from 'react'

export default function NumberInput({ value, onChange, className, placeholder }) {
    const inputRef = useRef(null);

    useEffect(() => {
        const handleWheel = (event) => {
            if (document.activeElement === inputRef.current) {
                event.preventDefault();
            }
        };
        const input = inputRef.current;
        input.addEventListener('wheel', handleWheel);
        // Cleanup the event listener on component unmount
        return () => {
            input.removeEventListener('wheel', handleWheel);
        };
    }, []);

    return <input ref={inputRef}
        className={className}
        type='number'
        placeholder={placeholder}
        value={value}
        onChange={onChange}
    />;
}
