import React, { useState } from 'react'
import { Icon } from '@iconify/react';
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom';
// import userImg from '../../assets/user-img.png'
import { useQuery } from 'react-query';
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";
import moment from 'moment';
import userImg from '../../../assets/user-img.png'
import { toast } from 'react-toastify';
import { RotatingLines } from 'react-loader-spinner';

export default function ViewAgent() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { getAccessToken } = useAuth();

  const fetchLotteryDataById = async () => {
    const token = await getAccessToken()
    const response = await fetch(`${APIurls.fetchAgentById}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (!response.ok) {
      throw new Error("Failed to Fetch User Data");
    }
    const result = await response.json();
    console.log("SINGLE AGENT DATA", result?.response?.agentData);
    return result?.response?.agentData;
  };

  const { data: agentData, isError, error, isLoading } = useQuery(['agent', id], fetchLotteryDataById);

  if (isError) {
    return (
      <div>
        {toast.error(error.message)}
        <h1 className='text-[2rem] font-bold flex items-center justify-center h-screen'>
          Failed to Fetch Lottery Agent Data...!
        </h1>
      </div>
    )
  }
  if (isLoading) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <RotatingLines
          strokeColor="green"
          strokeWidth="5"
          animationDuration="0.75"
          width="100"
          visible={true}
        />
      </div>
    );
  }


  return (
    <div className='flex flex-col gap-[2rem] my-[1rem] px-[1rem]'>
      <div className='flex justify-between'>
        <button
          onClick={() => navigate("/admin/lottery-agents")}
          className="flex items-center gap-[0.5rem] text-[0.8rem] sm:text-[1rem] font-[500] text-white bg-primary py-[0.4rem] px-[1rem] rounded-md"
        >
          <Icon icon="material-symbols:arrow-back-ios-new-rounded" />
          <span>Back</span>
        </button>
        <div className='flex gap-[1rem]'>
          <button
            onClick={() => navigate("/admin/lottery-agents")}
            className="text-[0.8rem] sm:text-[1rem] font-[500] text-white bg-primary py-[0.4rem] px-[2rem] rounded-md"
          >
            Edit
          </button>
          <button
            onClick={() => navigate("/admin/lottery-agents")}
            className="text-[0.8rem] sm:text-[1rem] font-[500] text-white bg-[#FF0023] py-[0.4rem] px-[1rem] rounded-md"
          >
            Block User
          </button>
        </div>
      </div>
      <div className='h-[155px] flex gap-[1rem]'>
        <div>
          <img src={userImg} alt="userImg" className='rounded-xl h-[155px] w-[170px] bg-cover bg-center' />
        </div>
        <div className='flex flex-col justify-between gap-[1rem] p-[1rem] rounded-xl'
          style={{
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          <div className='flex gap-[2rem] items-center'>
            <div className='bg-[#D1F0CF] p-[0.1rem] rounded-md'>
              <Icon icon="heroicons-solid:user-group" className='text-primary text-[2rem]' />
            </div>
            <div>
              <h1 className='font-bold whitespace-nowrap'>
                {agentData?.FirstName + ' ' + agentData?.LastName || "NA"}
              </h1>
              <p className='text-[#8B8D97] text-[0.8rem] whitespace-nowrap'>Created on{" "}
                <span className='font-bold'>
                  {moment(agentData?.createdAt).format("DD MMM YYYY") || "NA"}
                </span>
              </p>
            </div>
            <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold
        py-[0.4rem] px-4 ${agentData.status === "ACTIVE" ? "text-[#25BF17] bg-[#D1F0CF]" : agentData.status === "SUSPENDED" ? "text-[#FF0023] bg-[#FDCAD1]" : "text-[#FFCE06] bg-[#FDF9EF]"}`}>
              <span className={`${agentData.status === "ACTIVE" ? "bg-[#25BF17]" : agentData.status === "SUSPENDED" ? "bg-[#FF0023]" : "bg-[#FFCE06]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
              <h2>{agentData.status}</h2>
            </div>
          </div>
          <div className='flex items-center justify-between'>
            <div>
              <label htmlFor="emailId" className='text-[#8B8D97] text-[0.9rem]'>
                Email ID
              </label>
              <h2 className='font-semibold'>
                {agentData?.email || "NA"}
              </h2>
            </div>
            <div>
              <label htmlFor="emailId" className='text-[#8B8D97] text-[0.9rem]'>
                Mobile Number
              </label>
              <h2 className='font-semibold'>
                {agentData?.mobileNumber || "NA"}
              </h2>
            </div>
          </div>
        </div>
        <div className='flex flex-col justify-between gap-[1rem] p-[1rem] rounded-xl'
          style={{
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          <div>
            <label htmlFor="emailId" className='text-[#8B8D97] text-[0.9rem]'>
              WhatsApp Number
            </label>
            <h2 className='font-semibold'>
              {agentData?.whatsApp || "NA"}
            </h2>
          </div>
          <div>
            <label htmlFor="mobileNumber" className='text-[#8B8D97] text-[0.9rem]'>
              Address
            </label>
            <h2 className='font-semibold'>
              {`${agentData?.location?.address}, ${agentData?.location?.city}, ${agentData?.location?.state}, ${agentData?.location?.country}, ${agentData?.location?.pincode}`}
            </h2>
          </div>
        </div>
      </div>
      <div>
        <div className='flex gap-[2rem] text-[1.2rem] border-b-2 border-[#989898] px-[1rem]'>
          <NavLink to={`/admin/lottery-agents/view/${id}`} end>
            {({ isActive }) => (
              <h2 className={` ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                All
              </h2>
            )}
          </NavLink>
          <NavLink to={`/admin/lottery-agents/view/${id}/recharge`}>
            {({ isActive }) => (
              <h2 className={` ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                Recharge
              </h2>
            )}
          </NavLink>
          <NavLink to={`/admin/lottery-agents/view/${id}/withdrawal`}>
            {({ isActive }) => (
              <h2 className={` ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                Withdrawal
              </h2>
            )}
          </NavLink>
          <NavLink to={`/admin/lottery-agents/view/${id}/pending`}>
            {({ isActive }) => (
              <h2 className={` ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                Pending
              </h2>
            )}
          </NavLink>
        </div>
        <Outlet />
      </div>
    </div>
  )
}

