import React, { useState } from "react";
import { Icon } from "@iconify/react";
import strikeLogo from "../../assets/strike-logo.svg";

import { Link, useLocation, NavLink } from "react-router-dom";
//import premiumFeature from "../../assets/premiumFeature.svg";
//import logo from "../../assets/final 5.jpg";
// import { useAuth } from "../../stores/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../store/AuthContext";
import './adminStyle.css';



const AdminSideBar = ({ isAdminSidebarOpen, setIsAdminSidebarOpen }) => {
  const path = useLocation().pathname.split("/")[2];
  const { userRole } = useAuth();

  //   const { logout } = useAuth();
  const navigate = useNavigate();

  const { logout } = useAuth()
  // const handleLogout = async () => {
  //   try {
  //     await logout();
  //     navigate("/adminLogin", { replace: true });
  //   } catch (error) {
  //     console.log(error);
  //     toast.error("Failed to logout.", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //   }
  // };

  return (
    <div
      className="bg-[#F7FAFC] text-primary relative h-screen overflow-y-auto  lg:sticky lg:top-0 lg:flex lg:flex-col lg:justify-between pb-[2.5rem] custom-container lg:pt-[4.5rem]"
    >
      <div>
        <div className="flex flex-col justify-center items-center sticky top-0 my-[1rem] bg-[#F7FAFC] z-10">
          <div
            className="p-[0.2rem] absolute top-2 right-2 lg:hidden cursor-pointer bg-primary 
            rounded-[6px]"
            onClick={() => setIsAdminSidebarOpen(false)}
          >
            <Icon
              icon="material-symbols:close-rounded"
              className="text-[1.2rem] text-white"
            />
          </div>

          <Link to="/admin" className="w-full flex flex-col items-center py-[1rem] lg:hidden mt-[1rem]">
            <img src={strikeLogo} alt="skygoalLogo" className="" />
          </Link>
        </div>

        <div className="flex flex-col gap-[3rem] px-[1rem] py-[1rem] ">
          <div className="flex flex-col gap-[1rem]">
            {
              (userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("DASHBOARD")) && (
                <NavLink to="/admin" end>
                  {({ isActive, isPending }) => (
                    <div
                      onClick={() => setIsAdminSidebarOpen(false)}
                      className={`flex gap-[0.9rem] items-center rounded-[12px] py-[0.8rem] px-[0.8rem]
                      ${isActive
                          ? "text-primary font-bold"
                          : "text-[#1A202C]"
                        } cursor-pointer`}
                    >
                      <Icon icon="ic:round-dashboard" className="text-[1.5rem]" />
                      <h4 className="text-[1rem]">Dashboard</h4>
                    </div>
                  )}
                </NavLink>
              )
            }
            {
              (userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("VIEW_LOTTERY")) && (
                <NavLink to="/admin/lottery">
                  {({ isActive, isPending }) => (
                    <div
                      onClick={() => setIsAdminSidebarOpen(false)}
                      className={`flex gap-[0.9rem] items-center rounded-[12px] py-[0.8rem] px-[0.8rem]
                      ${isActive
                          ? "text-primary font-bold"
                          : "text-[#1A202C]"
                        } cursor-pointer`}
                    >
                      <Icon icon="game-icons:ticket" className="text-[1.5rem]" />
                      <h4 className="text-[1rem]">Lottery</h4>
                    </div>
                  )}
                </NavLink>
              )
            }
            {
              (userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("VIEW_PRIVATE_LOTTERY")) && (
                <NavLink to="/admin/private-lotteries">
                  {({ isActive, isPending }) => (
                    <div
                      onClick={() => setIsAdminSidebarOpen(false)}
                      className={`flex gap-[0.9rem] items-center rounded-[12px] py-[0.8rem] px-[0.8rem] 
                  ${isActive
                          ? "text-primary font-bold"
                          : "text-[#1A202C]"
                        } cursor-pointer`}
                    >
                      <Icon
                        icon="game-icons:ticket"
                        className="text-[1.5rem]"
                      />
                      <h4 className="text-[1rem]">Private Lotteries</h4>
                    </div>
                  )}
                </NavLink>
              )
            }
            {
              (userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("VIEW_LOTTERY_AGENT")) && (
                <NavLink to="/admin/lottery-agents">
                  {({ isActive, isPending }) => (
                    <div
                      onClick={() => setIsAdminSidebarOpen(false)}
                      className={`flex gap-[0.9rem] items-center rounded-[12px] py-[0.8rem] px-[0.8rem]
                  ${isActive
                          ? "text-primary font-bold"
                          : "text-[#1A202C]"
                        } cursor-pointer`}
                    >
                      <Icon icon="heroicons-solid:user-group" className="text-[1.5rem]" />
                      <h4 className="text-[1rem]">Lottery Agents</h4>
                    </div>
                  )}
                </NavLink>
              )
            }
            {
              (userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("VIEW_WALLET_AGENT")) && (
                <NavLink to="/admin/wallet-agents">
                  {({ isActive, isPending }) => (
                    <div
                      onClick={() => setIsAdminSidebarOpen(false)}
                      className={`flex gap-[0.9rem] items-center rounded-[12px] py-[0.8rem] px-[0.8rem]
                  ${isActive
                          ? "text-primary font-bold"
                          : "text-[#1A202C]"
                        } cursor-pointer`}
                    >
                      <Icon icon="mdi:user-lock" className="text-[1.5rem]" />
                      <h4 className="text-[1rem]">Wallet Agents</h4>
                    </div>
                  )}
                </NavLink>
              )
            }
            {
              (userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("VIEW_WALLET_COINS")) && (
                <NavLink to="/admin/wallet-coins">
                  {({ isActive, isPending }) => (
                    <div
                      onClick={() => setIsAdminSidebarOpen(false)}
                      className={`flex gap-[0.9rem] items-center rounded-[12px] py-[0.8rem] px-[0.8rem]
                  ${isActive
                          ? "text-primary font-bold"
                          : "text-[#1A202C]"
                        } cursor-pointer`}
                    >
                      <Icon icon="mingcute:wallet-fill" className="text-[1.5rem]" />
                      <h4 className="text-[1rem]">Wallet Coins</h4>
                    </div>
                  )}
                </NavLink>
              )
            }
            {
              (userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("VIEW_USERS")) && (
                <NavLink to="/admin/users">
                  {({ isActive, isPending }) => (
                    <div
                      onClick={() => setIsAdminSidebarOpen(false)}
                      className={`flex gap-[0.9rem] items-center py-[0.8rem] px-[0.8rem]  
                  ${isActive
                          ? "text-primary font-bold"
                          : "text-[#1A202C]"
                        } cursor-pointer`}
                    >
                      <Icon icon="mdi:user" className="text-[1.5rem]" />
                      <h4 className="text-[1rem]">Users</h4>
                    </div>
                  )}
                </NavLink>
              )
            }
            {
              (userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("VIEW_WITHDRAWALS")) && (
                <NavLink to="/admin/withdrawals">
                  {({ isActive, isPending }) => (
                    <div
                      onClick={() => setIsAdminSidebarOpen(false)}
                      className={`flex gap-[0.9rem] items-center rounded-[12px] py-[0.8rem] px-[0.8rem]
                  ${isActive
                          ? "text-primary font-bold"
                          : "text-[#1A202C]"
                        } cursor-pointer`}
                    >
                      <Icon className="text-[1.5rem]" icon="solar:cash-out-bold" />
                      <h4 className="text-[1rem]">Withdrawals</h4>
                    </div>
                  )}
                </NavLink>
              )
            }
            {
              (userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("NUMBER_GAME")) && (
                <NavLink to="/admin/number-game">
                  {({ isActive, isPending }) => (
                    <div
                      onClick={() => setIsAdminSidebarOpen(false)}
                      className={`flex gap-[0.9rem] items-center rounded-[12px] py-[0.8rem] px-[0.8rem]
                  ${isActive
                          ? "text-primary font-bold"
                          : "text-[#1A202C]"
                        } cursor-pointer`}
                    >
                      <Icon className="text-[1.5rem]" icon="octicon:number-24" />
                      <h4 className="text-[1rem]">Number Game</h4>
                    </div>
                  )}
                </NavLink>
              )
            }
            {
              (userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("WHEEL_GAME")) && (
                <NavLink to="/admin/wheel-game">
                  {({ isActive, isPending }) => (
                    <div
                      onClick={() => setIsAdminSidebarOpen(false)}
                      className={`flex gap-[0.9rem] items-center rounded-[12px] py-[0.8rem] px-[0.8rem]
                  ${isActive
                          ? "text-primary font-bold"
                          : "text-[#1A202C]"
                        } cursor-pointer`}
                    >
                      <Icon className="text-[1.5rem]" icon="lucide:ferris-wheel" />
                      {/* <img src={wheelGame} alt="" className="bg-primary" /> */}
                      <h4 className="text-[1rem]">Wheel Game</h4>
                    </div>
                  )}
                </NavLink>
              )
            }
            {
              (userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("VIEW_SUB_ADMIN")) && (
                <NavLink to="/admin/sub-admin">
                  {({ isActive, isPending }) => (
                    <div
                      onClick={() => setIsAdminSidebarOpen(false)}
                      className={`flex gap-[0.9rem] items-center rounded-[12px] py-[0.8rem] px-[0.8rem]
                  ${isActive
                          ? "text-primary font-bold"
                          : "text-[#1A202C]"
                        } cursor-pointer`}
                    >
                      <Icon className="text-[1.5rem]" icon="mdi:user" />
                      <h4 className="text-[1rem]">Sub Admin</h4>
                    </div>
                  )}
                </NavLink>
              )
            }
            {
              (userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("VIEW_OFFERS_BANNERS")) && (
                <NavLink to="/admin/offers-banners">
                  {({ isActive, isPending }) => (
                    <div
                      onClick={() => setIsAdminSidebarOpen(false)}
                      className={`flex gap-[0.9rem] items-center rounded-[12px] py-[0.8rem] px-[0.8rem]
                  ${isActive
                          ? "text-primary font-bold"
                          : "text-[#1A202C]"
                        } cursor-pointer`}
                    >
                      <Icon className="text-[1.5rem]" icon="bxs:offer" />
                      <h4 className="text-[1rem]">Offers & Banners</h4>
                    </div>
                  )}
                </NavLink>
              )
            }
            {
              (userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("REPORTS")) && (
                <NavLink to="/admin/reports">
                  {({ isActive, isPending }) => (
                    <div
                      onClick={() => setIsAdminSidebarOpen(false)}
                      className={`flex gap-[0.9rem] items-center rounded-[12px] py-[0.8rem] px-[0.8rem]
                  ${isActive
                          ? "text-primary font-bold"
                          : "text-[#1A202C]"
                        } cursor-pointer`}
                    >
                      <Icon icon="mdi:report-arc" className="text-[1.5rem]" />
                      <h4 className="text-[1rem]">Reports</h4>
                    </div>
                  )}
                </NavLink>
              )
            }
            {
              (userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("VIEW_NOTIFICATIONS")) && (
                <NavLink to="/admin/notifications">
                  {({ isActive, isPending }) => (
                    <div
                      onClick={() => setIsAdminSidebarOpen(false)}
                      className={`flex gap-[0.9rem] items-center rounded-[12px] py-[0.8rem] px-[0.8rem]
                  ${isActive
                          ? "text-primary font-bold"
                          : "text-[#1A202C]"
                        } cursor-pointer`}
                    >
                      <Icon icon="mdi:bell-notification" className="text-[1.5rem]" />
                      <h4 className="text-[1rem]">Notifications</h4>
                    </div>
                  )}
                </NavLink>
              )
            }
            {
              (userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("SETTINGS")) && (
                <NavLink to="/admin/settings">
                  {({ isActive, isPending }) => (
                    <div
                      onClick={() => setIsAdminSidebarOpen(false)}
                      className={`flex gap-[0.9rem] items-center rounded-[12px] py-[0.8rem] px-[0.8rem]
                  ${isActive
                          ? "text-primary font-bold"
                          : "text-[#1A202C]"
                        } cursor-pointer`}
                    >
                      <Icon
                        icon="ic:settings"
                        className="text-[1.5rem]"
                      />
                      <h4 className="text-[1rem]">Settings</h4>
                    </div>
                  )}
                </NavLink>
              )
            }
            {
              (userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("SUPPORT")) && (
                <NavLink to="https://dashboard.tawk.to/#/chat" target="blank" rel="noopener noreferrer">
                  {({ isActive, isPending }) => (
                    <div
                      onClick={() => setIsAdminSidebarOpen(false)}
                      className={`flex gap-[0.9rem] items-center rounded-[12px] py-[0.8rem] px-[0.8rem]
                  ${isActive
                          ? "text-primary font-bold"
                          : "text-[#1A202C]"
                        } cursor-pointer`}
                    >
                      <Icon icon="fluent:person-support-24-filled" className="text-[1.5rem]" />
                      <h4 className="text-[1rem]">Support</h4>
                    </div>
                  )}
                </NavLink>
              )
            }
            {
              (userRole.role.admin || userRole?.role?.subAdmin?.permissions.includes("VIEW_ARCHIVE_LOTTERIES")) && (
                <NavLink to="archive-lotteries">
                  {({ isActive, isPending }) => (
                    <div
                      onClick={() => setIsAdminSidebarOpen(false)}
                      className={`flex gap-[0.9rem] items-center rounded-[12px] py-[0.8rem] px-[0.8rem]
                  ${isActive
                          ? "text-primary font-bold"
                          : "text-[#1A202C]"
                        } cursor-pointer`}
                    >
                      <Icon icon="material-symbols:unarchive-rounded" className="text-[1.5rem]" />
                      <h4 className="text-[1rem]">Archive Lotteries</h4>
                    </div>
                  )}
                </NavLink>
              )
            }
            <button onClick={() => logout()}
              className="flex gap-[0.9rem] items-center justify-start w-full text-[#1A202C] hover:text-[#BE0A23] focus:text-[#BE0A23] rounded-[8px] py-[0.8rem] px-[0.8rem] mt-[8rem]"
            >
              <Icon icon="solar:logout-2-bold" className="text-[1.5rem]" />
              <h4 className="text-[1rem]" type="button">
                Log Out
              </h4>
            </button>
          </div>
          {/* <hr className="border-[#DFDFDF]" /> */}
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AdminSideBar;
