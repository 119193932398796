import React from 'react'
import { useOutletContext } from 'react-router-dom';
import ViewPrivateLotteriesWinners from './ViewPrivateLotteriesWinners';
import ViewPvtLotteryWinnerAnnounce from './ViewPvtLotteryWinnerAnnounce';

export default function ViewPvtLotteryWinnerContainer() {
  const [mongoId, lotteryData, setIsRefetch] = useOutletContext();

  return (
    <div>
      {
        lotteryData?.winnerAnnounceStatus ? (
          <ViewPrivateLotteriesWinners data={lotteryData} />
        ) : (
          <ViewPvtLotteryWinnerAnnounce mongoId={mongoId} setIsRefetch={setIsRefetch} />
        )
      }
    </div>
  )
}
