import React from 'react';
import { IconButton } from '@material-tailwind/react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Icon } from '@iconify/react';
import moment from 'moment';

export default function WithdrawalViewModal({ userName, acceptedDate, status }) {

  return (
    <Popup className=""
      trigger={<button className="">
        <IconButton
          className="shadow-none hover:shadow-none focus:shadow-none bg-white text-[#565656]"
          style={{
            borderRadius: "5.55px",
            border: "0.925px solid #D9D9D9",
            background: "#FFF",
          }}
        >
          <Icon icon="mdi:eye" className="text-[1.5rem]" />
        </IconButton> </button>}
      modal
      nested
    >
      {close => (
        <div className="">
          <div className="flex flex-col p-[1rem]">
            <div className='flex justify-end w-full'>
              <IconButton onClick={close}
                className="shadow-none hover:shadow-none focus:shadow-none text-[#FF0023] bg-[#FDE4E8]"
                size='sm'
                style={{
                  borderRadius: "5px",
                  border: "1px solid #FF0023",
                }}
              >
                <Icon
                  icon="material-symbols:close"
                  className="text-[1.5rem]"
                />
              </IconButton>
            </div>
            <div className='flex flex-wrap justify-between items-center gap-[2rem] p-[1rem] rounded-xl'>
              <div>
                <label htmlFor="emailId" className='text-[#8B8D97]'>
                  User Name
                </label>
                <h2 className='font-semibold text-[1.2rem]'>{userName}</h2>
              </div>
              <div>
                <label htmlFor="mobileNumber" className='text-[#8B8D97]'>
                  Country & Currency
                </label>
                <h2 className='font-semibold text-[1.2rem]'>
                  India | INR
                </h2>
              </div>
              <div>
                <label htmlFor="emailId" className='text-[#8B8D97]'>
                  Amount
                </label>
                <h2 className='font-semibold text-[1.2rem]'>
                  30,000
                </h2>
              </div>
              <div>
                <label htmlFor="mobileNumber" className='text-[#8B8D97]'>
                  Accepted Date
                </label>
                <h2 className='font-semibold text-[1.2rem]'>
                  {moment(acceptedDate).format("DD MMMM YYYY")}
                </h2>
              </div>
              <div>
                <label htmlFor="mobileNumber" className='text-[#8B8D97]'>
                  Status
                </label>
                <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold px-[1.5rem] py-[0.5rem] ${status === "Inactive" ? "text-[#FF0023] bg-[#FDCAD1]" : status === "Active" ? "text-[#25BF17] bg-[#D1F0CF]" : "text-[#FFCE06] bg-[#FDF9EF]"}`}>
                  <span className={`${status === "Inactive" ? "bg-[#FF0023]" : status === "Active" ? "bg-[#25BF17]" : "bg-[#FFCE06]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
                  <h2>{status}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Popup>
  )
};
