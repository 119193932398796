import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import { updateIsEditPage } from '../../../store/editAgentSlice';
import _ from "lodash";

export default function AgentsContainer() {
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        const newPath = pathname.split("/");
        if (_.includes(newPath, "edit")) {
            dispatch(updateIsEditPage(true));
        } else {
            dispatch(updateIsEditPage(false));
        }
    }, [pathname]);

    return (
        <div>
            <div className='flex gap-[3rem] border-b-2 border-[#989898] px-[1rem] pt-[1rem] mx-[0.5rem] mt-[0.5rem]'>
                <NavLink to={`/admin/lottery-agents`} end>
                    {({ isActive }) => (
                        <h2 className={`text-[1.2rem] sm:text-[1.3rem] whitespace-nowrap pb-[0.3rem] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                            Lottery Agents
                        </h2>
                    )}
                </NavLink>
                <NavLink to={`/admin/lottery-agents/request`}>
                    {({ isActive }) => (
                        <h2 className={`text-[1.2rem] sm:text-[1.3rem] whitespace-nowrap pb-[0.3rem] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                            Lottery Agent Requests
                        </h2>
                    )}
                </NavLink>
            </div>
            <Outlet />
        </div>
    )
}
