import { createSlice } from "@reduxjs/toolkit";

const userDataSlice = createSlice({
    name: "userData",
    initialState: {
        totalAmountEarnedByUser: 0,
        totalTransactionsDoneByUser: 0,
    },
    reducers: {
        updateTotalAmountEarnedByUser(state, action) {
            state.totalAmountEarnedByUser = action.payload;
        },
        updateTotalTransactionsDoneByUser(state, action) {
            state.totalTransactionsDoneByUser = action.payload;
        }
    }
})

export const { updateTotalAmountEarnedByUser, updateTotalTransactionsDoneByUser } = userDataSlice.actions;

export default userDataSlice.reducer;