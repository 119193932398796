import { Icon } from "@iconify/react/dist/iconify.js";
import NumberGameCards from "./NumberGameCards";
import NumberGameList from "./NumberGameList";
import { Link } from "react-router-dom";

export default function NumberGame() {
  return (
    <main>
      <section className="flex flex-col md:flex-row md:items-center justify-between lg:gap-0 gap-1">
        <h1 className="text-[1.2rem] sm:text-[1.5rem] font-semibold whitespace-nowrap">
          Number Game
        </h1>
        <div className="flex lg:flex-col flex-col-reverse xs:flex-row items-start xs:items-center lg:gap-5 gap-3 max-xs:mb-[1rem] lg:my-[1rem]">
          <div
            className="flex flex-row items-center border rounded-md border-[#DFDFDF] 
          px-[1rem] py-[0.6rem] gap-[1rem]"
            style={{ boxShadow: "4px 4px 150px rgba(0, 0, 0, 0.13)" }}
          >
            <input
              type="text"
              className="lg:w-[15rem] w-full placeholder-[#848484] text-black text-[0.9rem] outline-none"
              placeholder="Search by Lottery number or name"
            />
            <div className="text-sm cursor-pointer text-white bg-primary p-[0.2rem] rounded-[4px]">
              <Icon icon="akar-icons:search" className="" />
            </div>
          </div>
          <Link to={"create-number-game"}>
            <button className="text-[0.8rem] sm:text-[1rem] flex items-center justify-center max-xs:w-full  gap-[0.3rem] xs:gap-[0.5rem] bg-primary text-white py-[0.5rem] px-[0.4rem] xs:px-[1rem] rounded-md">
              <Icon
                icon="material-symbols:add"
                className="text-[1.2rem] sm:text-[1.5rem]"
              />
              <span className="whitespace-nowrap">Create New Number Game</span>
            </button>
          </Link>
        </div>
      </section>
      <NumberGameCards />
      <NumberGameList />
    </main>
  );
}
