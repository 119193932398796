import React, { useState } from 'react'
import { Icon } from '@iconify/react';
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom';
// import userImg from '../../assets/user-img.png'
import { useQuery } from 'react-query';
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";
import moment from 'moment';
import userImg from '../../../assets/user-img.png'
import { RotatingLines } from 'react-loader-spinner';
import { formatToIndianNumber } from '../../../helpers/numberConversion';
import { toast } from 'react-toastify';

export default function ViewPrivateAgent() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { getAccessToken } = useAuth();
  const [viewRefetch, setViewRefetch] = useState();

  const fetchWalletAgentById = async () => {
    const token = await getAccessToken()
    const response = await fetch(`${APIurls.fetchWalletAgentById}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (!response.ok) {
      throw new Error("Failed to Fetch Wallet Agent Data");
    }
    const result = await response.json();
    console.log("Single Wallet Agent", result?.response?.agentDetails);
    return result?.response?.agentDetails;
  };

  const { data: singleWalletAgent, isError, error, isLoading, refetch } = useQuery(['singleWalletAgentData', id], fetchWalletAgentById);

  if (viewRefetch) {
    refetch();
    setViewRefetch(false);
  }

  if (isError) {
    return (
      <div>
        {toast.error(error.message)}
        <h1 className='text-[2rem] font-bold flex items-center justify-center h-screen'>
          Failed to Fetch Wallet Agent Data...!
        </h1>
      </div>
    )
  }
  if (isLoading) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <RotatingLines
          strokeColor="green"
          strokeWidth="5"
          animationDuration="0.75"
          width="100"
          visible={true}
        />
      </div>
    );
  }


  return (
    <div className='flex flex-col gap-[2rem] my-[1rem] px-[1rem]'>
      <div className='flex justify-between'>
        <button
          onClick={() => navigate("/admin/wallet-agents")}
          className="flex items-center gap-[0.5rem] text-[0.8rem] sm:text-[1rem] font-[500] text-white bg-primary py-[0.4rem] px-[1rem] rounded-md"
        >
          <Icon icon="material-symbols:arrow-back-ios-new-rounded" />
          <span>Back</span>
        </button>
        <div className='flex gap-[1rem]'>
          <button
            onClick={() => navigate("/admin/wallet-agents")}
            className="text-[0.8rem] sm:text-[1rem] font-[500] text-white bg-primary py-[0.4rem] px-[2rem] rounded-md"
          >
            Edit
          </button>
          <button
            onClick={() => navigate("/admin/wallet-agents")}
            className="text-[0.8rem] sm:text-[1rem] font-[500] text-white bg-[#FF0023] py-[0.4rem] px-[1rem] rounded-md"
          >
            Block User
          </button>
        </div>
      </div>
      <div className='h-[155px] flex gap-[1rem]'>
        <div>
          <img src={userImg} alt="userImg" className='rounded-xl h-[155px] w-[170px] bg-cover bg-center' />
        </div>
        <div className='flex flex-col justify-between gap-[1rem] p-[1rem] rounded-xl'
          style={{
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          <div className='flex gap-[2rem] items-center'>
            <div className='bg-[#D1F0CF] p-[0.1rem] rounded-md'>
              <Icon icon="heroicons-solid:user-group" className='text-primary text-[2rem]' />
            </div>
            <div>
              <h1 className='font-bold whitespace-nowrap'>
                {singleWalletAgent?.FirstName + " " + singleWalletAgent?.LastName}
              </h1>
              <p className='text-[#8B8D97] text-[0.8rem] whitespace-nowrap'>Created on{" "}
                <span className='font-bold'>
                  {moment(singleWalletAgent.createdAt).format("DD MMM YYYY")}
                </span>
              </p>
            </div>
            <div className={`flex justify-center items-center gap-[0.5rem] rounded-md font-semibold px-[1rem]
        py-[0.4rem] ${singleWalletAgent.status.toLowerCase() === "active" ? "text-[#25BF17] bg-[#D1F0CF]" : singleWalletAgent.status.toLowerCase() === "suspended" ? " text-[#FF0023] bg-[#FDCAD1]" : "text-[#FFCE06] bg-[#FDF9EF]"}`}>
              <span className={`${singleWalletAgent.status.toLowerCase() === "active" ? "bg-[#25BF17]" : singleWalletAgent.status.toLowerCase() === "suspended" ? "bg-[#FF0023] " : "bg-[#FFCE06]"} h-[0.5rem] w-[0.5rem] rounded-full`}></span>
              <h2>{singleWalletAgent.status}</h2>
            </div>
          </div>
          <div className='flex items-center justify-between'>
            <div>
              <label htmlFor="emailId" className='text-[#8B8D97] text-[0.9rem]'>
                Email ID
              </label>
              <h2 className='font-semibold'>
                {singleWalletAgent?.email}
              </h2>
            </div>
            <div>
              <label htmlFor="emailId" className='text-[#8B8D97] text-[0.9rem]'>
                Mobile Number
              </label>
              <h2 className='font-semibold'>
                {singleWalletAgent?.mobileNumber}
              </h2>
            </div>
          </div>
        </div>
        <div className='flex flex-wrap justify-between items-center gap-[1rem] p-[1rem] rounded-xl'
          style={{
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          <div>
            <label htmlFor="emailId" className='text-[#8B8D97] text-[0.9rem]'>
              Nationality
            </label>
            <h2 className='font-semibold'>
              {singleWalletAgent?.location?.country}
            </h2>
          </div>
          <div>
            <label htmlFor="mobileNumber" className='text-[#8B8D97] text-[0.9rem]'>
              Passport Number
            </label>
            <h2 className='font-semibold'>
              ABC123ASD
            </h2>
          </div>
          <div>
            <label htmlFor="emailId" className='text-[#8B8D97] text-[0.9rem]'>
              WhatsApp Number
            </label>
            <h2 className='font-semibold'>
              {singleWalletAgent?.whatsApp}
            </h2>
          </div>
          <div>
            <label htmlFor="mobileNumber" className='text-[#8B8D97] text-[0.9rem]'>
              Address
            </label>
            <h2 className='font-semibold'>
              {singleWalletAgent?.location?.address}, {singleWalletAgent?.location.city}, {singleWalletAgent?.location.state}, {singleWalletAgent?.location.country} - {singleWalletAgent?.location.pincode}
            </h2>
          </div>
        </div>
      </div>
      <div className='flex gap-7 items-center justify-between'>
        <div className='p-3 rounded-[10px] w-full'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <div className='flex gap-4 justify-between items-center'>
            <label htmlFor="transactions" className='text-[#858585]'>
              Total Number of Transactions
            </label>
            <Icon icon="ant-design:transaction-outlined" className='text-primary text-[1.8rem]' />
          </div>
          <h1 className='text-primary text-[2rem] font-bold'>
            {/* {totalTransactionsDoneByUser !== "undefined" ? totalTransactionsDoneByUser : "NA"} */}
            300
          </h1>
        </div>
        <div className='p-3 rounded-[10px] w-full'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <div className='flex gap-4 justify-between items-center'>
            <label htmlFor="transactions" className='text-[#858585]'>
              Total Amount Earned
            </label>
            <Icon icon="tdesign:money" className='text-primary text-[1.8rem]' />
          </div>
          <h1 className='text-primary text-[2rem] font-bold'>
            1,00,000
          </h1>
        </div>
        <div className='p-3 rounded-[10px] w-full'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <div className='flex gap-4 justify-between items-center'>
            <label htmlFor="transactions" className='text-[#858585]'>
              Total Amount In Wallet
            </label>
            <Icon icon="fluent:wallet-credit-card-32-regular" className='text-primary text-[1.8rem]' />
          </div>
          <h1 className='text-primary text-[2rem] font-bold'>
            {formatToIndianNumber(singleWalletAgent.wallet)}
          </h1>
        </div>
        <div className='w-[50%]'></div>
      </div>
      <div>
        <div className='flex gap-[2rem] text-[1.2rem] border-b-2 border-[#989898] px-[1rem]'>
          <NavLink to={`/admin/wallet-agents/view/${id}`} end>
            {({ isActive }) => (
              <h2 className={` ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                All
              </h2>
            )}
          </NavLink>
          <NavLink to={`/admin/wallet-agents/view/${id}/recharge`}>
            {({ isActive }) => (
              <h2 className={` ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                Recharge
              </h2>
            )}
          </NavLink>
          <NavLink to={`/admin/wallet-agents/view/${id}/withdrawal`}>
            {({ isActive }) => (
              <h2 className={` ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                Withdrawal
              </h2>
            )}
          </NavLink>
          <NavLink to={`/admin/wallet-agents/view/${id}/pending`}>
            {({ isActive }) => (
              <h2 className={` ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                Pending
              </h2>
            )}
          </NavLink>
          <NavLink to={`/admin/wallet-agents/view/${id}/invited`}>
            {({ isActive }) => (
              <h2 className={` ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
                Invited
              </h2>
            )}
          </NavLink>
        </div>
        <Outlet context={[setViewRefetch, singleWalletAgent._id]} />
      </div>
    </div>
  )
}

