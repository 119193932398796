import React from "react";
import { Icon } from "@iconify/react";
import adminAvatar from '../../assets/adminAvatar.svg'
import strikeLogo from '../../assets/strike-logo.svg'
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleNotificationModal } from "../../store/notificationSlice";
import NotificationModal from "../../helpers/NotificationModal";
import { useAuth } from "../../store/AuthContext";
import Avatar from "react-avatar";

const Header = ({ isAdminSidebarOpen, setIsAdminSidebarOpen }) => {
  //   const { userId, userName } = useSelector((state) => state.employee);
  const dispatch = useDispatch();
  const { showNotificationModal } = useSelector((store) => store.notification)

  const { userRole, details } = useAuth();

  return (
    <div className="h-[4.5rem] bg-white hidden sm:flex flex-row  justify-between items-center px-[1rem] md:px-[2rem] lg:px-[4rem] py-[1rem] border-b border-[#DFDFDF] shadow-md fixed w-full z-10">
      <div className="flex flex-row items-center gap-[2rem] md:gap-[4rem]">
        <div
          className="px-[0.3rem] rounded-md lg:hidden text-white bg-primary cursor-pointer"
          onClick={() => {
            setIsAdminSidebarOpen(true);
          }}
        >
          <Icon icon="ic:outline-menu" className="text-[2.5rem] text-white " />
        </div>
        <div className="hidden lg:flex">
          <Link to="/admin">
            <img src={strikeLogo} alt="" className="w-[80%]" />
          </Link>
        </div>
        <div
          className="flex flex-row items-center border rounded-md border-[#DFDFDF] bg-[#F6F6F8] 
          px-[1rem] py-[0.5rem] gap-[1rem]"
          style={{ boxShadow: "4px 4px 150px rgba(0, 0, 0, 0.13)" }}
        >
          <div className="text-sm cursor-pointer text-white bg-primary p-[0.2rem] rounded-[4px]">
            <Icon icon="akar-icons:search" className="" />
          </div>
          <input
            type="text"
            className="w-[15rem] placeholder-[#848484] text-black text-[0.9rem] outline-none bg-[#F6F6F8]"
            placeholder="Search...."
          />
        </div>
      </div>
      <div className="flex flex-row items-center gap-[1rem] sm:gap-[2rem] md:gap-[3rem]">
        <div className="relative">
          <button onClick={() => dispatch(toggleNotificationModal())}
            className="bg-[#F6F6F8] p-[0.5rem] rounded-full cursor-pointer">
            <Icon icon="mi:notification" className="text-[1.5rem] text-[#4D4D4D]" />
          </button>
          {showNotificationModal && (
            <div className="absolute top-[3.5rem] right-[-5rem] shadow-lg z-40 w-[25rem] bg-white">
              <NotificationModal />
            </div>
          )}
        </div>
        <div className="flex flex-row items-center gap-[1rem]">
          {/* <img src={adminAvatar} alt="" className="" /> */}
          <Avatar
              name={details?.name || "N A" }
              size="40"
              round={true}
            />
          <div>
            <h3 className="text-[#4D4D4D] text-[0.8rem] font-[600]">
              {details?.name || "NA"}
            </h3>
            <p className="text-[#4D4D4D] text-[0.7rem] font-[500]">
              {userRole?.role?.admin ? "Admin" : "Sub-Admin"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
