import React from 'react';

export default function ProgressBar({ progress, bgColor }) {

   const parentDiv = {
      height: '10px',
      width: '400px',
      backgroundColor: 'white',
      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.40) inset",
      borderRadius: '50px',
   }
   const childDiv = {
      height: '100%',
      width: `${progress}%`,
      backgroundColor: bgColor,
      borderRadius: '50px',
   }

   return (
      <div style={parentDiv}>
         <div style={childDiv}>
         </div>
      </div>
   )
}