import React from 'react'
import OrderList from '../adminModule/Dashboard/OrderList'
import Title from '../adminModule/Dashboard/Title'
import Users from '../adminModule/Dashboard/Users/Users'
import LotterySummary from '../adminModule/Dashboard/LotterySummary/LotterySummary'
import Lotteries from '../adminModule/Dashboard/Lotteries'
import { useQuery } from 'react-query';
import { useAuth } from '../../store/AuthContext';
import { APIurls } from '../../api/apiConstant';
import { RotatingLines } from 'react-loader-spinner';
import { toast } from 'react-toastify'

export default function Dashboard() {

  const { getAccessToken, userRole } = useAuth();

  const fetchLotteryData = async () => {
    const token = await getAccessToken()
    const response = await fetch(APIurls.fetchLottary, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (!response.ok) {
      throw new Error("Failed to Fetch Lottery Data");
    }
    const result = await response.json();
    console.log("Lottary Data Dashboard...", result?.response)
    return result?.response;
  };
  const { data: lotteryData, isLoading, isError, error } = useQuery('dashboardLotteries', fetchLotteryData);

  if (isError) {
    <div>
      {toast.error(error.message)}
    </div>
  }
  if (isLoading) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <RotatingLines
          strokeColor="green"
          strokeWidth="5"
          animationDuration="0.75"
          width="100"
          visible={true}
        />
      </div>
    )
  }

  return (
    <div className='lg:m-5 md:mt-30 sm:mt-20 m-3'>
      <Title></Title>
      <Lotteries data={lotteryData} />
      <LotterySummary data={lotteryData} />
      {/* <PrivateLotteries></PrivateLotteries> */}
      <OrderList></OrderList>
      <Users></Users>
    </div>
  )
}
