export const formatToIndianCurrency = function (number) {
    const formatter = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR'
    });
    let formattedNumber = formatter.format(number);
    formattedNumber = formattedNumber.replace(/\.00$/, '');
    return formattedNumber;
}
export const formatToIndianNumber = function (number) {
    const formatter = new Intl.NumberFormat('en-IN', {
        // style: 'currency',
        currency: 'INR'
    });
    let formattedNumber = formatter.format(number);
    // formattedNumber = formattedNumber.replace(/\.00$/, '');
    return formattedNumber;
}