import React from 'react'
import { Icon } from '@iconify/react/dist/iconify.js'
import { NavLink, Outlet } from 'react-router-dom'

export default function AgentWithdrawals() {
  return (
    <div>
      <h1 className='text-[1.2rem] my-[1.5rem] px-[2rem]'>Agent Withdrawals</h1>
      <div className="grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-6 my-7 px-4">
        {/* badge -1: Total Agents  */}
        <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <div>
            <header className='flex justify-between'>
              <h3 className='text-[14px] text-[#858585]'>Total Agents</h3>
              <Icon className='text-[#25BF17] text-[1.9rem]' icon="heroicons-solid:user-group" />
            </header>
            <h3 className='text-[#25BF17] text-[26px] font-bold'>
              {/* {agentRequestData?.length || "NA"} */}
              65
            </h3>
          </div>
        </div>
        {/* badge -2: Active Agents   */}
        <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <div>
            <header className='flex justify-between'>
              <h3 className='text-[14px] text-[#858585]'>Active</h3>
              <Icon icon="ooui:user-active" className="text-primary text-[1.8rem]" />
            </header>
            <h3 className='text-[#25BF17] text-[26px] font-bold'>
              {/* {activeAgents?.length !== "undefined" ? activeAgents?.length : "NA"} */}
              40
            </h3>
          </div>
        </div>
        {/* badge -3: Inactive Agents  */}
        <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <div>
            <header className='flex justify-between'>
              <h3 className='text-[14px] text-[#858585]'>Inactive</h3>
              <Icon className='text-[#25BF17] text-[1.6rem]' icon="grommet-icons:in-progress" />
            </header>
            <h3 className='text-[#25BF17] text-[26px] font-bold'>
              25
            </h3>
          </div>
        </div>
        <div></div>

      </div>
      <div className='flex gap-[3rem] border-b-2 border-[#989898] px-[1rem] pt-[1rem] mx-[0.5rem]'>
        <NavLink to={`/admin/withdrawals/agents`} end>
          {({ isActive }) => (
            <h2 className={`text-[1.1rem] sm:text-[1.2rem] whitespace-nowrap pb-[0.3rem] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
              Withdrawal requests
            </h2>
          )}
        </NavLink>
        <NavLink to={`/admin/withdrawals/agents/total`}>
          {({ isActive }) => (
            <h2 className={`text-[1.1rem] sm:text-[1.2rem] whitespace-nowrap pb-[0.3rem] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
              Total withdrawals
            </h2>
          )}
        </NavLink>
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  )
}
