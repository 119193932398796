import React from "react";
import { Page, Text, View, Document, StyleSheet, Link, Image } from '@react-pdf/renderer';
import moment from 'moment-timezone';
import strikeLogo from '../assets/Strike-Main-Logo.jpg'

function DownloadPDF({ data, filteredLotteries, filteredPrivLottList, filteredLotAgentList, filteredUsersList, subAdminData, notificationData }) {

    console.log("Data coming to DownloadPDF Component", data)

    let mainData = []

    if (filteredLotteries) {
        const lotteryData = data?.map((item, index) => ({
            SL: index + 1,
            "Unique Number": item.LottaryNumber,
            "Lottery Name": item.Name,
            "Winning Amount": item.winneramount,
            "Created At": moment.utc(item.createdAt).tz('Asia/kolkata').format("lll"),
            "Expected Date": moment.utc(item.expieryDate).tz('Asia/kolkata').format("lll"),
            Image: item.lottaryImage,
            Status: item.status
        }))
        mainData = lotteryData
    } else if (filteredPrivLottList) {
        const filtereditems = data?.map((item, index) => ({
            SL: index + 1,
            "Lottary Name": item.Name,
            "Unique Number": item.LottaryNumber,
            "Agent Name": item.agentUsers.FirstName + " " + item.agentUsers.LastName,
            "Winning Amount": item.winneramount,
            "Created At": moment.utc(item.createdAt).tz('Asia/kolkata').format("lll"),
            "Expected Date": moment.utc(item.expieryDate).tz('Asia/kolkata').format("lll"),
            Status: item.status
        }))
        mainData = filtereditems
    } else if (filteredLotAgentList) {
        const filteredAgentlists = data?.map((item, index) => ({
            SL: index + 1,
            "Agent Name": item.FirstName + item.LastName,
            Country: item.location.country,
            Balance: item.wallet,
            "Registered Date": moment.utc(item.createdAt).tz('Asia/kolkata').format("lll"),
            "Account Status": item.status
        }))
        mainData = filteredAgentlists
    } else if (filteredUsersList) {
        const filteredUserslist = data?.map((item, index) => ({
            SL: index + 1,
            "User Name": item.FirstName + " " + item.LastName,
            Country: item.location.country,
            "Registration Date": moment.utc(item.createdAt).tz('Asia/kolkata').format("lll"),
            Balance: item.wallet,
            Status: item.status
        }))
        mainData = filteredUserslist
    } else if (subAdminData) {
        const subAdmindata = data?.map((item, index) => ({
            SL: index + 1,
            "Sub-Admin Id": item.subId,
            Name: item.name,
            "Email Id": item.email,
            "Mobile Number": item.MobileNumber,
            "Created At": moment.utc(item.createdAt).tz('Asia/kolkata').format("lll"),
            Status: item.status
        }))
        mainData = subAdmindata
    } else if (notificationData) {
        const notificationsdata = data?.map((item, index) => ({
            SL: index + 1,
            Title: item.title,
            Description: item.description,
            "Created At": moment.utc(item.createdAt).tz('Asia/kolkata').format("lll"),
            "Send To": item.sendTo.agents === true ? "Agents" : item.sendTo.customers === true ? "Customers" : "Lottery Agents",
            Image: item.notificationImg
        }))
        mainData = notificationsdata
    }

    const headers = Object.keys(mainData[0])
    console.log("Headers", headers)

    const styles = StyleSheet.create({
        tableCol: {
            width: "15%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: '#000',
            padding: "5px",
        },
        tableColSL: {
            width: "5%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: '#000',
            padding: "5px",
        },
        uniqNo: {
            width: "15.5%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: '#000',
            padding: "5px",
        },
        agentName: {
            width: "20%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: '#000',
            padding: "5px",
        },
        winAmount: {
            width: "11%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: '#000',
            padding: "5px",
        },
        status: {
            width: "10%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: '#000',
            padding: "5px",
        },
        creatAndexpecDate: {
            width: "16.5%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: '#000',
            padding: "5px",
        },
        balance: {
            width: "12%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: '#000',
            padding: "5px",
        },
        subAdmId: {
            width: "22%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: '#000',
            padding: "5px",
        },
        mailId: {
            width: "17%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: '#000',
            padding: "5px",
        },
        title: {
            width: "18%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: '#000',
            padding: "5px",
        },
        description: {
            width: "22%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: '#000',
            padding: "5px",
        },
        tableCell: {
            marginTop: 5,
            textAlign: "center",
            padding: 5,
        },
    })

    return (
        <Document>
            <Page orientation='landscape' size="A4" style={{paddingRight:"30px",paddingLeft:"30px"}}>
                <View style={{ paddingLeft: "10px", paddingRight:"10px",}}>
                    <View style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Image src={strikeLogo} style={{ width: "150px", paddingTop: "0px"}} />
                        <View style={{width:"100%", display:"flex", flexDirection:"row" ,justifyContent:"space-between", paddingTop:"0px",paddingBottom:"20px"}}>
                            <Text style={{fontSize:"15px"}}>Downloaded on: {moment.utc(new Date()).tz('Asia/kolkata').format("lll")}</Text>
                            <Text style={{fontSize:"15px"}}>Downloaded by:</Text>
                        </View>
                    </View>
                    <View style={{ width: "100%", display: "flex", flexDirection: "column", margin: "0px auto", justifyContent: "center", alignItems: "center", fontSize: "10px" }}>
                        <View style={{ display: "flex", flexDirection: "row" }}>
                            {headers.map((colName, index) => (
                                <View key={index} style={colName === "SL" ? styles.tableColSL : colName === "Unique Number" ? styles.uniqNo : colName === "Agent Name" ? styles.agentName : colName === "Winning Amount" ? styles.winAmount : colName === "Status" ? styles.status : colName === "Created At" ? styles.creatAndexpecDate : colName === "Expected Date" ? styles.creatAndexpecDate : colName === "Balance" ? styles.balance : colName === "Registered Date" ? styles.creatAndexpecDate : colName === "User Name" ? styles.agentName : colName === "Registration Date" ? styles.creatAndexpecDate : colName === "Sub-Admin Id" ? styles.subAdmId : colName === "Name" ? styles.agentName : colName === "Email Id" ? styles.mailId : colName === "Title" ? styles.title : colName === "Description" ? styles.description : styles.tableCol}>
                                    <Text>{colName}</Text>
                                </View>
                            ))}
                        </View>
                        <View style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            {mainData.map((ele, index) => (
                                <View style={{ display: "flex", flexDirection: "row" }} key={index}>
                                    {headers.map((key, colIndex) => (
                                        <View key={colIndex} style={key === "SL" ? styles.tableColSL : key === "Unique Number" ? styles.uniqNo : key === "Agent Name" ? styles.agentName : key === "Winning Amount" ? styles.winAmount : key === "Status" ? styles.status : key === "Created At" ? styles.creatAndexpecDate : key === "Expected Date" ? styles.creatAndexpecDate : key === "Balance" ? styles.balance : key === "Registered Date" ? styles.creatAndexpecDate : key === "User Name" ? styles.agentName : key === "Registration Date" ? styles.creatAndexpecDate : key === "Sub-Admin Id" ? styles.subAdmId : key === "Name" ? styles.agentName : key === "Email Id" ? styles.mailId : key === "Title" ? styles.title : key === "Description" ? styles.description : styles.tableCol}>
                                            {key === "Image" ? (
                                                <Link style={{ width: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>{ele["Image"]}</Link>
                                            ) : (
                                                <Text style={styles.tableCell}>{ele[key]}</Text>
                                            )}
                                        </View>
                                    ))}
                                </View>
                            ))}
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
};
export default DownloadPDF;