import React, { useMemo, useState } from 'react'
import { Icon } from '@iconify/react/dist/iconify.js'
import { NavLink, Outlet } from 'react-router-dom'
import { useQuery } from "react-query";
import { APIurls } from "../../../api/apiConstant";
import { useAuth } from "../../../store/AuthContext";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";

export default function UserWithdrawals() {
  const [isRefetch, setIsRefetch] = useState(false);
  const { getAccessToken } = useAuth();

  const fetchUserTotalWithdrawalData = async () => {
    const token = await getAccessToken()
    const response = await fetch(APIurls.fetchTotalUserWithdrawals, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    if (!response.ok) {
      throw new Error("Failed to Fetch User Total Withdrawals");
    }
    const result = await response.json();

    console.log("User Total Withdrawals...", result?.response?.withdrawList);
    return result?.response?.withdrawList?.reverse();
  };
  const { data: userTotalWithdrawals, isLoading, refetch, isError, error } = useQuery('userTotalWithdrawalsData', fetchUserTotalWithdrawalData);

  let approved = useMemo(() => (
    userTotalWithdrawals?.filter((data) => data?.status.toLowerCase() === "approved")
  ), [userTotalWithdrawals])
  let rejected = useMemo(() => (
    userTotalWithdrawals?.filter((data) => data?.status.toLowerCase() === "rejected")
  ), [userTotalWithdrawals])
  let hold = useMemo(() => (
    userTotalWithdrawals?.filter((data) => data?.status.toLowerCase() === "hold")
  ), [userTotalWithdrawals])
  let pending = useMemo(() => (
    userTotalWithdrawals?.filter((data) => data?.status.toLowerCase() === "pending")
  ), [userTotalWithdrawals])

  if (isRefetch) {
    refetch();
    setIsRefetch(false);
  }

  if (isError) {
    return (
      <div>
        {toast.error(error.message)}
        <h1 className='text-[2rem] font-bold flex items-center justify-center h-screen'>
          Failed to Fetch User Withdrawals Data List...!
        </h1>
      </div>
    )
  }
  if (isLoading) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <RotatingLines
          strokeColor="green"
          strokeWidth="5"
          animationDuration="0.75"
          width="100"
          visible={true}
        />
      </div>
    )
  }


  return (
    <div>
      <h1 className='text-[1.2rem] my-[1.5rem] px-[2rem]'>User Withdrawals</h1>
      <div className="grid xl:grid-cols-5 sm:grid-cols-3 grid-cols-1 gap-6 my-7 px-4">
        {/* badge -1: Total Users  */}
        <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <header className='flex justify-between'>
            <h3 className='text-[14px] text-[#858585]'>Total Users</h3>
            <Icon className='text-[#25BF17] text-[1.7rem]' icon="heroicons-solid:user-group" />
          </header>
          <h3 className='text-[#25BF17] text-[26px] font-bold'>
            {/* {agentRequestData?.length || "NA"} */}
            {userTotalWithdrawals?.length !== "undefined" ? userTotalWithdrawals?.length : "NA"}
          </h3>
        </div>
        {/* badge -2: Approved Users  */}
        <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <header className='flex justify-between'>
            <h3 className='text-[14px] text-[#858585]'>Approved</h3>
            <Icon icon="icon-park-solid:check-one" className="text-primary text-[1.7rem]" />
          </header>
          <h3 className='text-[#25BF17] text-[26px] font-bold'>
            {approved?.length !== "undefined" ? approved?.length : "NA"}
          </h3>
        </div>
        {/* badge -3: Rejected Users  */}
        <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <header className='flex justify-between'>
            <h3 className='text-[14px] text-[#858585]'>Rejected</h3>
            <Icon className='text-[#25BF17] text-[1.75rem]' icon="icon-park-solid:close-one" />
          </header>
          <h3 className='text-[#25BF17] text-[26px] font-bold'>
            {rejected?.length !== "undefined" ? rejected?.length : "NA"}
          </h3>
        </div>
        {/* badge -3: On Hold Users  */}
        <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <header className='flex justify-between'>
            <h3 className='text-[14px] text-[#858585]'>Hold</h3>
            <Icon className='text-[#25BF17] text-[1.75rem]' icon="icon-park-solid:pause-one" />
          </header>
          <h3 className='text-[#25BF17] text-[26px] font-bold'>
            {hold?.length !== "undefined" ? hold?.length : "NA"}
          </h3>
        </div>
        {/* badge -3: Pending Users  */}
        <div className='py-3 px-4 rounded-[10px] flex flex-col justify-between'
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)" }}
        >
          <header className='flex justify-between'>
            <h3 className='text-[14px] text-[#858585]'>Pending</h3>
            <Icon className='text-[#25BF17] text-[1.6rem]' icon="grommet-icons:in-progress" />
          </header>
          <h3 className='text-[#25BF17] text-[26px] font-bold'>
            {pending?.length !== "undefined" ? pending?.length : "NA"}
          </h3>
        </div>

      </div>
      <div className='flex gap-[3rem] border-b-2 border-[#989898] px-[1rem] pt-[1rem] mx-[0.5rem]'>
        <NavLink to={`/admin/withdrawals`} end>
          {({ isActive }) => (
            <h2 className={`text-[1.1rem] sm:text-[1.2rem] whitespace-nowrap pb-[0.3rem] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
              Withdrawal requests
            </h2>
          )}
        </NavLink>
        <NavLink to={`/admin/withdrawals/total`}>
          {({ isActive }) => (
            <h2 className={`text-[1.1rem] sm:text-[1.2rem] whitespace-nowrap pb-[0.3rem] ${isActive ? "font-bold text-primary border-b-2 border-primary" : "text-[#858585]"}`}>
              Total withdrawals
            </h2>
          )}
        </NavLink>
      </div>
      <Outlet context={[userTotalWithdrawals, isLoading, setIsRefetch]} />
    </div>
  )
}

