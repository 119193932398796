export const generateLotteryNumber = () => {
    const generateRandomCharacter = (isDigit) => {
      const charset = isDigit ? '0123456789' : 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const randomIndex = Math.floor(Math.random() * charset.length);
      return charset[randomIndex];
    };
  
    const numLetters = 6; // Adjust the number of letters
    const numDigits = 15 - numLetters; // Calculate the number of digits
  
    const randomLetters = Array.from({ length: numLetters }, () => generateRandomCharacter(false));
    const randomDigits = Array.from({ length: numDigits }, () => generateRandomCharacter(true));
  
    const shuffledCharacters = randomLetters.concat(randomDigits).sort(() => Math.random() - 0.5);
    return shuffledCharacters.join('');
  };

  export const revenueCalculate = (totalTicket , ticketPrice , winningAmount) =>{
    // console.log(winningAmount)
   //const totalMA = winningAmount?.reduce((prev , curr) => Number(curr.Cashprice) + Number(prev.Cashprice) )
  
  return Math.max((Number(totalTicket) * Number(ticketPrice) - Number(winningAmount)) ,0)
  }