import React, { useMemo } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';
import { Icon } from '@iconify/react';

const renderLegend = (props) => {
  const { payload } = props;
  return (
    <ul className='flex justify-between items-center mt-[-50px]'>
      {
        payload.map((entry, index) => (
          <li key={`item-${index}`}>
            <div className='flex items-center gap-1'>
              <Icon icon="material-symbols-light:circle" className="text-[#25BF17] w-2 h-2" />
              <p className='font-medium text-[12px]'>{entry.value}</p>
            </div>
            <h4 className='text-[18px] font-bold ml-3 inline'>
              {(entry.payload?.percent * 100).toFixed(0)}%
            </h4>
            <span>({entry?.payload?.value})</span>
          </li>
        ))
      }
    </ul>
  );
}

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload }) => {
  const { name } = payload;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="left" className='text-[10px]'>
      <>
        <tspan>{name}</tspan>
        <tspan x={x} dy="1.5em" className='text-[12px]'>
          {`${(percent * 100).toFixed(0)}%`}
        </tspan>
      </>
    </text>
  );
};

const COLORS = ['#25BF17', '#6CDE62'];

export default function TotalLottery({ data }) {

  let lotteryCompleted = useMemo(() => (
    data?.Lottary?.filter((lottery) => lottery?.status.toLowerCase() === "completed")
  ), [data])
  let lotteryPending = data?.Lottary?.length - lotteryCompleted?.length;

  const chartData = [
    { name: 'Completed', value: lotteryCompleted?.length },
    { name: 'Pending', value: lotteryPending },
  ];

  return (
    <div className='p-4 rounded-lg border-gray-300 border-[1px] lg:w-2/6 w-full h-[500px]'>
      <header className='flex justify-between items-center pb-10'>
        <div className='flex items-center gap-2'>
          <Icon icon="material-symbols-light:circle" className="text-[#25BF17]" />
          <h3 className='text-[24px] font-medium'>Total Lotteries</h3>
        </div>
      </header>

      {/* chart  */}

      <ResponsiveContainer className="mt-[-60px]" width="100%" height="100%">
        <PieChart width={200} height={200}>
          <Pie
            data={chartData}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Legend verticalAlign="bottom" height={36} iconType='circle' content={renderLegend} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}
